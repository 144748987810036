/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

import ImageWrapper from 'components/ImageWrapper';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import RotateLeftIcon from 'images/icons/arrows/RotateLeftIcon.svg';
import EngineIcon from 'images/icons/configsAndSetups/configLightBlueOutline.svg';
import EmptyIcon from 'images/icons/empties/emptyIcon.svg';
import AddToProduction from 'images/icons/objects/bells/productionBlueOutline.svg';
import DisabledProduction from 'images/icons/objects/bells/productionGrayOutline.svg';
import RejectProduction from 'images/icons/objects/bells/productionRedOutline.svg';
import CountNotPendent from 'images/icons/objects/clipboards/listGrayOutline.svg';
import CountPendent from 'images/icons/objects/clipboards/listRedOutline.svg';
import CardConsumeIcon from 'images/icons/objects/schedules/doubleScheduleBlue.svg';
import VirtualProduction from 'images/icons/signals/alerts/alertWhiteLightBlueBgCircle.svg';
import AlertYellow from 'images/icons/signals/alerts/yellowCircleAlert.svg'
import FinishedIcon from 'images/icons/signals/checks/checkGreenRounded.svg';
import CanceledIcon from 'images/icons/signals/closesAndCancels/cancelRedRounded.svg';
import ProducingIcon from 'images/icons/signals/moreInfos/inProgressBlueRounded.svg';
import PauseIcon from 'images/icons/signals/pausesAndStops/pauseYellowRounded.svg';
import PlayIcon from 'images/icons/signals/playsAndStarts/playNavyBlueRounded.svg';
import { ButtonStatusCustomColor } from 'styles/buttons';
import { checkIsBefore } from 'utils/isBefore';
import ValidationLength from 'utils/utils';

import { useStyles, disabledIcon } from '../styles';
import { handleFields } from '../utils';

function DesktopRows({
  cards,
  setManufacture,
  setUpdateValue,
  updateValue,
  handleCardQuantity,
  getDuration,
  handleClickOpenModal,
  setLoading,
  manufactures,
  loading,
  handleOpenItems,
  modalStates,
  setModalStates,
  handleSubmitProduction,
  handleResetProduction,
  getId,
  handleOpenExtract,
  handleOpenRecipeItemsDiffPopover,
  handleOpenRecipeConsumeDiffPopover,
  setSelectedCard,
  updateQuantityInitialValue,
  statusCopilot,
  setAnchorEl,
  setCurrentCard
}) {
  const classes = useStyles();

  const renderWeekTags = (item) => {
    const weekTag = (week, active) => (
      <div className={`copilotWeekTag ${active && 'active'}`}>
        <span>
          {week}
        </span>
      </div>
    )

    const manufactureScheduleId = item?.schedule?.map(el => el?.daysWeekId)

    return (
      <div className="copilotWeekTagsBox">
        {weekTag('S', manufactureScheduleId?.includes(1))}
        {weekTag('T', manufactureScheduleId?.includes(2))}
        {weekTag('Q', manufactureScheduleId?.includes(3))}
        {weekTag('Q', manufactureScheduleId?.includes(4))}
        {weekTag('S', manufactureScheduleId?.includes(5))}
        {weekTag('S', manufactureScheduleId?.includes(6))}
        {weekTag('D', manufactureScheduleId?.includes(7))}
      </div>
    )
  }
  
  const buttonStatusInfo = (item, hasReview) => {
    let statusColor = '#454A67';
    let statusBackground = '#DADDF3';
    let statusText = 'Produção Sugerida';
    const lastUserUpdateToProduce = item?.actions?.filter(
      (el) => el?.type === 'TO_PRODUCE'
    )?.length
      ? item?.actions
          ?.filter((el) => el?.type === 'TO_PRODUCE')
          ?.sort((a, b) => (a?.date < b?.date ? 1 : -1))[0]?.user
      : '';
    const lastUserUpdateToCancel = item?.actions?.filter(
        (el) => el?.type === 'CANCELED'
      )?.length
      ? item?.actions
          ?.filter((el) => el?.type === 'CANCELED')
          ?.sort((a, b) => (a?.date < b?.date ? 1 : -1))[0]?.user
      : '';
    const lastUserUpdateRefused = item?.actions?.filter(
      (el) => el?.type === 'REFUSED'
    )?.length
      ? item?.actions
          ?.filter((el) => el?.type === 'REFUSED')
          ?.sort((a, b) => (a?.date < b?.date ? 1 : -1))[0]?.user
      : '';

    if (!item?.engine) {
      statusBackground = '#FFF2CC';
      statusText = 'Sem Sugestão';
    }

    if (item?.statusAction === 'TO_PRODUCE' && hasReview) {
      statusColor = '#FFF';
      statusBackground = '#5062F0';
      statusText = `Produção incluída em ${moment(item?.dateAction).format(
        'DD/MM/YYYY'
      )} às ${moment(item?.dateAction).format(
        'LT'
      )}hs (${lastUserUpdateToProduce})`;
    }

    if (hasReview && lastUserUpdateToCancel) {
      statusColor = '#FFF';
      statusBackground = '#F53D4C';
      statusText = `Produção rejeitada em ${moment(item?.dateAction).format(
        'DD/MM/YYYY'
      )} às ${moment(item?.dateAction).format(
        'LT'
      )}hs (${lastUserUpdateToCancel})`;
    }

    if (item?.statusAction === 'REFUSED' && hasReview && !lastUserUpdateToCancel) {
      statusColor = '#FFF';
      statusBackground = '#F53D4C';
      statusText = `Produção rejeitada em ${moment(item?.dateAction).format(
        'DD/MM/YYYY'
      )} às ${moment(item?.dateAction).format(
        'LT'
      )}hs (${lastUserUpdateRefused})`;
    }

    return {
      statusColor,
      statusBackground,
      statusText
    };
  };

  const currentStatusInfo = (item, totalCards) => {
    let icon = ProducingIcon;
    let tooltip =
      item?.otherManufactures > 0
        ? `Produzindo + ${totalCards} ${
            totalCards > 1 ? 'produções incluídas' : 'produção incluída'
          }`
        : 'Produzindo';
    let border = item?.otherManufactures > 0 && '2px solid #F2B90D';

    if (item?.status === 'FINISH') {
      icon = FinishedIcon;
      tooltip =
        item?.otherManufactures > 0
          ? `Finalizado + ${totalCards} ${
              totalCards > 1 ? 'produções incluídas' : 'produção incluída'
            }`
          : 'Finalizado';
      border = item?.otherManufactures > 0 && '2px solid #F2B90D';
    }

    if (item?.status === 'CANCELED') {
      icon = CanceledIcon;
      tooltip =
        item?.otherManufactures > 0
          ? `Cancelado + ${totalCards} ${
              totalCards > 1 ? 'produções incluídas' : 'produção incluída'
            }`
          : 'Cancelado';
      border = item?.otherManufactures > 0 && '2px solid #F2B90D';
    }

    if (item?.status === 'PAUSE') {
      icon = PauseIcon;
      tooltip =
        item?.otherManufactures > 0
          ? `Pausado + ${totalCards} ${
              totalCards > 1 ? 'produções incluídas' : 'produção incluída'
            }`
          : 'Pausado';
      border = item?.otherManufactures > 0 && '2px solid #F2B90D';
    }

    if (item?.status === 'WAITING') {
      icon = PlayIcon;
      tooltip =
        item?.otherManufactures > 0
          ? `Não iniciado + ${totalCards} ${
              totalCards > 1 ? 'produções incluídas' : 'produção incluída'
            }`
          : 'Não iniciado';
      border = item?.otherManufactures > 0 && '2px solid #F2B90D';
    }

    return {
      icon,
      tooltip,
      border
    };
  };

  const lineBackgroundColor = (hasSuggestion, item, isItem = false) => {
    if (hasSuggestion) {
      let opacity = item?.engineScheduleMissing ? '.45' : '.2';
      opacity = isItem ? '.15' : opacity;

      return isItem ? `rgba(117, 131, 240, ${opacity})` : `rgba(80, 98, 240, ${opacity})`;
    }

    let opacity = item?.todaySchedule ? '.5' : '.2';
    opacity = isItem ? '.10' : opacity;

    return `rgba(247, 213, 110, ${opacity})`;
  };

  const renderConsumeIcon = (card, item) => {
    const isComplementary = !item
      ? card?.isComplementary
      : item?.isComplementary;

    const yieldConsume = !item
      ? card?.engine?.yield &&
        parseFloat(card?.engine?.yield?.toFixed(3)) !==
          parseFloat(card?.yield?.toFixed(3))
      : item?.engine?.yield &&
        parseFloat(item?.engine?.yield.toFixed(3)) !==
          parseFloat(item?.quantity?.toFixed(3)) &&
        item?.stock <= parseFloat(item?.engine?.yield.toFixed(3));

    const hasRecipeItemsDiffQuantities = card?.items?.filter(
      (el) => el?.type === 'INTERNAL'
    )?.length;

    return (
      (card?.type === 'RECIPE' &&
        hasRecipeItemsDiffQuantities &&
        !card?.hideValue &&
        card?.quantity) ||
      (isComplementary && yieldConsume)
    );
  };

  const renderIncludeNewProductionButton = (manufacture, disabledToProduce, type) => (
    <Tooltip
      title={
        <p className="mb0 bold" style={{ padding: '2px 6px' }}>
          Incluir Produção
        </p>
      }
      aria-label="filters"
      placement="right-start"
      // disableHoverListener={!tooltip}
    >
      <IconButton
        edge="start"
        disabled={status === 'finish'}
        size="small"
        color="inherit"
        onClick={(e) => {
          if (disabledToProduce) return;
          const isComplementary =
            type === 'recipe'
              ? manufacture?.isComplementary
              : manufacture?.items?.some((el) => el?.isComplementary);

          const hasRecipeItemsDiffQuantities = manufacture?.items
            ?.filter((el) => el?.type === 'INTERNAL')
            ?.map(
              (item) =>
                (item?.manufactureInProgress?.yield ||
                  0 + item?.theoreticalStock) - item?.quantity
            )
            ?.some((el) => el < 0);

          if (type === 'recipe' && hasRecipeItemsDiffQuantities) {
            handleOpenRecipeItemsDiffPopover(
              e,
              manufacture,
              manufacture?.items?.filter(
                (item) =>
                  (item?.manufactureInProgress?.yield ||
                    0 + item?.theoreticalStock) -
                    item?.quantity <
                    0 && item?.type === 'INTERNAL'
              )
            );
            return null;
          }

          if (
            isComplementary &&
            manufacture?.engine?.yield &&
            parseFloat(manufacture?.engine?.yield?.toFixed(3)) !==
              parseFloat(manufacture?.yield?.toFixed(3))
          ) {
            handleOpenRecipeConsumeDiffPopover(e, manufacture);
            return null;
          }

          handleSubmitProduction(manufacture, 'TO_PRODUCE');
        }}
        aria-label="close"
        style={{
          outline: '0',
          cursor: disabledToProduce ? 'auto' : 'pointer'
        }}
      >
        <img
          src={AddToProduction}
          style={{
            width: '18px',
            opacity: disabledToProduce && '.3'
          }}
          alt={
            disabledToProduce ? 'DisabledProduction' : 'AddToProduction'
          }
        />
      </IconButton>
    </Tooltip>
  )

  const renderRestartProductionButton = (manufacture, disabledToProduce, type = '') => (
    <Tooltip
      title={
        <p className="mb0 bold" style={{ padding: '2px 6px' }}>
          Editar Produção
        </p>
      }
      aria-label="filters"
      placement="right-start"
    >
      <IconButton
        edge="start"
        disabled={disabledToProduce || statusCopilot === 'Finalizado'}
        size="small"
        color="inherit"
        onClick={(e) => {
          if (disabledToProduce) return;

          handleResetProduction(manufacture);
        }}
        aria-label="close"
        style={{
          outline: '0',
          cursor: disabledToProduce ? 'auto' : 'pointer'
        }}
      >
        <img
          src={RotateLeftIcon}
          style={{
            width: '14px',
            marginLeft: type === 'reject' ? 10 : 2,
            marginRight: 2,
            opacity: disabledToProduce || statusCopilot === 'Finalizado' && '.3'
          }}
          alt={
            disabledToProduce ? 'DisabledProduction' : 'RotateLeftIcon'
          }
        />
      </IconButton>
    </Tooltip>
  )

  const currentScheduleInfo = (hasSuggestion, item) =>
    hasSuggestion ? item?.engineScheduleMissing : item?.todaySchedule;

  function handleRecipe(manufacture, open, cardIndex, hasSuggestion) {
    return (
      <tr className={`${manufacture?.hide && 'dNone'}`}>
        <td colSpan="9" className="tableColorHover">
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            <table
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ border: 'none' }}
              className="dropDownTable"
            >
              <colgroup>
                <col style={{ width: '29.5%' }} />
                <col style={{ width: '7%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '13%' }} />
                <col style={{ width: '2.5%' }} />
                <col style={{ width: '23%' }} />
                <col style={{ width: '5.5%' }} />
                <col style={{ width: '2.5%' }} />
              </colgroup>

              <tbody>
                {manufacture.items.map((item, index) => {
                  const isLastElement = index === manufacture.items?.length - 1;
                  const exists = cards[cardIndex].items ? item : null;

                  if (
                    cards[cardIndex].items.length < manufacture.items.length &&
                    !exists
                  ) {
                    cards[cardIndex].items.push({
                      id: index,
                      quantity: item.quantity,
                      initialQuantity: item.quantity,
                      discard: { quantity: 0, reason: null }
                    });
                  }

                  return (
                    <tr className="defaultLine">
                      <td
                        className="description itemProductionTableDescription pr8"
                        style={{
                          paddingLeft: '29px',
                          borderBottom: !isLastElement ? '1px solid gray' : 'none',
                          borderRight: 'solid 1px #D0D3E2',
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        <div className="dFlex justifyBetween alignCenter">
                          <div className="pr10" style={{ width: "270px" }}>
                            {ValidationLength(item.item, 35)}
                          </div>

                          <div className="dFlex alignCenter justifyBetween" style={{ width: "128.5px" }}>
                            <div className="dFlex" style={{ width: 28, height: 28, marginRight: 1 }}>
                              <ImageWrapper
                                width="100%"
                                height="28px"
                                url={item?.image}
                                borderType={item?.type}
                              />
                            </div>
                            
                            <span className="dFlex justifyEnd ml5" style={{ width: "60%" }}>
                              <CurrencyTextField
                                margin="none"
                                variant="standard"
                                size="small"
                                currencySymbol=""
                                minimumValue="0"
                                maximumValue="999999999"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={3}
                                decimalPlacesShownOnBlur={3}
                                decimalPlacesShownOnFocus={3}
                                type="text"
                                name="quantity"
                                fullWidth
                                disabled={
                                  manufacture?.status !== 'WAITING' &&
                                  manufacture?.status !== 'START' &&
                                  manufacture?.status !== 'PAUSE'
                                }
                                value={item.quantity}
                                InputProps={{
                                  maxLength: 8,
                                  classes: { input: classes.input }
                                }}
                                onChange={(e) => {
                                  setManufacture(manufacture);
                                  setUpdateValue(
                                    parseFloat(e.target.value).toFixed(3) !==
                                      parseFloat(item.quantity.toFixed(3))
                                  );
                                }}
                                onBlur={(e, value) => {
                                  if (
                                    !updateValue &&
                                    parseFloat(item.quantity).toFixed(3) ===
                                      parseFloat(value).toFixed(3)
                                  )
                                    return;

                                  handleCardQuantity(
                                    cardIndex,
                                    manufacture.quantity,
                                    index,
                                    parseFloat(value),
                                    undefined
                                  );
                                  setUpdateValue(false);
                                }}
                              />
                            </span>
                            
                            <span className="ml3 dFlex justifyCenter" style={{ width: "10%" }}>
                              {item?.un?.abbreviation}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td
                        colSpan={3}
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          background: lineBackgroundColor(
                            hasSuggestion,
                            manufacture,
                            true
                          )
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    );
  }

  function handleTransformation(manufacture, open, cardIndex, hasSuggestion) {
    const { loss } = manufacture;
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    const itemTransformation = manufacture?.itemTransformation
    const cardYield =
      manufacture.type === 'recipe'
        ? manufacture.yield * (manufacture.quantity || 1)
        : (manufacture.quantity || 1) -
          (manufacture.quantity || 1) * (manufacture.loss / 100);
    const total = manufacture.items.reduce(
      (total, value) =>
        value
          ? parseFloat(total) + parseFloat(value.quantity)
          : parseFloat(total),
      0
    );
    const cardQuantity = manufacture.quantity || 1;
    const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);
    
    return (
      <>
        <tr className={`${manufacture?.hide && 'dNone'} itemTransformationLine`} valign="center">
          <td colSpan="9" className="tableColorHover">
            <Collapse
              in={open}
              timeout="0"
              unmountOnExit
              className={open && 'expanded'}
            >
              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
                style={{ border: 'none' }}
                className="dropDownTable"
              >
                <colgroup>
                  <col style={{ width: '29.5%' }} />
                  <col style={{ width: '7%' }} />
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '13%' }} />
                  <col style={{ width: '2.5%' }} />
                  <col style={{ width: '23%' }} />
                  <col style={{ width: '5.5%' }} />
                  <col style={{ width: '2.5%' }} />
                </colgroup>

                <tbody>
                  <tr className="defaultLine">
                    <td
                      className="description pr8"
                      style={{
                        paddingLeft: '29px',
                        borderRight: 'solid 1px #D0D3E2',
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      <div className="dFlex justifyBetween alignCenter">
                        <div className="dFlex">
                          <div className="pr10" style={{ width: "269.5px" }}>
                            <Tooltip
                              title={
                                <p
                                  className="mb0 bold"
                                  style={{ padding: '2px 6px' }}
                                >
                                  Produto à ser transformado
                                </p>
                              }
                              aria-label="filters"
                              placement="right-start"
                            >
                              <div style={{ width: "max-content", color: "#0B1A8E" }}>
                                {ValidationLength(itemTransformation?.description, 38)}
                              </div>
                            </Tooltip>
                          </div>

                          <div className="dFlex" style={{ width: 28, height: 28 }}>
                            <ImageWrapper
                              width="100%"
                              height="28px"
                              url={itemTransformation?.image?.url}
                              borderType={itemTransformation?.type}
                            />
                          </div>
                        </div>

                        <div className="dFlex alignCenter justifyEnd" style={{ width: "100.5px" }}>
                          <span className={`dFlex justifyEnd ml5 ${manufacture.statusAction === 'TO_PRODUCE' && 'disabledField'}`} style={{ width: "80%" }}>
                            <InputNumber
                              updateQuantityInitialValue={updateQuantityInitialValue}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              setUpdateValue={setUpdateValue}
                              updateValue={updateValue}
                              minimumValue="0"
                              maximumValue="999999999"
                              initialValue={manufacture.quantity?.toFixed(3)}
                              disabled={manufacture.statusAction === 'TO_PRODUCE'}
                              handleValue={(value) => {
                                setManufacture(manufacture);

                                handleCardQuantity(
                                  cardIndex,
                                  parseFloat(value),
                                  undefined,
                                  undefined,
                                  undefined
                                );
                              }}
                              fontSize="12px"
                              fontWeight="normal"
                              width="100%"
                              height="28px"
                            />
                          </span>
                          
                          <span className="ml3 dFlex justifyCenter" style={{ width: "10%" }}>
                            {itemTransformation?.consumptionUnits?.abbreviation}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td
                      className="right pr8 cursorPointer"
                      style={{
                        borderRight: 'solid 1px #D0D3E2',
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      <div className="copilotTableCollapse">
                        <span 
                          className="dFlex justifyCenter lightBlue cursorPointer" 
                          onClick={() => {
                            handleOpenExtract({
                              ...itemTransformation?.product,
                              id: itemTransformation?.productId,
                              consumptionUnitsPrimary: {
                                ...itemTransformation?.un
                              }
                            });
                          }}
                        >
                          {parseFloat((itemTransformation?.theoreticalStock || 0)?.toFixed(3))?.toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 3
                            }
                          )}{' '}
                        </span>

                        <span 
                          className="mr5 ml3 dFlex justifyCenter lightBlue cursorPointer" 
                          style={{ width: "14%" }}
                          onClick={() => {
                            handleOpenExtract({
                              ...itemTransformation,
                              id: itemTransformation?.productId,
                              consumptionUnitsPrimary: {
                                ...itemTransformation?.un
                              }
                            });
                          }}
                        >
                          {itemTransformation?.consumptionUnits?.abbreviation}
                        </span>

                        <div className="dFlex alignCenter justifyCenter" style={{ width: '14%' }}>
                          {itemTransformation?.count && (
                            <Tooltip
                              title={
                                <p
                                  className="mb0 bold"
                                  style={{ padding: '2px 6px' }}
                                >
                                  Contagem pendente
                                </p>
                              }
                              aria-label="filters"
                              placement="right-start"
                              disableHoverListener={
                                manufacture?.count?.hasCount
                              }
                            >
                              <Link
                                className={`
                                  ${itemTransformation?.count?.hasCount && 'disabledLink'}
                                `}
                                target="_blank"
                                to={`/inventario/contagem-diaria/${getId}/${itemTransformation?.count?.countId}?&countId=${itemTransformation?.count?.countId}&countOrigin=COUNTS&countDate=${itemTransformation?.count?.countDate}&countAssortDate=${itemTransformation?.count?.countAssortDate}&countAssortUser=${itemTransformation?.count?.countAssortUser}&descriptionName=${itemTransformation?.description || manufacture.description}`}
                              >
                                <img
                                  src={
                                    itemTransformation?.count?.hasCount
                                      ? CountNotPendent
                                      : CountPendent
                                  }
                                  style={{ width: '14px', marginBottom: 2 }}
                                  alt={
                                    itemTransformation?.count?.hasCount
                                      ? 'CountNotPendent'
                                      : 'CountPendent'
                                  }
                                />
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </td>

                    <td
                      className="right pr8 pl8"
                      style={{
                        borderRight: 'solid 1px #D0D3E2',
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      <span
                        className={`lightBlue dFlex ${!manufacture.hasProjection ? 'justifyBetween' : 'justifyEnd'}`}
                      >
                        {
                          !manufacture.hasProjection && 
                          <Tooltip
                            title={
                              <p
                                className="mb0 bold"
                                style={{ padding: '2px 6px' }}
                              >
                                Sem dados históricos<br/>
                                para gerar projeção
                              </p>
                            }
                            aria-label="filters"
                            placement="bottom-start"
                          >
                            <img
                              src={AlertYellow}
                              style={{ width: '16px' }}
                              alt="AlertIcon"
                            />
                          </Tooltip>
                        }
                        <span className={`${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'}`} onClick={() => {
                          if (disableDurationDialog) return null
                          
                          getDuration(
                            itemTransformation.productId ? itemTransformation.productId : null,
                            itemTransformation.theoreticalStock,
                            itemTransformation,
                            null,
                            null,
                            manufacture.date
                          );
                          handleClickOpenModal(manufacture);
                        }}>
                          {handleFields(itemTransformation)?.duration}
                        </span>
                      </span>
                    </td>

                    <td
                      className="center p8"
                      style={{
                        borderRight: 'solid 1px #454A67',
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      &nbsp;
                    </td>

                    <td
                      className="p8"
                      style={{
                        borderRight: 'solid 1px #454A67',
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      <div className="copilotTableCollapse">
                        <Tooltip
                          title={
                            <div className="dFlex flexColumn">
                              <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                                % de Perda esperada na Transformação 
                              </p>
                            </div>
                          }
                          aria-label="filters"
                          placement="right-start"
                          // disableHoverListener={!tooltip}
                        >
                          <span 
                            className="dFlex justifyCenter" 
                            style={{ 
                              width: '27%', 
                              color: loss ? 'red' : 'black'
                            }}
                          >
                            {loss
                              ? `-${parseFloat(loss).toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}`
                              : '0,00'}
                            %
                          </span>
                        </Tooltip>
                        
                        <div className={`${manufacture.statusAction === 'TO_PRODUCE' || manufacture.type === 'TRANSFORMATION' && 'disabledField'}`} style={{ width: "45%", margin: '0 5px' }}>
                          <InputNumber
                            updateQuantityInitialValue={updateQuantityInitialValue}
                            setModalStates={setModalStates}
                            modalStates={modalStates}
                            setUpdateValue={setUpdateValue}
                            updateValue={updateValue}
                            minimumValue="0"
                            maximumValue="999999999"
                            initialValue={manufacture?.yield?.toFixed(3)}
                            disabled={manufacture.statusAction === 'TO_PRODUCE' || manufacture.type === 'TRANSFORMATION'}
                            handleValue={(value) => {
                              setManufacture(manufacture);

                              handleCardQuantity(
                                cardIndex,
                                manufacture.quantity || 1,
                                undefined,
                                undefined,
                                parseFloat(value)
                              );
                            }}
                            fontSize="12px"
                            fontWeight="normal"
                            width="100%"
                            height="28px"
                          />
                        </div>

                        <span className="mr5 dFlex justifyCenter" style={{ width: "7%" }}>
                          {itemTransformation?.consumptionUnits?.abbreviation}
                        </span>
                        
                        <div className="dFlex alignCenter justifyCenter" style={{ width: '12%' }}/>
                      </div>
                    </td>

                    <td
                      style={{
                        background: '#FFF'
                      }}
                    >
                      &nbsp;
                    </td>

                    <td
                      style={{
                        background: '#FFF'
                      }}
                    >
                      &nbsp;
                    </td>

                    <td
                      style={{
                        background: '#FFF'
                      }}
                    >
                      &nbsp;
                    </td>

                    <td
                      style={{
                        borderLeft: 'solid 1px #454A67',
                        background: '#FFF'
                      }}
                    >
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        background: lineBackgroundColor(
                          hasSuggestion,
                          manufacture,
                          true
                        )
                      }}
                    >
                      <hr style={{ borderTop: 'solid 1px #9296AC' }} />
                    </td>

                    <td style={{ background: '#FFF' }} colSpan="4" />
                  </tr>
                </tbody>
              </table>
            </Collapse>
          </td>
        </tr>

        <tr className={`${manufacture?.hide && 'dNone'}`} valign="center">
          <td colSpan="9" className="tableColorHover">
            <Collapse
              in={open}
              timeout="0"
              unmountOnExit
              className={open && 'expanded'}
            >
              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
                style={{ border: 'none' }}
                className="dropDownTable"
              >
                <colgroup>
                  <col style={{ width: '29.5%' }} />
                  <col style={{ width: '7%' }} />
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '13%' }} />
                  <col style={{ width: '2.5%' }} />
                  <col style={{ width: '23%' }} />
                  <col style={{ width: '5.5%' }} />
                  <col style={{ width: '2.5%' }} />
                </colgroup>

                <tbody>
                  {manufacture.items.map((item, index) => {
                    const exists = cards[cardIndex].items ? item : null;
                    if (
                      cards[cardIndex].items.length < manufacture.items.length &&
                      !exists
                    ) {
                      cards[cardIndex].items.push({
                        id: index,
                        quantity: item.quantity,
                        initialQuantity: item.quantity,
                        discard: {
                          quantity: 0,
                          reason: {
                            id: null,
                            description: null
                          }
                        }
                      });
                    }

                    const handleItemQuantity = (value, labelQuantity = '') => {
                      if (!value) {
                        return;
                      }

                      setManufacture(manufacture);

                      handleCardQuantity(
                        cardIndex,
                        manufacture.quantity,
                        index,
                        parseFloat(value),
                        undefined,
                        true,
                        labelQuantity
                      );
                    };

                    return (
                      <>
                        <tr className="defaultLine">
                          <td
                            className="description"
                            style={{
                              paddingLeft: '29px',
                              borderRight: 'solid 1px #D0D3E2',
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            <div className="dFlex">
                              <div className="pr10" style={{ width: "270px" }}>
                                {ValidationLength(item.item, 34)}
                              </div>

                              <div className="dFlex" style={{ width: 28, height: 28 }}>
                                <ImageWrapper
                                  width="100%"
                                  height="28px"
                                  url={item?.image}
                                  borderType={item?.type}
                                />
                              </div>
                            </div>
                          </td>

                          <td
                            className="right pr8 cursorPointer"
                            style={{
                              borderRight: 'solid 1px #D0D3E2',
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            <div className="copilotTableCollapse">
                              <span 
                                className="dFlex justifyCenter lightBlue cursorPointer"
                                onClick={() => {
                                  handleOpenExtract({
                                    ...item?.product,
                                    consumptionUnitsPrimary: {
                                      ...item?.un
                                    }
                                  });
                                }}
                              >
                                {parseFloat(item?.stock?.toFixed(3))?.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )}{' '}
                              </span>
                              
                              <span 
                                className="mr5 ml3 dFlex justifyCenter lightBlue cursorPointer" 
                                style={{ width: "14%" }}
                                onClick={() => {
                                  handleOpenExtract({
                                    ...item?.product,
                                    consumptionUnitsPrimary: {
                                      ...item?.un
                                    }
                                  });
                                }}
                              >
                                {item?.un?.abbreviation}
                              </span>

                              <div className="dFlex alignCenter justifyCenter" style={{ width: '14%' }}>
                                {item?.count && (
                                  <Tooltip
                                    title={
                                      <p
                                        className="mb0 bold"
                                        style={{ padding: '2px 6px' }}
                                      >
                                        Contagem pendente
                                      </p>
                                    }
                                    aria-label="filters"
                                    placement="right-start"
                                    disableHoverListener={
                                      manufacture?.count?.hasCount
                                    }
                                  >
                                    <Link
                                      className={`
                                        ${item?.count?.hasCount && 'disabledLink'}
                                      `}
                                      target="_blank"
                                      to={`/inventario/contagem-diaria/${getId}/${item?.count?.countId}?&countId=${item?.count?.countId}&countOrigin=COUNTS&countDate=${item?.count?.countDate}&countAssortDate=${item?.count?.countAssortDate}&countAssortUser=${item?.count?.countAssortUser}&descriptionName=${item?.item || manufacture.description}`}
                                    >
                                      <img
                                        src={
                                          item?.count?.hasCount
                                            ? CountNotPendent
                                            : CountPendent
                                        }
                                        style={{ width: '14px', marginBottom: 2 }}
                                        alt={
                                          item?.count?.hasCount
                                            ? 'CountNotPendent'
                                            : 'CountPendent'
                                        }
                                      />
                                    </Link>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </td>

                          <td
                            className="right pr8 pl8"
                            style={{
                              borderRight: 'solid 1px #D0D3E2',
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            <span
                              className={`lightBlue ${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'} dFlex ${!item.hasProjection ? 'justifyBetween' : 'justifyEnd'}`}
                              onClick={() => {
                                if (disableDurationDialog) return null
                                
                                getDuration(
                                  item.id ? item.id : null,
                                  item.stock,
                                  item,
                                  null,
                                  null,
                                  manufacture.date
                                );
                                handleClickOpenModal(manufacture);
                              }}
                            >
                              {!item.hasProjection && 
                                <Tooltip
                                  title={
                                    <p
                                      className="mb0 bold"
                                      style={{ padding: '2px 6px' }}
                                    >
                                      Sem dados históricos<br/>
                                      para gerar projeção
                                    </p>
                                  }
                                  aria-label="filters"
                                  placement="bottom-start"
                                  >
                                  <img
                                    src={AlertYellow}
                                    style={{ width: '16px' }}
                                    alt="AlertIcon"
                                  />
                                </Tooltip>
                              }
                              {handleFields(item).duration}
                            </span>
                          </td>

                          <td
                            className="center p8"
                            style={{
                              borderRight: 'solid 1px #454A67',
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            &nbsp;
                          </td>

                          <td
                            className="p8"
                            style={{
                              borderRight: 'solid 1px #454A67',
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            <span className="dFlex justifyCenter" style={{ width: '27%' }}/>
                            
                            <div className="copilotTableCollapse">
                              <CurrencyTextField
                                style={{ width: "45%", margin: '0 5px' }}
                                margin="none"
                                variant="standard"
                                size="small"
                                currencySymbol=""
                                minimumValue="0"
                                maximumValue="999999999"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={3}
                                decimalPlacesShownOnBlur={3}
                                decimalPlacesShownOnFocus={3}
                                type="text"
                                name="quantity"
                                fullWidth
                                disabled={manufacture.status !== 'WAITING' || manufacture.statusAction === 'TO_PRODUCE'}
                                value={item.quantity}
                                InputProps={{
                                  maxLength: 8,
                                  classes: { input: classes.input }
                                }}
                                onChange={(e) => {
                                  setManufacture(manufacture);

                                  setUpdateValue(
                                    parseFloat(e.target.value).toFixed(3) !==
                                      parseFloat((item?.quantity || 0).toFixed(3))
                                  );
                                }}
                                onBlur={(e, value) => {
                                  if (
                                    !updateValue &&
                                    parseFloat(
                                      (item?.quantity || 0).toFixed(3)
                                    ) === parseFloat(value).toFixed(3)
                                  )
                                    return;

                                  handleCardQuantity(
                                    cardIndex,
                                    manufacture.quantity,
                                    index,
                                    parseFloat(value),
                                    undefined
                                  );
                                  setUpdateValue(false);
                                }}
                              />

                              <span className="mr5 dFlex justifyCenter" style={{ width: "7%" }}>
                                {item?.un?.abbreviation}
                              </span>
                              
                              <div style={{ width: '12%', marginTop: 4 }}>
                                {renderConsumeIcon(manufacture, item) ? (
                                  <Tooltip
                                    title={
                                      <div className="dFlex flexColumn">
                                        <p
                                          className="mb0 bold"
                                          style={{ padding: '2px 6px' }}
                                        >
                                          Consumo das fichas relacionadas a esta
                                          produção
                                        </p>
                                      </div>
                                    }
                                    aria-label="filters"
                                    placement="right-start"
                                    // disableHoverListener={!tooltip}
                                  >
                                    <IconButton
                                      edge="start"
                                      size="small"
                                      color="inherit"
                                      aria-label="close"
                                      onClick={() => {
                                        const isComplementary = item?.isComplementary;

                                        if (
                                          isComplementary &&
                                          item?.engine?.yield &&
                                          parseFloat(
                                            item?.engine?.yield.toFixed(3)
                                          ) !==
                                            parseFloat(item?.quantity?.toFixed(3)) &&
                                          item?.stock <=
                                            parseFloat(item?.engine?.yield.toFixed(3))
                                        ) {
                                          setModalStates({
                                            ...modalStates,
                                            modalConsumeQuantities: true
                                          });
                                          setSelectedCard({
                                            ...manufacture,
                                            consumeDialogByItem: item
                                          });

                                          return null;
                                        }
                                      }}
                                      style={{
                                        outline: '0',
                                        cursor:
                                          status === 'canceled' ? 'auto' : 'pointer'
                                      }}
                                    >
                                      <img
                                        src={CardConsumeIcon}
                                        style={{ width: '16px' }}
                                        alt="CardConsumeIcon"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                          </td>

                          <td
                            style={{
                              background: '#FFF'
                            }}
                          >
                            &nbsp;
                          </td>

                          <td
                            style={{
                              background: '#FFF'
                            }}
                          >
                            &nbsp;
                          </td>

                          <td
                            style={{
                              background: '#FFF'
                            }}
                          >
                            &nbsp;
                          </td>

                          <td
                            style={{
                              borderLeft: 'solid 1px #454A67',
                              background: '#FFF'
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>

                        <tr>
                          <td
                            colSpan="5"
                            style={{
                              background: lineBackgroundColor(
                                hasSuggestion,
                                manufacture,
                                true
                              )
                            }}
                          >
                            <hr />
                          </td>

                          <td style={{ background: '#FFF' }} colSpan="4" />
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Collapse>
          </td>
        </tr>
      </>
    );
  }

  if (!manufactures) {
    return (
      <>
        <tr>
          <td colSpan="9" valign="center" align="center">
            <Typography
              style={{
                verticalAlign: 'center',
                margin: '10px 0'
              }}
            >
              {loading ? (
                <LinearProgress variant="query" />
              ) : (
                'Não há nada para ser exibido'
              )}
            </Typography>
          </td>
        </tr>

        <tr>
          <td colSpan="9">
            <hr />
          </td>
        </tr>
      </>
    );
  }

  return manufactures.map((manufacture, index) => {
    const previousItem = index > 0 ? manufactures[index - 1] : null;
    const type = manufacture.type.toLowerCase();
    const status = manufacture.status.toLowerCase();
    const exists = cards ? cards.find((x) => x?.id === index) : null;
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    const hasReview =
      manufacture?.statusAction &&
      manufacture?.statusAction !== 'NOT_EVALUATED';
    const hasSuggestion = manufacture?.engine;
    const disableByCardStatus = status !== 'waiting';
    const disabledToProduce =
      manufacture?.hideValue ||
      manufacture.statusAction === 'TO_PRODUCE' ||
      manufacture?.statusAction === 'REFUSED' ||
      (hasReview && disableByCardStatus);
    const disabledToReject =
      manufacture.statusAction === 'REFUSED' ||
      (hasReview && disableByCardStatus);

    if (cards && cards.length < manufactures.length && !exists) {
      cards
        .sort((a, b) => (a.status > b.status ? -1 : 1))
        .push({
          id: index,
          open: false,
          quantity: manufacture.quantity,
          type,
          startTime: manufacture.actions
            .reverse()
            .find((x) => x.type === 'START')
            ? manufacture.actions.reverse().find((x) => x.type === 'START').date
            : null,
          pauseTime: manufacture.actions
            .reverse()
            .find((x) => x.type === 'PAUSE')
            ? manufacture.actions.reverse().find((x) => x.type === 'PAUSE').date
            : null,
          finishTime: manufacture.actions
            .reverse()
            .find((x) => x.type === 'FINISH')
            ? manufacture.actions.reverse().find((x) => x.type === 'FINISH')
                .date
            : null,
          manufactured: manufacture.manufactured,
          totalLoss: null,
          loss: manufacture.loss ? manufacture.loss : 0,
          totalYield:
            type === 'recipe'
              ? manufacture.yield * (manufacture.quantity || 1)
              : (manufacture.quantity || 1) -
                (manufacture.quantity || 1) * (manufacture.loss / 100),
          initialYield:
            type === 'recipe'
              ? manufacture.yield * 1
              : 1 - 1 * (manufacture.loss / 100),
          items: []
        });
    }

    const nextDay = (value, type) => {
      let day = '';
      let dayAbbreviation = '';

      if (value === 1) {
        dayAbbreviation = 'Seg';
        day = 'Segunda-Feira';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 2) {
        dayAbbreviation = 'Ter';
        day = 'Terça-Feira';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 3) {
        dayAbbreviation = 'Qua';
        day = 'Quarta-Feira';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 4) {
        dayAbbreviation = 'Qui';
        day = 'Quinta-Feira';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 5) {
        dayAbbreviation = 'Sex';
        day = 'Sexta-Feira';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 6) {
        dayAbbreviation = 'Sab';
        day = 'Sábado';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      if (value === 7) {
        dayAbbreviation = 'Dom';
        day = 'Domingo';
        return type === 'abbreviation' ? dayAbbreviation : day;
      }
      return '-';
    };

    const validUnit = type === 'transformation'
    ? manufacture?.items?.[0]?.un?.abbreviation || ''
    : manufacture?.un?.abbreviation || ''

    return (
      <>
        <tr style={{ borderTop: previousItem?.engine && !manufacture?.engine && 'solid 3px #454A67' }} valign="center" className={`${manufacture?.hide && 'dNone'}`}>
          <td
            className="description pr8"
            style={{
              borderRight: 'solid 1px #D0D3E2',
              background: lineBackgroundColor(hasSuggestion, manufacture)
            }}
          >
            <List component="nav" className="list">
              <ListItem button className="listItem p0">
                <div className="dFlex w100" style={{ padding: 0 }}>
                  <div className="dFlex alignCenter" style={{ width: "90%" }}>
                    <div
                      style={{ width: 29 }}
                      onClick={() => handleOpenItems(manufacture._id)}
                    >
                      {manufactures.find((el) => el._id === manufacture._id)
                        ?.open ? (
                        <ExpandLess style={{ color: '#333541', marginRight: '-5px' }} />
                      ) : (
                        <ExpandMore style={{ color: '#333541', marginRight: '-5px' }} />
                      )}
                    </div>

                    <div
                      className="pr10"
                      style={{ width: "270px" }}
                      onClick={() => {
                        if (manufacture?.preparationMethod?.url) return;
                        handleOpenItems(manufacture._id);
                      }}
                    >
                      <Tooltip
                        title={
                          <p
                            className="mb0 bold"
                            style={{ padding: '2px 6px' }}
                          >
                            {hasSuggestion
                              ? 'Incluído sem agenda para esta data'
                              : 'Excluído com agenda para esta data'}
                          </p>
                        }
                        aria-label="filters"
                        placement="right-start"
                        disableHoverListener={
                          !currentScheduleInfo(hasSuggestion, manufacture)
                        }
                      >
                        <span
                          className={`${
                            currentScheduleInfo(hasSuggestion, manufacture) &&
                            'bold'
                          }`}
                          style={{
                            color: !manufacture.engineScheduleMissing
                              ? ''
                              : '#F53D4C'
                          }}
                        >
                          {ValidationLength(
                            handleFields(manufacture).description,
                            34,
                            null,
                            null,
                            null,
                            `${manufacture?.cardId} - ${
                              handleFields(manufacture).description
                            }`,
                            null
                          )}
                        </span>
                      </Tooltip>
                    </div>

                    <div className="dFlex" style={{ width: 28, height: 28 }}>
                      {type === 'recipe' &&
                        <ImageWrapper
                          width="100%"  
                          height="28px"
                          url={manufacture?.image}
                          borderType="INTERNAL"
                        />
                      }
                    </div>
                  </div>

                  <div style={{ width: "10%" }}>
                    {manufacture.origin === 'V' ? (
                      <Tooltip
                        title={
                          <>
                            <p style={{ padding: 0, margin: 0 }}>
                              <b>PRODUÇÃO VIRTUAL</b>
                            </p>
                            <p style={{ padding: 0, margin: 0 }}>
                              Produção sem controle de inventário.
                            </p>
                            <p style={{ padding: 0, margin: 0 }}>
                              Apenas para projeção de entrada e saída do
                              estoque.
                            </p>
                          </>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img src={VirtualProduction} />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              </ListItem>
            </List>
          </td>

          <td
            className="description right pr8 cursorPointer"
            style={{
              borderRight: 'solid 1px #D0D3E2',
              background: lineBackgroundColor(hasSuggestion, manufacture)
            }}
          >
            {type === 'transformation' ? (
              manufactures.find((el) => el._id === manufacture._id)?.open ? (
                <ExpandLess style={{ color: '#333541', marginRight: '-5px' }} />
              ) : (
                <ExpandMore style={{ color: '#333541', marginRight: '-5px' }} />
              )
            ) : (
              <div className="dFlex alignCenter justifyEnd lightBlue cursorPointer">
                <div 
                  onClick={() => {
                    if (type !== 'transformation') {
                      handleOpenExtract({
                        ...manufacture?.product,
                        consumptionUnitsPrimary: {
                          ...manufacture?.un
                        }
                      });
                    } else {
                      handleOpenItems(manufacture._id);
                    }
                  }}
                >
                  {handleFields(manufacture).stock}
                </div>

                <span 
                  className="mr5 ml3 dFlex justifyCenter cursorPointer" style={{ width: '14%' }}
                  onClick={() => {
                    if (type !== 'transformation') {
                      handleOpenExtract({
                        ...manufacture?.product,
                        consumptionUnitsPrimary: {
                          ...manufacture?.un
                        }
                      });
                    } else {
                      handleOpenItems(manufacture._id);
                    }
                  }}
                >
                  {manufacture?.un?.abbreviation || ''}
                </span>

                <div className="dFlex alignCenter justifyCenter" style={{ width: "14%" }}>
                  {manufacture?.count && type !== 'transformation' && (
                    <Tooltip
                      title={
                        <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                          Contagem pendente
                        </p>
                      }
                      aria-label="filters"
                      placement="right-start"
                      disableHoverListener={manufacture?.count?.hasCount}
                    >
                      <Link
                        className={`${manufacture?.count?.hasCount && 'disabledLink'}`}
                        target="_blank"
                        to={`/inventario/contagem-diaria/${getId}/${manufacture?.count?.countId}?&countId=${manufacture?.count?.countId}&countOrigin=COUNTS&countDate=${manufacture?.count?.countDate}&countAssortDate=${manufacture?.count?.countAssortDate}&countAssortUser=${manufacture?.count?.countAssortUser}&descriptionName=${manufacture.description}`}
                      >
                        <img
                          src={
                            manufacture?.count?.hasCount
                              ? CountNotPendent
                              : CountPendent
                          }
                          style={{ width: '14px', marginBottom: 2 }}
                          alt={
                            manufacture?.count?.hasCount
                              ? 'CountNotPendent'
                              : 'CountPendent'
                          }
                        />
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </td>

          <td
            className={`description right pr8 pl8 ${
              type === 'transformation' && 'cursorPointer'
            }`}
            onClick={() => {
              if (type !== 'transformation') return;

              handleOpenItems(manufacture._id);
            }}
            style={{
              borderRight: 'solid 1px #D0D3E2',
              background: lineBackgroundColor(hasSuggestion, manufacture)
            }}
          >
            {type === 'transformation' ? (
              manufactures.find((el) => el._id === manufacture._id)?.open ? (
                <ExpandLess style={{ color: '#333541', marginRight: '-5px' }} />
              ) : (
                <ExpandMore style={{ color: '#333541', marginRight: '-5px' }} />
              )
            ) : (
              <span
                className={`lightBlue dFlex ${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'} ${!manufacture.hasProjection ? 'justifyBetween' : 'justifyEnd'}`}
                onClick={() => {
                  if (disableDurationDialog) return null

                  getDuration(
                    manufacture.productId ? manufacture.productId : null,
                    manufacture.stock,
                    manufacture,
                    null,
                    null,
                    manufacture.date
                  );
                  handleClickOpenModal(manufacture);
                }}
              >
                {
                  !manufacture.hasProjection &&
                  <Tooltip
                    title={
                      <p
                        className="mb0 bold"
                        style={{ padding: '2px 6px' }}
                      >
                        Sem dados históricos<br/>
                        para gerar projeção
                      </p>
                    }
                    aria-label="filters"
                    placement="bottom-start"
                    >
                    <img
                      src={AlertYellow}
                      style={{ width: '16px' }}
                      alt="AlertIcon"
                    />
                  </Tooltip>
                }
                {handleFields(manufacture).duration}
              </span>
            )}
          </td>

          <td
            className="description center p8"
            style={{
              borderRight: 'solid 1px #454A67',
              background: lineBackgroundColor(hasSuggestion, manufacture)
            }}
          >
            {renderWeekTags(manufacture)}
          </td>

          <td
            className="description right p8"
            style={{
              borderLeft: 'solid 1px #454A67',
              background: lineBackgroundColor(hasSuggestion, manufacture)
            }}
          >
            {type === 'recipe' ?
              <div className="dFlex">
                <span 
                  className="dFlex justifyEnd bold lightBlue cursorPointer" 
                  style={{ width: '27%' }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                    setCurrentCard(manufacture)
                  }}
                >
                  {manufacture?.viewMethod === 1 ? 'Q' : 'R'}
                </span>

                <div style={{ width: "45%", margin: '1.5px 5px 0 10px' }}>
                  {manufacture?.viewMethod === 1 ?
                    <InputNumber
                      setModalStates={setModalStates}
                      modalStates={modalStates}
                      setUpdateValue={setUpdateValue}
                      updateValue={updateValue}
                      updateQuantityInitialValue={updateQuantityInitialValue}
                      minimumValue="0"
                      maximumValue="999999999"
                      disabled={manufacture.status !== 'WAITING'}
                      initialValue={
                        manufacture.quantity?.toFixed(3)
                      }
                      handleValue={(value) => {
                        setManufacture(manufacture);

                        handleCardQuantity(
                          index,
                          parseFloat(value),
                          undefined,
                          undefined,
                          undefined
                        );
                      }}
                      fontSize="12px"
                      fontWeight="normal"
                      width="100%"
                      height="28px"
                    />
                  : 
                    <InputNumber
                      setModalStates={setModalStates}
                      modalStates={modalStates}
                      setUpdateValue={setUpdateValue}
                      updateValue={updateValue}
                      updateQuantityInitialValue={updateQuantityInitialValue}
                      minimumValue="0"
                      maximumValue="999999999"
                      disabled={manufacture.status !== 'WAITING'}
                      initialValue={manufacture?.yield?.toFixed(3)}
                      handleValue={(value) => {
                        setManufacture(manufacture);
      
                        handleCardQuantity(
                          index,
                          manufacture.quantity || 1,
                          undefined,
                          undefined,
                          parseFloat(value)
                        );
                      }}
                      fontSize="12px"
                      fontWeight="normal"
                      width="100%"
                      height="28px"
                    />
                  }
                </div>

                <span className="mr5 dFlex justifyCenter" style={{ width: "7%" }}>
                  {manufacture?.viewMethod !== 1 ? manufacture?.un?.abbreviation || '' : ''}
                </span>
                
                <div className="dFlex alignCenter justifyCenter" style={{ width: '12%', marginTop: 4 }}>
                  {renderConsumeIcon(manufacture) ? (
                    <Tooltip
                      title={
                        <div className="dFlex flexColumn">
                          <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                            Consumo das fichas relacionadas a esta produção
                          </p>
                        </div>
                      }
                      aria-label="filters"
                      placement="right-start"
                      // disableHoverListener={!tooltip}
                    >
                      <IconButton
                        edge="start"
                        size="small"
                        color="inherit"
                        aria-label="close"
                        onClick={() => {
                          const isComplementary =
                            type === 'recipe'
                              ? manufacture?.isComplementary
                              : manufacture?.items?.some((el) => el?.isComplementary);

                          const hasRecipeItemsDiffQuantities =
                            manufacture?.items?.filter(
                              (el) => el?.type === 'INTERNAL'
                            )?.length;

                          if (type === 'recipe' && hasRecipeItemsDiffQuantities) {
                            setModalStates({
                              ...modalStates,
                              modalDiffQuantities: true
                            });
                            setSelectedCard(manufacture);

                            return null;
                          }

                          if (
                            isComplementary &&
                            manufacture?.engine?.yield !== manufacture?.yield
                          ) {
                            setModalStates({
                              ...modalStates,
                              modalConsumeQuantities: true
                            });
                            setSelectedCard(manufacture);

                            return null;
                          }
                        }}
                        style={{
                          outline: '0',
                          cursor: status === 'canceled' ? 'auto' : 'pointer'
                        }}
                      >
                        <img
                          src={CardConsumeIcon}
                          style={{ width: '16px' }}
                          alt="CardConsumeIcon"
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null} 
                </div>
              </div>
            : null}
          </td>

          <td
            className="description pl8 pr8"
            style={{
              borderLeft: 'solid 1px #454A67',
              background: hasReview && 'rgba(255, 255, 255, .2)'
            }}
          >
            {hasSuggestion && (
              <Tooltip
                title={
                  <div className="dFlex flexColumn">
                    <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                      Sugestão Bloom
                    </p>

                    <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                      {manufacture?.description}
                    </p>

                    <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                      QTDE:{' '}
                      {manufacture?.engine?.quantity.toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}{' '}
                      {type === 'transformation'
                        ? manufacture?.items?.[0]?.un?.abbreviation || ''
                        : manufacture?.un?.abbreviation || ''}
                    </p>

                    <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                      RENDIMENTO:{' '}
                      {manufacture?.engine?.yield.toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}{' '}
                      {type === 'transformation'
                        ? manufacture?.items?.[0]?.un?.abbreviation || ''
                        : manufacture?.un?.abbreviation || ''}
                    </p>
                  </div>
                }
                aria-label="filters"
                placement="right-start"
                // disableHoverListener={!tooltip}
              >
                <IconButton
                  edge="start"
                  size="small"
                  color="inherit"
                  aria-label="close"
                  style={{
                    padding: 0,
                    margin: 'auto',
                    outline: '0',
                    cursor: status === 'canceled' ? 'auto' : 'pointer'
                  }}
                >
                  <img
                    src={EngineIcon}
                    style={{ width: '16px' }}
                    alt="EngineIcon"
                  />
                </IconButton>
              </Tooltip>
            )}
          </td>

          <td
            className="description"
            style={{ background: hasReview && 'rgba(255, 255, 255, .2)' }}
          >
            <div className="dFlex w100 alignCenter">
              <ButtonStatusCustomColor
                className="productionButtonStatus"
                color={buttonStatusInfo(manufacture, hasReview).statusColor}
                background={
                  buttonStatusInfo(manufacture, hasReview).statusBackground
                }
              >
                <span className="fontSize10">
                  {buttonStatusInfo(manufacture, hasReview).statusText}
                </span>
              </ButtonStatusCustomColor>
            </div>
          </td>

          <td
            className="description pr8 pl16"
            style={{ background: hasReview && 'rgba(255, 255, 255, .2)' }}
          >
            {manufacture?.statusAction === "TO_PRODUCE" ?
              renderRestartProductionButton(manufacture, manufacture?.status !== 'WAITING')
            :
              renderIncludeNewProductionButton(manufacture, disabledToProduce, type)
            } 

            {manufacture?.statusAction === "REFUSED" ?
              renderRestartProductionButton(manufacture, manufacture?.status !== 'WAITING', 'reject')
            :
              <Tooltip
                title={
                  <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                    Rejeitar Produção
                  </p>
                }
                aria-label="filters"
                placement="right-start"
                // disableHoverListener={!tooltip}
              >
                <IconButton
                  edge="start"
                  className="ml5"
                  disabled={status === 'finish'}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    if (disabledToReject) return;

                    handleSubmitProduction(manufacture, 'REFUSED');
                  }}
                  aria-label="close"
                  style={{
                    outline: '0',
                    cursor: disabledToReject ? 'auto' : 'pointer'
                  }}
                >
                  <img
                    src={RejectProduction}
                    style={{ width: '18px', opacity: disabledToReject && '.2' }}
                    alt={
                      disabledToReject ? 'DisabledProduction' : 'RejectProduction'
                    }
                  />
                </IconButton>
              </Tooltip>
            } 
          </td>

          <td
            className="description right pr8 pl8"
            style={{ borderLeft: 'solid 1px #454A67' }}
          >
            {manufacture?.statusAction === 'TO_PRODUCE' ? (
              <Tooltip
                title={
                  <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                    {
                      currentStatusInfo(
                        manufacture,
                        manufacture?.otherManufactures
                      ).tooltip
                    }
                  </p>
                }
                aria-label="filters"
                placement="right-start"
                // disableHoverListener={!tooltip}
              >
                <IconButton
                  edge="start"
                  className="dBlock mAuto mt0 mb0"
                  size="small"
                  color="inherit"
                  // onClick={() => {
                  //   if (manufacture.disabled) return;

                  //   handleSubmitProduction(manufacture, 'REFUSED');
                  // }}
                  aria-label="close"
                  style={{
                    outline: '0',
                    cursor: 'auto',
                    border: currentStatusInfo(manufacture).border,
                    padding: 0
                  }}
                >
                  <img
                    src={currentStatusInfo(manufacture).icon}
                    style={{ width: '16px' }}
                    alt={currentStatusInfo(manufacture).icon}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              manufacture?.otherManufactures > 0 && (
                <Tooltip
                  title={
                    <p className="mb0 bold" style={{ padding: '2px 6px' }}>
                      {`${manufacture?.otherManufactures} ${
                        manufacture?.otherManufactures > 1
                          ? 'produções incluídas'
                          : 'produção incluída'
                      }`}
                    </p>
                  }
                  aria-label="filters"
                  placement="right-start"
                >
                  <IconButton
                    edge="start"
                    className="dBlock mAuto mt0 mb0"
                    size="small"
                    color="inherit"
                    aria-label="close"
                    style={{
                      outline: '0',
                      cursor: 'auto',
                      border: '2px solid #F2B90D',
                      padding: 0
                    }}
                  >
                    <img src={EmptyIcon} style={{ width: '16px' }} />
                  </IconButton>
                </Tooltip>
              )
            )}
          </td>
        </tr>

        {type === 'recipe' &&
        cards &&
        cards.find((item, cardIndex) => cardIndex === index)
          ? handleRecipe(
              manufacture,
              manufactures.find((el) => el._id === manufacture._id)?.open,
              index,
              hasSuggestion
            )
          : type === 'transformation' &&
            cards &&
            cards.find((item, cardIndex) => cardIndex === index)
          ? handleTransformation(
              manufacture,
              manufactures.find((el) => el._id === manufacture._id)?.open,
              index,
              hasSuggestion
            )
          : null}

        <tr className={`${manufacture?.hide && 'dNone'}`}>
          <td colSpan="9">
            <hr style={{ background: '#1F2445' }} />
          </td>
        </tr>
      </>
    )
  })
}

export default DesktopRows;
