/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import CountInfosArea from './cockpitInfos/CountInfosArea';
import ProductionInfosArea from './cockpitInfos/ProductionInfosArea';
import ReceivementInfosArea from './cockpitInfos/ReceivementInfosArea';
import SalesInfosArea from './cockpitInfos/SalesInfosArea';
import ShoppingInfosArea from './cockpitInfos/ShoppingInfosArea';
import SkusInfosArea from './cockpitInfos/SkusInfosArea';

const environments = Environment(process.env.REACT_APP_ENV);

function Cockpit({ userInfo }) {
  const { id: paramsID } = useParams();
  const actualDate = new Date();
  const actualDateLessOne = moment(actualDate)?.subtract(1, 'day')?.format('YYYY-MM-DD');
  const actualDateLessFourteen = moment(actualDate)?.subtract(14, 'day')?.format('YYYY-MM-DD');
  const [shoppingFirstDate, setShoppingFirstDate] = useState(moment().startOf('isoWeek').subtract(2, 'week'))
  const [shoppingOrderType, setShoppingOrderType] = useState('positiveEvolution')
  const [filters] = useState([
    { param: 'originId', value: paramsID, urlParam: `originId=${paramsID}` },
    { param: 'days', value: 7, urlParam: `days=7` }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [cmvInfos, setCmvInfos] = useState()
  const [receivementInfos, setReceivementInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [countInfos, setCountInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [salesInfos, setSalesInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [skusInfos, setSkusInfos] = useState({
    content: [],
    contentFormatted: [],
  })
  const [productionInfos, setProductionInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [shoppingInfos, setShoppingInfos] = useState({
    content: [],
    loading: false,
  })
  const [shoppingTotalInfos, setShoppingTotalInfos] = useState({
    content: [],
    loading: false,
  })

  const getCmvInfos = () => {
    setCmvInfos({
      ...cmvInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cockpit/cmv?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setCmvInfos({
          ...cmvInfos,
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setCmvInfos({
          ...cmvInfos,
          content: '',
          loading: false
        })
        console.log(error);
      });
  }

  const getSalesInfos = () => {
    setSalesInfos({
      ...salesInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cockpit/salesProjection?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        const mainObj = response?.data?.content?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setSalesInfos({
          contentFormatted: [
            {
              title: 'vendas',
              data: mainObj?.map(el => parseFloat(el?.sales)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `R$ ${el?.sales?.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}`
              })),
              color: '#5062F0'
            },
            {
              title: 'projeção',
              data: mainObj?.map(el => parseFloat(el?.projection)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `R$ ${el?.projection?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`
              })),
              color: '#F2B90D'
            },
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          dates: mainObj?.map(el => el?.date),
          loading: false
        })
      })
      .catch((error) => {
        setSalesInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getSkusInfos = () => {
    setSkusInfos({
      ...productionInfos,
      loading: true
    })
    
    const URL = `${environments.dashBoard}/cockpit/skus?restaurantId=${paramsID}&lastDays=60`

    Axios.get(URL)
      .then((response) => {
        setSkusInfos({
          content: response?.data,
          loading: false
        })
      })
      .catch((error) => {
        setSkusInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getProductionInfos = () => {
    setProductionInfos({
      ...productionInfos,
      loading: true
    })
    
    const URL = `${environments.dashBoard}/cockpit/manufactures?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setProductionInfos({
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setProductionInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getCountInfos = () => {
    setCountInfos({
      ...countInfos,
      loading: true
    })

    Axios.get(`${environments.dashBoard}/cockpit/counts?restaurantId=${paramsID}&lastDays=60`)
      .then((response) => {
        setCountInfos({
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setCountInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getReceivementInfos = () => {
    setReceivementInfos({
      ...receivementInfos,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/orders/total?originId=${paramsID}&rangeDays=14`)
      .then((response) => {
        const mainObj = response?.data?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setReceivementInfos({
          contentFormatted: [
            {
              title: 'à receber',
              data: mainObj?.map(el => el?.count),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.count} ${el?.count === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#F2B90D'
            },
            {
              title: 'recebido',
              data: mainObj?.map(el => el?.received),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.received} ${el?.received === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#08AA15'
            },
            {
              title: 'recebido s/ catalogar',
              data: mainObj?.map(el => el?.receivedWithoutCataloging),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.receivedWithoutCataloging} ${el?.receivedWithoutCataloging === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#F53D4C'
            }
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          labels: mainObj?.map(el => moment(el?.date).add(3, 'hours')?.format('DD/MM')),
          loading: false
        })
      })
      .catch((error) => {
        setReceivementInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getShoppingInfos = () => {
    setShoppingInfos({
      ...shoppingInfos,
      loading: true
    })
    const firstDateFormatted = moment(shoppingFirstDate).format('YYYY-MM-DD')

    Axios.get(`${environments.dashBoard}/cockpit/monthlyEvolution?restaurantId=${paramsID}&startDates=${`${firstDateFormatted}`}&orderBy=${shoppingOrderType}`)
    .then((response) => {
      setShoppingInfos({
        content: response?.data?.content,
        loading: false
      })
    })
    .catch((error) => {
      setShoppingInfos({
        content: [],
        loading: false
      })
      console.log(error);
    });
  }

  const getShoppingTotalInfos = () => {
    setShoppingTotalInfos({
      ...shoppingTotalInfos,
      loading: true
    })
    const firstDateFormatted = moment(shoppingFirstDate).format('YYYY-MM-DD')

    Axios.get(`${environments.dashBoard}/cockpit/monthlyEvolution?restaurantId=${paramsID}&startDates=${`${firstDateFormatted}&totals=true`}`)
    .then((response) => {
      setShoppingTotalInfos({
        content: response?.data?.content,
        loading: false
      })
    })
    .catch((error) => {
      setShoppingTotalInfos({
        content: [],
        loading: false
      })
      console.log(error);
    });
  }

  useEffect(() => {
    getCmvInfos()
    getSalesInfos()
    getSkusInfos()
    getProductionInfos()
    getCountInfos()
    getReceivementInfos()
  }, [paramsID])

  useEffect(() => {
    getShoppingInfos()
    getShoppingTotalInfos()
  }, [paramsID, shoppingOrderType])

  return (
    <Layout>
      <GlobalStyle/>
      
      <TitleHead title="Cockpit" />

      <Container className="cockpitInfosPage mb0">
        <PageTitleAndFilter
          title={`Cockpit | últimos ${14} dias`}
        />

        <div className="dFlex w100">
          <SalesInfosArea 
            salesInfos={salesInfos}
            cmvInfos={cmvInfos}
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
          />

          <SkusInfosArea 
            skusInfos={skusInfos} 
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
          /> 
        </div> 

        <div className="dFlex w100">
          <div className="dFlex flexColumn" style={{ width: "49%", marginRight: ".5vw" }}>
            <div className="dFlex w100">
              <ProductionInfosArea 
                productionInfos={productionInfos} 
                paramsID={paramsID}
                actualDateLessOne={actualDateLessOne}
                actualDateLessFourteen={actualDateLessFourteen}
              />

              <CountInfosArea 
                countInfos={countInfos} 
                paramsID={paramsID}
                actualDateLessOne={actualDateLessOne}
                actualDateLessFourteen={actualDateLessFourteen}
              />
            </div>

            <ReceivementInfosArea 
              receivementInfos={receivementInfos}
              paramsID={paramsID}
              actualDateLessOne={actualDateLessOne}
              actualDateLessFourteen={actualDateLessFourteen}
            />          
          </div>

          <ShoppingInfosArea 
            shoppingInfos={shoppingInfos}
            shoppingOrderType={shoppingOrderType}
            shoppingTotalInfos={shoppingTotalInfos}
            setShoppingOrderType={setShoppingOrderType}
            shoppingFirstDate={shoppingFirstDate}
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
          />
        </div>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cockpit);
