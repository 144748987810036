import { makeStyles } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';
import './scss/common/base.scss';
import './scss/dialogs/base.scss';
import './scss/components/base.scss';
import './scss/pages/base.scss';
import './scss/responsive/base.scss';

export const GlobalStyle = createGlobalStyle`
  /* ALIGNMENTS */
  .alnright {
    text-align: right;
  }

  .alncenter {
    text-align: center;
  }

  .alnleft {
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* TABLES */
  table.mobile {
    display: none
  }

  table {
    tr {
      :hover {
        td {
          background-color: rgba(80, 98, 240, 0.15) !important;

          td {
            background: transparent !important;
          }
        }
      }

      &.withoutHover {
        &:hover {
          td,
          th {
            background: transparent !important;
          }
        }
      }
    }
  }

  .widthTable {
    width: 60% !important;
  }

  .widthTablePersonal{
    &.max1000{
      max-width: 1000px;
    }
    &.w10{
      width: 10% !important;
    }
    &.w20{
      width: 20% !important;
    }
    &.w30{
      width: 30% !important;
    }
    &.w40{
      width: 40% !important;
    }
    &.w50{
      width: 50% !important;
    }
    &.w60{
      width: 60% !important;
    }
    &.w70{
      width: 70% !important;
    }
    &.w80{
      width: 80% !important;
    }
    &.w90{
      width: 90% !important;
    }
    &.w95{
      width: 95% !important;
    }
    &.w98{
      width: 98% !important;
    }
    &.w100{
      width: 100% !important;
    }
    &.responsiveFull{
      @media (max-width: 1024px) {
        width: 100% !important;
      }
      @media (max-width: 1200px) {
        width: 90% !important;
      }
    }
  }
  
  .borderL {
    border-left: 1px solid #666770;
  }

  .borderLGray {
    border-left: 1px solid #D2D3D7;
  }
  
  table.desktop {
    width: 100%;
    table-layout: auto;
    empty-cells: hide;
    background: #FFF;
    border-spacing: 1px 0;
    border-collapse: collapse;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    padding-bottom: 15px;
    display: table;
    font-family: "Lato";

    .deleteIcon,
    .editIcon,
    .playIcon
    {
      width: 20px;
      height: 20px;
    }

    .disabledButton:disabled {
        opacity: 0.2;
    }

    tr {
      line-height: 30px;
      border: none;

      &.withoutHover {
        &:hover {
          td,
          th {
            background: transparent !important;
          }
        }
      }

      :hover {
        td {
          background-color: rgba(80, 98, 240, 0.15) !important;

          td {
            background: transparent !important;
          }
        }
      }

      :last-child {
        td {
          border-bottom: 0;
        }
      }

      &.headerSubtitle {
        td {
          background: #F6F6F8 !important
        }

        :hover {
          td {
            background-color: #F6F6F8 !important;
          }
        }
      }
    }

    .headerTitle {
      th,
      td {
        background: #1F2445;
        position: sticky;
        top: -6px;
        z-index: 99;
        color: #FFF;

        &:first-of-type {
          overflow: hidden;
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          overflow: hidden;
          border-top-right-radius: 4px;
        }

        &:first-of-type,
        &:last-of-type {
          padding: 0 12px;
        }
      }

      .columnItens {
        width: 18%;

        @media only screen and (min-width: 1030px) and (max-width: 1367px) {
            width: 16%;
        }

        @media only screen and (min-width: 1606px) and (max-width: 1920px) {
            width: 17.97%;
        }
      }

      .columnQuantity {
        width: 6%;

        @media only screen and (min-width: 1030px) and (max-width: 1367px) {
            width: 5%;
        }
      }

      .columnType {
        width: 6.5%;

        @media only screen and (min-width: 1030px) and (max-width: 1367px) {
            width: 6%;
        }

        @media only screen and (min-width: 1606px) and (max-width: 1920px) {
            width: 3.9%;
        }
      }

    }

    .headerSubtitle {
      th,
      td {
        background: #F6F6F8;
        position: sticky;
        top: 24px;
        z-index: 999;
        color: #77798B;

        &:first-of-type,
        &:last-of-type {
          padding: 0 12px;
        }
      }
    }

    .headerBorder {
      th,
      td {
        position: sticky;
        top: 39px;
        z-index: 999;
      }
    }

    .lastChildRender {
      display: flex;
      div {
        display: flex;
        height: 30px;
      }
      div:last-child {

        p {
          display: none;
        }
      }
    }

    .titleLineBorder {
      border-bottom: 1px solid rgb(211, 211, 211);
    }

    .bigTitle {
      font-family: "Lato";
      font-size: 16px;
      line-height: 60px;
    }

    .title {
      font-family: "Lato";
      font-size: 12px;
      font-weight: bold;
      line-height: 30px;
      border-bottom: 1px solid #ccc;
    }

    .subtitle {
      font-family: "Lato";
      font-size: 9px;
      font-weight: bold;
    }

    .fontSize12 {
      font-family: "Lato";
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .left {
      text-align: left;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }

    .description {
      font-family: "Lato";
      font-size: 12px;
      line-height: 30px;
    }

    .fontColor {
      color: #000319;
    }

    .fontColorMeta {
      color: #333541;
    }

    .editIcon {
      width: 20px;
      height: 20px;
    }

    .deleteIcon {
      width: 20px;
      height: 20px;
    }

    hr {
      margin: 0;
    }
  }

  .pagination {
    font-family: "Lato";
    margin: 12px 0 10px 0;
    justify-content: flex-start;
    display: flex;

    button {
      border-radius: 8px !important;
    }

    .MuiPaginationItem-outlinedPrimary {
      border-color: #313347;

      svg {
        color: #313347!important;
      }

      &.Mui-selected {
        background: #5062F0!important;
        color: #FFF!important;
      }
    }
  }

  @media (max-width: 1024px) {
    table.mobile {
      width: 100%;
      background: #FFF;
      table-layout: auto;
      empty-cells: hide;
      border-collapse: separate;
      border-spacing: 20px 0;
      border: 1px solid #eaeaea;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 0 0px rgba(0,0,0,0.12);
      display: table;

      td:nth-child(1) {
        width: 30%;
        text-align: right;
      }

      td:nth-child(2) {
        width: 70%;
      }

      .bigTitle {
        width: 100%;
        font-family: "Lato";
        font-size: 16px;
        line-height: 30px;
        text-align: center !important;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
      }

      .subtitle {
        font-family: "Lato";
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        padding-right: 10px;
      }

      .description {
        font-family: "Lato";
        font-size: 14px;
        line-height: 30px;
        text-align: left;
      }

      .actions {
        width: 100%;
        padding: 10px;
        text-align: center;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }

      hr {
        margin: 0;
      }

      .pagination {
        font-family: "Lato";
        margin: 20px 0 10px 0;
        justify-content: center;
        display: flex;
      }
    }

    .tableSku {
      .subtitle {
        font-family: "Lato";
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        padding-right: 10px;
      }

      .description {
        font-family: "Lato";
        font-size: 14px;
        line-height: 30px;
      }
    }

    .left {
      text-align: left;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }
  }

  /* FILTERS */
  .filters {
    margin: 10px;
    padding: 0 10px;
    border-radius: 20px;
  }
`;

/* CONTAINERS */
export const Container = styled.div`
  display: block;
  margin-bottom: 15px;
`;

/* CLASSES */
export const classes = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },

  formControl: {
    width: 200,
    margin: theme.spacing(1),
    minWidth: 200
  }
}));
