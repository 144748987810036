/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';
import Draggable from 'react-draggable';
import { useParams } from 'react-router';

import Button from 'components/Button/button';
import periodOptions from 'data/periodicities.json';
import AlertBlueIcon from 'images/icons/signals/alerts/alertWhiteLightBlueBgCircle.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

import InternalProductRelated from './Dialogs/InternalProductRelated';
import ProductRelatedMenus from './Dialogs/ProductRelatedMenus';
import { Container, ConfiguredProducts, CmvAndCountSetupCard, TableCurrentSetups } from './styles';
import Table from './Table';

import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const environments = Environment(process.env.REACT_APP_ENV);

const arrayDays = [
  {
    "description": "Todos"
  },
  {
    "id": 0,
    "description": "Domingo"
  },
  {
    "id": 1,
    "description": "Segunda"
  },
  {
    "id": 2,
    "description": "Terça"
  },
  {
    "id": 3,
    "description": "Quarta"
  },
  {
    "id": 4,
    "description": "Quinta"
  },
  {
    "id": 5,
    "description": "Sexta"
  },
  {
    "id": 6,
    "description": "Sábado"
  }
]

const freeMonthDayOptions = Array.from({ length: 31 }, (_, i) =>
  (i + 1).toString()
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CmvAndCountSetup({
  open,
  setModalSettings,
  modalSettings,
  updateStorageLocations,
  setMessage,
}) {
  const { id: paramsID } = useParams();
  const [mainList, setMainList] = useState([]);
  const [periodicitiesOptions, setPeriodicitiesOptions] = useState([])
  const [storageLocationOptions, setStorageLocationOptions] = useState([])
  const [currentPeriodicity, setCurrentPeriodicity] = useState()
  const [period, setPeriod] = useState()
  const [pendentItems, setPendentItems] = useState(false);
  const [enableToLoadPeriodicities, setEnableToLoadPeriodicities] = useState(false)
  const [enableToLoadStorageLocations, setEnableToLoadStorageLocations] = useState(false)
  const [loading, setLoading] = useState(false)
  const [internalProductRelated, setInternalProductRelated] = useState({
    loading: false,
    content: ''
  });
  const [productRelatedMenus, setProductRelatedMenus] = useState({
    loading: false,
    content: ''
  });

  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    size: 100,
    filters: [],
  });

  const [freeWeeklyDays, setFreeWeeklyDays] = useState([]);
  const [freeMonthlyDays, setFreeMonthlyDays] = useState([]);
  const [firstDayWeek, setFirstDayWeek] = useState('');
  const [secondDayWeek, setSecondDayWeek] = useState('');
  const [firstDayMonth, setFirstDayMonth] = useState('');
  const [secondDayMonth, setSecondDayMonth] = useState('');
  const [updatedItems, setUpdatedItems] = useState([]);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [hasProductUpdates, setHasProductUpdates] = useState(false);
  const [internalDialogSettings, setInternalDialogSettings] = useState([
    { id: 1, name: 'internalProductRelated', open: false, fullScreen: false },
    { id: 2, name: 'productRelatedMenus', open: false, fullScreen: false },
    { id: 3, name: 'confirmCloseDialog', open: false, fullScreen: false },
  ]);

  const { getId, mainLoading, page, size } = callSettings;

  const [filtersChanged, setFiltersChanged] = useState(callSettings.filters);
  const [validFields, setValidFields] = useState({
    monthDays: false,
    weekDays: false,
    dayOneMonth: false,
    dayTwoMonth: false,
    dayOneWeek: false,
    dayTwoWeek: false
  });

  const validateRequiredFields = () => {
    const currentLabel = period?.label

    if (currentLabel === 'DIARY') {
      return true
    }

    if (currentLabel === 'BIMONTHLY') {
      setValidFields({
        ...validFields,
        dayOneMonth: !firstDayMonth,
        dayTwoMonth: !secondDayMonth
      })

      return firstDayMonth && secondDayMonth
    }

    if (currentLabel === 'BIWEEKLY') {
      setValidFields({
        ...validFields,
        dayOneWeek: !firstDayWeek?.description,
        dayTwoWeek: !secondDayWeek?.description
      })

      return firstDayWeek?.description && secondDayWeek?.description
    }

    if (currentLabel === 'MONTHLY') {
      setValidFields({
        ...validFields,
        dayOneMonth: !firstDayMonth,
      })

      return firstDayMonth
    }

    if (currentLabel === 'WEEKLY') {
      setValidFields({
        ...validFields,
        dayOneWeek: !firstDayWeek?.description,
      })

      return firstDayWeek?.description
    }

    if (currentLabel === 'FREE_WEEKLY') {
      setValidFields({
        ...validFields,
        weekDays: !freeWeeklyDays?.length,
      })

      return freeWeeklyDays?.length
    }

    if (currentLabel === 'FREE_MONTHLY') {
      setValidFields({
        ...validFields,
        monthDays: !freeMonthlyDays?.length,
      })

      return freeMonthlyDays?.length
    }
  }

  const configProductsStyle = () => {
    if (mainList?.nConfigProducts === 0) {
      return '#F53D4C'
    }

    if (mainList?.nConfigProducts !== mainList?.nTotalProducts) {
      return '#AA8208'
    }

    return '#006608'
  }

  const getInternalProductRelated = (product) => {
    setInternalProductRelated({
      ...internalProductRelated,
      loading: true,
    });

    Axios
      .get(
        `${environments.dashBoard}/cmvcount/${product?.id}/cards?restaurantId=${getId}`
      )
      .then((response) => {
        setInternalProductRelated({
          loading: false,
          content: response?.data || ''
        });

        setInternalDialogSettings(
          internalDialogSettings.map((modal) =>
            modal.name === 'internalProductRelated' ? { ...modal, open: true } : modal
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setInternalProductRelated({
          loading: false,
          content: ''
        });
      });
  }

  const getProductRelatedMenus = (product) => {
    setProductRelatedMenus({
      ...productRelatedMenus,
      loading: true,
    });

    Axios
      .get(
        `${environments.dashBoard}/cmvcount/${product?.id}/menus?restaurantId=${getId}`
      )
      .then((response) => {
        setProductRelatedMenus({
          loading: false,
          content: response?.data || ''
        });

        setInternalDialogSettings(
          internalDialogSettings.map((modal) =>
            modal.name === 'productRelatedMenus' ? { ...modal, open: true } : modal
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setProductRelatedMenus({
          loading: false,
          content: ''
        });
      });
  }

  const getMainContent = useCallback(
    () => {
      (async () => {
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));

        Axios.get(`${environments.dashBoard}/cmvCount/products?restaurantId=${getId}`)
          .then((response) => {
          const periodicityResponse = response?.data?.periodicity
          setMainList(response?.data
            ?
              {
                ...response?.data,
                periodicity: {
                  ...periodicityResponse,
                  counts: periodicityResponse?.counts?.sort((a, b) => a?.setup?.storageLocationDescription >= b?.setup?.storageLocationDescription ? 1 : -1)
                }
              }
            : []
          );

          setCurrentPeriodicity(periodicitiesOptions.find(el => el?.description === periodicityResponse?.description))
          setPeriod(periodOptions?.find(el => el?.label === periodicityResponse?.type))

          if (periodicityResponse?.type === 'FREE_MONTHLY') {
            setFreeMonthlyDays(periodicityResponse?.days?.split(','))
          }

          if (periodicityResponse?.type === 'FREE_WEEKLY') {
            setFreeWeeklyDays(arrayDays?.filter(el => periodicityResponse?.days?.includes(el?.id)))
          }

          if (periodicityResponse?.type === 'BIMONTHLY' || period?.label === 'MONTHLY') {
            setFirstDayMonth(periodicityResponse?.first)
            setSecondDayMonth(periodicityResponse?.type === 'BIMONTHLY' ? periodicityResponse?.second : '')
          }

          if (periodicityResponse?.type === 'BIWEEKLY' || periodicityResponse?.type === 'WEEKLY') {
            setFirstDayWeek(arrayDays?.find(el => el?.id === periodicityResponse?.first))
            setSecondDayWeek(periodicityResponse?.type === 'BIWEEKLY' ? arrayDays?.find(el => el?.id === periodicityResponse?.second) : '')
          }
          
          setCallSettings((prevState) => ({
            ...prevState,
            mainLoading: false,
          }));
        }).catch(() => {
          setCallSettings((prevState) => ({
            ...prevState,
            mainLoading: false
          }));
        })
      })();
    },
    [filtersChanged, enableToLoadPeriodicities, enableToLoadStorageLocations]
  );

  const onSubmit = () => {
    if (!validateRequiredFields()) return null

    setLoading(true)
    const defaultPeriodicity = mainList?.periodicity
    const isFreePeriod = period?.label === 'FREE_MONTHLY' || period?.label === 'FREE_WEEKLY'
    const isMonthPeriod = period?.label === 'BIMONTHLY' || period?.label === 'MONTHLY'
    const isWeekPeriod = period?.label === 'BIWEEKLY' || period?.label === 'WEEKLY'
    let currentDays = defaultPeriodicity?.days
    let currentFirstDay = defaultPeriodicity?.first
    let currentSecondDay = defaultPeriodicity?.second
    
    if (isFreePeriod) {
      currentDays = period?.label === 'FREE_MONTHLY' ? freeMonthlyDays : freeWeeklyDays?.map(el => el?.id)
    }

    if (isMonthPeriod) {
      currentFirstDay = firstDayMonth
      currentSecondDay = secondDayMonth
      currentDays = []
    }

    if (isWeekPeriod) {
      currentFirstDay = firstDayWeek
      currentSecondDay = secondDayWeek
      currentDays = []
    }

    const body = {
      restaurantId: getId,
      periodicity: {
        ...defaultPeriodicity,
        update: hasUpdates,
        days: currentDays,
        description: currentPeriodicity?.description,
        type: period?.label,
        first: currentFirstDay,
        second: currentSecondDay
      },
      products: updatedItems
    }

    Axios.post(
      `${environments.catalog}/cmvCount/setup`,
      body
    )
      .then(() => {
        setMessage({
          description: 'Configuração atualizada com sucesso.',
          status: 'success'
        });
        setLoading(false)
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
          )
        );
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          description: 'Erro ao atualizar configuração',
          status: 'error'
        });
      });
  }

  const renderMonthlyDoubleDaysFields = (showSecondDay) => (
    <>
      <TextField
        size="small"
        onChange={(e) => {
          setHasUpdates(true)
          setFirstDayMonth(e.target.value)
        }}
        name="firstDay"
        label="Dia 1"
        variant="outlined"
        value={firstDayMonth}
        type="number"
        fullWidth
        required={
          period?.label !== 'MONTHLY' &&
            period?.label !== 'BIMONTHLY'
            ? false
            : 'required'
        }
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }}
        inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
        className={`textFieldSmall ${validFields?.dayOneMonth && 'labelError'}`}
        placeholder="Digite aqui..."
        InputLabelProps={{
          shrink: true
        }}
      />

      {showSecondDay &&
        <TextField
          size="small"
          onChange={(e) => {
            setHasUpdates(true)
            setSecondDayMonth(e.target.value)
          }}
          name="secondDayWeek"
          label="Dia 2"
          variant="outlined"
          value={secondDayMonth}
          type="number"
          fullWidth
          required={
            period?.label !== 'BIMONTHLY'
              ? false
              : 'required'
          }
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
          inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
          className={`textFieldSmall ${validFields?.dayTwoMonth && 'labelError'}`}
          placeholder="Digite aqui..."
          InputLabelProps={{
            shrink: true
          }}
        />
      }
    </>
  )

  const renderWeeklyDoubleDaysFields = (showSecondDay = false) => (
    <>
      <Autocomplete
        getOptionSelected={(option, value) =>
          option.description === value.description
        }
        getOptionLabel={(option) => option.description}
        options={arrayDays.filter(
          (item) => item.description !== 'Todos'
        )}
        size="small"
        value={firstDayWeek}
        onChange={(_, value) => {
          setHasUpdates(true)
          setFirstDayWeek(value);
        }}
        fullWidth
        className={`autocompleteSmall ${validFields?.dayOneWeek && 'labelError'}`}
        renderInput={(params) => (
          <TextField
            {...params}
            id={params.id}
            name="type"
            label="Dia 1"
            variant="outlined"
            required={
              period?.label !== 'WEEKLY' &&
                period?.label !== 'BIWEEKLY'
                ? false
                : 'required'
            }
            placeholder="Digite aqui..."
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        renderOption={(params) => (
          <Typography
            style={{ fontSize: '12px', fontFamily: 'Lato' }}
          >
            {params.description}
          </Typography>
        )}
      />

      {showSecondDay && 
        <Autocomplete
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          getOptionLabel={(option) => option.description}
          options={arrayDays}
          size="small"
          fullWidth
          value={secondDayWeek}
          onChange={(_, value) => {
            setHasUpdates(true)
            setSecondDayWeek(value);
          }}
          className={`autocompleteSmall ${validFields?.dayTwoWeek && 'labelError'}`}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              name="type"
              label="Dia 2"
              variant="outlined"
              required={
                period?.label !== 'BIWEEKLY'
                  ? false
                  : 'required'
              }
              placeholder="Digite aqui..."
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          renderOption={(params) => (
            <Typography
              style={{ fontSize: '12px', fontFamily: 'Lato' }}
            >
              {params.description}
            </Typography>
          )}
        />
      }
    </>
  )

  useEffect(() => {
    if (!periodicitiesOptions.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/periodicities?page=1&size=1500&originId=${getId}`
        );
        const periodicityJson = await response.json();
        setPeriodicitiesOptions(periodicityJson?.content);
        setEnableToLoadPeriodicities(true)
      })();
    }

    if (!storageLocationOptions.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/storageLocations?page=1&size=1500&originId=${getId}`
        );
        const storageLocationsJson = await response.json();
        setStorageLocationOptions(storageLocationsJson.content);
        setEnableToLoadStorageLocations(true)
      })();
    }
  }, [getId]);

  useEffect(() => {
    if (updateStorageLocations) {
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocationOptions,
        setStorageLocationOptions,
        getId
      );
    }
  }, [updateStorageLocations]);

  useEffect(() => {
    if (enableToLoadPeriodicities && enableToLoadStorageLocations) {
      getMainContent();
    }
  }, [getId, filtersChanged, page, size, enableToLoadPeriodicities, enableToLoadStorageLocations]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => {
        if (hasUpdates || hasProductUpdates) {
          setInternalDialogSettings(
            internalDialogSettings?.map((modal) =>
              modal.name === 'confirmCloseDialog' ? { ...modal, open: true } : modal
            )
          );
        } else {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
            )
          );
        }
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal similarSkuDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>
          CONFIGURAÇÃO DE CONTAGENS X CMV
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (hasUpdates || hasProductUpdates) {
              setInternalDialogSettings(
                internalDialogSettings?.map((modal) =>
                  modal.name === 'confirmCloseDialog' ? { ...modal, open: true } : modal
                )
              );
            } else {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
                )
              );
            }
          }}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="close modal"
          />
        </IconButton>
      </DialogTitle>

      <Container>
        <CmvAndCountSetupCard className="cardDefault pt0">
          <TableCurrentSetups>
            <Autocomplete
              key={currentPeriodicity}
              id="controllable-states-demo"
              getOptionLabel={(option) => option?.description}
              options={periodicitiesOptions}
              size="small"
              value={currentPeriodicity}
              fullWidth
              disabled
              className="autocompleteSmall"
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={params?.id}
                  name="counts"
                  label="Contagem"
                  variant="outlined"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                >
                  {params?.description}
                </Typography>
              )}
            />

            <Autocomplete
              key={period}
              id="controllable-states-demo"
              getOptionLabel={(option) => option?.description}
              options={periodOptions}
              size="small"
              defaultValue={period?.description}
              value={period}
              fullWidth
              className="autocompleteSmall"
              onChange={(_, value) => {
                setHasUpdates(true)
                setPeriod(period != null ? value : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={params?.id}
                  name="frequencies"
                  label="Frequência sugerida"
                  variant="outlined"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                >
                  {params?.description}
                </Typography>
              )}
            />

            {(period?.label === 'BIMONTHLY' || period?.label === 'MONTHLY') &&
              renderMonthlyDoubleDaysFields(period?.label === 'BIMONTHLY')
            }

            {(period?.label === 'BIWEEKLY' || period?.label === 'WEEKLY') &&
              renderWeeklyDoubleDaysFields(period?.label === 'BIWEEKLY')
            }

            {period?.label === 'FREE_WEEKLY' &&
              <Autocomplete
                getOptionSelected={(option, value) =>
                  option.description === value.description
                }
                getOptionLabel={(option) => option.description}
                options={arrayDays}
                size="small"
                fullWidth
                multiple
                limitTags={2}
                value={freeWeeklyDays}
                required={
                  period?.label === 'FREE_WEEKLY'
                    ? false
                    : 'required'
                }
                onChange={(event, value) => {
                  setHasUpdates(true)
                  if (value.find((item) => item.description === 'Todos')) {
                    setFreeWeeklyDays(
                      arrayDays.filter((item) => item.description !== 'Todos')
                    );
                  } else {
                    setFreeWeeklyDays(value);
                  }
                }}
                className={`autocompleteSmall freeDaysField ${validFields?.weekDays && 'labelError'}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    name="type"
                    label="Dias"
                    variant="outlined"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    {params.description}
                  </Typography>
                )}
              />
            }

            {period?.label === 'FREE_MONTHLY' &&
              <Autocomplete
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={freeMonthDayOptions}
                multiple
                limitTags={10}
                size="small"
                fullWidth
                value={freeMonthlyDays}
                required={
                  period?.label !== 'FREE_MONTHLY'
                    ? false
                    : 'required'
                }
                onChange={(_, value) => {
                  setHasUpdates(true)
                  setFreeMonthlyDays(value);
                }}
                className={`autocompleteSmall freeDaysField ${validFields?.monthDays && 'labelError'}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    name="type"
                    label="Dias"
                    variant="outlined"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    {params}
                  </Typography>
                )}
              />
            }

            <ConfiguredProducts>
              {!mainLoading && mainList?.nTotalProducts !== null ?
                <>
                  <span style={{ color: configProductsStyle() }}>
                    {mainList?.nConfigProducts} / {mainList?.nTotalProducts} {" "}
                    {mainList?.nConfigProducts === 1 ? 'produto configurado' : 'produtos configurados'}
                  </span>

                  <Link
                    className={`
                      ${!mainList?.periodicity?.counts?.length && 'disabledLink'}
                    `}
                    target="_blank"
                    to={`/inventario/contagem-diaria/${getId}/${mainList?.periodicity?.counts[0].countId}?countId=${mainList?.periodicity?.counts[0].countId}&countOrigin=COUNTS&countDate=${mainList?.periodicity?.counts[0].countDate}&countAssortDate=${mainList?.periodicity?.counts[0].countAssortDate}&countAssortUser=${mainList?.periodicity?.counts[0].countAssortUser}&periodicityInfos=${`${mainList?.periodicity?.id}_${mainList?.periodicity?.description}_${mainList?.periodicity?.type}_${mainList?.periodicity?.isCmvCount}`}&countIds=${mainList?.periodicity?.counts?.map(el => el?.countId)}&storageLocations=${mainList?.periodicity?.counts?.map(el => `${el?.countId}_${el?.setup?.storageLocationId}_${el?.setup?.storageLocationDescription}`)}`}
                  >
                    <Tooltip
                      title={
                        <div style={{ padding: '4px' }}>
                          <p style={{ margin: '0' }}>
                            Visualizar contagem
                          </p>
                        </div>
                      }
                      aria-label="filters"
                      placement="right-start"
                    >
                      <img
                        src={AlertBlueIcon}
                        alt={AlertBlueIcon}
                        className={`${!mainList?.nConfigProducts && 'dNone'}`}
                        style={{ width: 16, height: 15 }}
                      />
                    </Tooltip>
                  </Link>
                </>
              : 
                <div className="countsCircularProgressBar dFlex justifyCenter">
                  <CircularProgress />
                </div>
              }
            </ConfiguredProducts>
          </TableCurrentSetups>

          <Table
            mainList={mainList}
            setMainList={setMainList}
            isLoading={mainLoading}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            setPendentItems={setPendentItems}
            pendentItems={pendentItems}
            storageLocationOptions={storageLocationOptions}
            getInternalProductRelated={getInternalProductRelated}
            getProductRelatedMenus={getProductRelatedMenus}
            setUpdatedItems={setUpdatedItems}
            updatedItems={updatedItems}
            setHasProductUpdates={setHasProductUpdates}
          />
        </CmvAndCountSetupCard>

        <Button
          onClick={() => {
            onSubmit();
          }}
          disabled={loading}
          className="defaultButton submitButton mlAuto mt12"
          type="submit"
          design="contained"
          label={loading ? 'Criando...' : 'Criar Contagem'}
          style={{ outline: 0 }}
        />
      </Container>

      {internalDialogSettings?.find((modal) => modal.name === 'internalProductRelated').open && (
        <InternalProductRelated
          mainList={internalProductRelated?.content?.cards}
          description={internalProductRelated?.content?.description}
          loading={internalProductRelated?.loading}
          open={internalDialogSettings?.find((modal) => modal.name === 'internalProductRelated').open}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'internalProductRelated' ? { ...modal, open: false } : modal
              )
            );

            setInternalProductRelated({
              content: [],
              loading: false
            })
          }}
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'productRelatedMenus').open && (
        <ProductRelatedMenus
          mainList={productRelatedMenus?.content?.menus}
          description={productRelatedMenus?.content?.description}
          loading={productRelatedMenus?.loading}
          open={internalDialogSettings?.find((modal) => modal.name === 'productRelatedMenus').open}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'productRelatedMenus' ? { ...modal, open: false } : modal
              )
            );

            setProductRelatedMenus({
              content: [],
              loading: false
            })
          }}
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'confirmCloseDialog').open && (
        <ConfirmModal
          open={internalDialogSettings?.find((modal) => modal.name === 'confirmCloseDialog').open}
          content={
            <Typography className="bold">
              Deseja realmente abandonar essas alterações?
            </Typography>
          }
          onConfirm={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
              )
            );

            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          cancelText="Não"
          confirmText="Sim"
        />
      )}
    </Dialog>
  );
}

export default CmvAndCountSetup;
