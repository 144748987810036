import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15px 15px;

  .submitButton {
    span {
      font-size: 12px;
    }
  }
`;

export const CmvAndCountSetupCard = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #04061A40;
  padding: 26px 0 0 0;
  margin-top: 12px;
  max-height: 500px;
  height: auto;
  overflow: auto;
`;

export const TableCurrentSetups = styled.div`
  display: flex;
  padding: 26px 10px 10px;
  background: #FFF;
  z-index: 9;

  .autocompleteSmall,
  .textFieldSmall {
    width: 13%;
    margin-right: 40px;

    &:first-of-type,
    &:nth-of-type(2) {
      width: 20%;
    }

    &.freeDaysField {
      width: 20%;
    }
  }

  span {
    font-size: 12px;
  }
`;

export const ConfiguredProducts = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: auto;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 12px;
  }

  img {
    margin-left: 5px;
  }

  .countsCircularProgressBar {
    .MuiCircularProgress-root {
      width: 30px !important;
      height: 30px !important;
      margin-bottom: 0;
      padding: 5px;
    }
  }
`;

