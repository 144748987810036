/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  FormControlLabel,
  Switch,
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';

import ArrowDownCircleIcon from 'images/icons/arrows/arrowDownRedLightRedBgCircle.svg';
import ArrowUpCircleIcon from 'images/icons/arrows/arrowUpGreenLightGreenBgCircle.svg';

function SalesInfos({ salesInfos, salesInfoType, setSalesInfoType }) {
  const salesTotalValue = salesInfoType ? salesInfos?.sales?.total : salesInfos?.sales?.totalWithDiscount
  const currentProjection = salesInfos?.projection?.totalProjection || salesInfos?.projection?.totalForecastNoSafeMargin
  const isPositiveTarget = salesTotalValue >= currentProjection

  return (
    <div className="cmvSalesCard">
      <p className="cardTitle">VENDAS</p>

      <div className="dFlex alignCenter justifyCenter pRelative w100">
        <span className="bold">
          R$ {(salesTotalValue || 0)?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </span>

        <Tooltip
          title={
            <div className="dFlex flexColumn" style={{ padding: '4px 2px' }}>
              <p className="mb0">
                {isPositiveTarget ? 'Acima' : 'Abaixo'} da meta: {" "}
                {(currentProjection || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </p>
            </div>
          }
          aria-label="add"
        >
          <img
            className="pAbsolute"
            src={isPositiveTarget ? ArrowUpCircleIcon : ArrowDownCircleIcon}
            alt={isPositiveTarget ? ArrowUpCircleIcon : ArrowDownCircleIcon}
            style={{ width: '20px', marginLeft: 150 }}
          />
        </Tooltip>

        <FormControlLabel
          className="toggleDefault m0 dFlex customToggle"
          control={
            <Switch
              checked={salesInfoType}
              onChange={() => {
                setSalesInfoType(!salesInfoType);
              }}
              name="View Mode"
            />
          }
          label={salesInfoType ? 'Vendas com desconto' : 'Vendas sem desconto'}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(SalesInfos);
