/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { TreeSelect } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import BrandSuggestion from 'components/Dialogs/BrandSuggestion';
import EanSuggestion from 'components/Dialogs/EanSuggestion';
import GeneralSuggestions from 'components/Dialogs/GeneralSuggestions';
import GoogleImageSearcher from 'components/Dialogs/GoogleImageSearcher';
import ImageCropper from 'components/Dialogs/ImageCropper';
import RegisterPackageType from 'components/Dialogs/RegisterPackageType';
import FullScreenLoading from 'components/FullScreenLoading';
import TagsManager from 'components/TagsManager';
import DefaultImage from 'images/defaultAndEmpties/defaultImage.svg';
import SwitchIcon from 'images/icons/arrows/editEan.svg';
import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import EmptyCheckboxIcon from 'images/icons/empties/emptyCheckboxIcon.svg';
import EmptyCheckboxIconWhite from 'images/icons/empties/emptyCheckboxIconWhite.svg';
import BloomIcon from 'images/icons/logo/bloomIconLarge.svg';
import GoogleSearchIcon from 'images/icons/searches/googleSearchIcon.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import CloseGrayIcon from 'images/icons/signals/closesAndCancels/closeGray.svg';
import InfoIcon from 'images/icons/signals/infos/info.svg';
import DeleteIcon from 'images/icons/signals/removesAndDeletes/delete.svg';
import BrandForm from 'pages/catalog/brands/BrandForm';
import ProducerForm from 'pages/catalog/manufacturers/ProducerForm';
import ProvidersTable from 'pages/cataloguing/components/AvailablePackaging';
import ModalTable from 'pages/cataloguing/components/PackagesTable';
import SkusTable from 'pages/cataloguing/components/SkusTable';
import TreeContainer from 'pages/categories/CategoriesDialog';
import PeriodicityForm from 'pages/inventory/periodicity/PeriodicityForm';
import StorageLocationForm from 'pages/inventory/storage-Locations/StorageLocationForm';
import ProviderForm from 'pages/suppliers/ProviderForm';
import getOptions from 'services/getOptions';
import googleSearch from 'services/googleSearch';
import calcSKUPriceFromPackage from 'utils/calcSKUPriceFromPricePackage';
import copyClipBoard from 'utils/copyClipboard';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

const environments = Environment(process.env.REACT_APP_ENV);

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function SkuModal(props) {
  const userInfo = useSelector((currState) => currState.user);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const isUserPlatform = localStorage.getItem('userPlatform')
  const classes = useStyles();
  const activeCompany =
    userInfo?.companies?.groups[0]?.subGroups[0]?.stores?.map((store) => ({
      ...store,
      name: store?.name?.toUpperCase()
    }));

  const { handleSubmit } = useForm();
  const isAdminMode = userInfo?.profile?.[0]?.[0]?.name === 'admin' || userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isRestaurant =
    props.userInfo?.profile?.[0]?.[0]?.name === 'restaurants';
  const isCatalogProfile =
    props.userInfo?.profile?.[0]?.[0]?.name === 'cataloguer';
  const isQuoter = props.userInfo?.profile?.[0]?.[0]?.name === 'quoter';

  const [cropper, setCropper] = useState();
  const [modalChanged, setModalChanged] = useState(false);
  const [isMultipleSku, setIsMultipleSku] = useState(
   false
  );
  const [multipleSkuData, setMultipleSkuData] = useState(
    props.multipleSkuData || []
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generalSuggestionLoading, setGeneralSuggestionLoading] =
    useState(false);
  const [items, setItems] = useState(props.items);
  const [providers, setProviders] = useState(props.providers);
  const [products, setProducts] = useState(props.products);
  const [tags, setTags] = useState(props.tags);
  const [categories, setCategories] = useState(props.categories);
  const [brands, setBrands] = useState(props.brands || []);
  const [packageTypes, setPackageTypes] = useState(props.packageTypes);
  const [skus, setSkus] = useState(props.skus);
  const [storageLocations, setStorageLocations] = useState(
    props.storageLocations
  );
  const [periodicities, setPeriodicities] = useState(props.periodicities);
  const [addProvider, setAddProvider] = useState(false);
  const [consumptionUnits, setConsumptionUnits] = useState([
    {
      id: 1,
      description: 'litro',
      abbreviation: 'lt'
    },
    {
      id: 2,
      description: 'quilograma',
      abbreviation: 'kg'
    },
    {
      id: 3,
      description: 'unidade',
      abbreviation: 'un'
    }
  ]);

  const [storeOptions, setStoreOptions] = useState(props?.storeOptions || []);
  const [purchaseSku, setPurchaseSku] = useState(0);
  const [purchaseProvider, setPurchaseProvider] = useState(0);
  const [suggestionsSelected, setSuggestionsSelected] = useState();
  const [unifiedSkus, setUnifiedSkus] = useState(
    props.unifiedSkus ||
      (props.editMode && props?.sku?.products?.length
        ? props.sku?.products[0]?.products?.unifiedSkus
        : false) ||
      false
  );
  const [unitsMeasurements, setUnitsMeasurements] = useState([
    {
      id: 4,
      description: 'grama',
      abbreviation: 'g',
      hasSku: true
    },
    {
      id: 1,
      description: 'litro',
      abbreviation: 'lt',
      hasSku: true
    },
    {
      id: 5,
      description: 'miligrama',
      abbreviation: 'mg',
      hasSku: false
    },
    {
      id: 6,
      description: 'mililitro',
      abbreviation: 'ml',
      hasSku: true
    },
    {
      id: 2,
      description: 'quilograma',
      abbreviation: 'kg',
      hasSku: true
    },
    {
      id: 3,
      description: 'unidade',
      abbreviation: 'un',
      hasSku: true
    }
  ]);
  const [copied, setCopied] = useState(false);
  const [openedSkus, setOpenedSkus] = useState([]);
  const [unit, setUnit] = useState();
  const [jsonBody, setJsonBody] = useState({
    sku: {
      ean: props?.selectedEan || null,
      description: null,
      specification: null,
      providers: [],
      restaurants: {
        description: userInfo.companiesActive.name,
        restaurantId: props.getId,
        resales: false,
        itemPriority: false,
        brandFavorite: false,
        brandAccepted: false,
        categoryId: null,
        user: props.user
      },
      categoryId: null,
      brandsId: null,
      quantity: null,
      unitsMeasurementsId: null,
      user: userInfo.user
    },
    product: {
      useOnCards: true,
      takeInventory: false,
      status: 'ACTIVE',
      description: null,
      restaurantId: props.getId || null,
      salePrice: null,
      discountPrice: null,
      theoreticalStock: null,
      realStock: null,
      duration: null,
      lastPrice: null,
      averagePrice: null,
      averageCost: null,
      lastCost: null,
      averageCostStock: null,
      lastCostStock: null,
      margin: null,
      markup: null,
      cmv: null,
      curve: null,
      user: props.user,
      consumptionUnitsPrimaryId: null,
      consumptionUnitsSecondaryId: null,
      categoryId: null,
      category: undefined,
      quantityMinStock: 0,
      quantityMaxStock: 0,
      originIdCard: null,
      productsGroupId: null,
      approximateCost: null,
      skus: [],
      storageLocations: [],
      unifiedSkus
    },
    tags: []
  });

  const [selectedItems, setSelectedItems] = useState({
    sku: {},
    item: {},
    product: {},
    storage: [],
    frequencies: [],
    category: {},
    unit: {},
    primaryUnit: {},
    secondaryUnit: {},
    provider: {},
    brand: {},
    packagePrice: '',
    packageQuantity: '',
    providerCode: ''
  });
  const [skuPreview, setSkuPreview] = useState({});
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [reprocess, setReprocess] = useState({
    message: '',
    status: false
  });
  const [errMsg, setErrMsg] = useState(false);
  const [messageProvider, setMessageProvider] = useState({
    message: '',
    severity: ''
  });
  const [messageError, setMessageError] = useState('');
  const [eanFilterValue, setEanFilterValue] = useState(
    props.eanFilterValue || ''
  );
  const [errUnitsMsg, setErrUnitsMsg] = useState(false);
  const [errProviderMsg, setErrProviderMsg] = useState(false);
  const [producerModal, setProducerModal] = useState(false);
  const [updateProducers, setUpdateProducers] = useState(false);
  const [periodicityModal, setPeriodicityModal] = useState(false);
  const [storageLocationModal, setStorageLocationModal] = useState(false);
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [validDescription, setValidDescription] = useState(true);
  const [validQuantity, setValidQuantity] = useState(true);
  const [validBrand, setValidBrand] = useState(true);
  const [existsPackage, setExistsPackage] = useState(null);
  const [validUnitsMeasurements, setValidUnitsMeasurements] = useState(true);
  const [validProductDescription, setValidProductDescription] = useState(true);
  const [validProductPrimaryUnit, setValidProductPrimaryUnit] = useState(true);
  const [validProductCategory, setValidProductCategory] = useState(true);
  const [validPeriodicity, setValidPeriodicity] = useState(true);
  const [validStorageLocation, setValidStorageLocation] = useState(true);
  const [restaurantsId, setRestaurantsId] = useState(
    props?.selectedStores?.length
      ? props.canAssociateCurrentSku
        ? !props.editMode
          ? [props?.selectedStores[0]]
          : props?.selectedStores?.filter(
              (item) =>
                !props?.sku?.restaurantsIds
                  ?.split(',')
                  ?.map((el) => parseInt(el, 10))
                  .includes(item._id)
            ) || userInfo.companies.groups[0].subGroups[0].stores
        : !props.editMode
        ? [props?.selectedStores[0]]
        : props?.selectedStores?.filter((item) => {
            if (props.sku?.restaurantsIds) {
              return props?.sku?.restaurantsIds
                ?.split(',')
                ?.map((el) => parseInt(el, 10))
                .includes(item._id);
            }

            return props.sku?.restaurants
              .map((rest) => rest.restaurantId)
              .includes(item._id);
          }) || userInfo.companies.groups[0].subGroups[0].stores
      : isAdminMode
      ? []
      : userInfo.companies.groups[0].subGroups[0].stores
  );

  const [validateProductArea, setValidateProductArea] = useState(false);
  const [hideInventoryFields, setHideInventoryFields] = useState(false);
  const [codeProviderError, setCodeProviderError] = useState(false);
  const [validProviderDescription, setValidProviderDescription] =
    useState(true);
  const [
    dialogRegisterPackageTypeSettings,
    setDialogRegisterPackageTypeSettings
  ] = useState({
    open: false
  });
  const [dialogBrandsSettings, setDialogBrandsSettings] = useState({
    open: false,
    updateBrands: false
  });
  const [dialogProvidersSettings, setDialogProvidersSettings] = useState({
    open: false,
    updateProviders: false
  });
  const [skuDefaultDescription, setSkuDefaultDescription] = useState({
    product: '',
    brand: '',
    tags: '',
    packageType: '',
    quantity: '',
    unit: ''
  });

  const [userGroups, setUserGroups] = useState({
    groups: [
      {
        subGroups: [
          {
            stores: [{}]
          }
        ]
      }
    ]
  });
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 2,
      name: 'imageCropper',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 3,
      name: 'providerAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 4,
      name: 'searchImage',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentSku: {}
    },
    {
      id: 5,
      name: 'eanSuggestions',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentSku: {}
    },
    {
      id: 6,
      name: 'brandSuggestions',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentSku: {}
    },
    {
      id: 7,
      name: 'generalSuggestions',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentSku: {}
    }
  ]);
  const [submitMainForm, setSubmitMainForm] = useState(false);
  const [providersToAssociateObj, setProvidersToAssociateObj] = useState({
    openModal: false,
    length: 0
  });
  const [restaurantGroups, setRestaurantGroups] = useState([]);
  const [restaurantSubGroups, setRestaurantSubGroups] = useState([]);
  const [restaurantStores, setRestaurantStores] = useState([]);
  const [disableProductUnit, setDisableProductUnit] = useState(false);
  const [hideAutocompleteTooltip, setHideAutocompleteTooltip] = useState(false);
  const [invalidEan, setInvalidEan] = useState(false);
  const [skuAllValues, setSkuAllValues] = useState(true);
  const [lossPercentageCategory, setLossPercentageCategory] = useState(null);
  const [debounceTimeList, setDebounceTimeList] = useState([]);

  const [genericMessage, setGenericMessage] = useState({
    description: '',
    status: ''
  });
  const [openCategorieDialog, setOpenCategorieDialog] = useState(false);
  const [freeCatalogParamAssociate, setFreeCatalogParamAssociate] = useState(
    props.sku?.skuBloom === 'BLOOM' ? null : props.freeCatalogParamAssociate
  );
  const [canAssociateShow, setCanAssociateShow] = useState(
    props?.selectedStores?.length
      ? props?.selectedStores.filter(
          (item) =>
            !props?.sku?.restaurantsIds
              ?.split(',')
              ?.map((el) => parseInt(el, 10))
              .includes(item._id)
        ).length
      : true
  );

  const isInvalidEan = (ean, index, skuId) => {
    Axios.get(`${environments.catalog}/skus/platform/ean/${ean}?skuId=${skuId}`)
      .then((response) => {
        const invalid = response?.data?.existsEan && !response?.data?.sameSku;

        if (index) {
          setMultipleSkuData(
            multipleSkuData.map((sku, i) => {
              if (i === index) {
                return { ...sku, eanError: invalid };
              }
              return { ...sku };
            })
          );
        } else {
          setInvalidEan(invalid);
          if (invalid)
            setGenericMessage({
              description: `Ean já cadastrado na Bloom`,
              status: 'warning',
              className: 'snackBarWarning'
            });
        }
      })
      .catch(() => {
        if (index) {
          setMultipleSkuData(
            multipleSkuData.map((sku, i) => {
              if (i === index) {
                return { ...sku, eanError: false };
              }
              return { ...sku };
            })
          );
        } else {
          setInvalidEan(false);
        }
      });
  };

  const checkConditionToHideInventoryFields = (sku) => {
    if (sku?.products?.length > 0) {
      if (sku.products[0].products) {
        if (
          sku.products[0].products?.setups &&
          sku.products[0].products?.setups.length > 0
        ) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const groupActive =
      userInfo?.companies?.groups?.length > 0
        ? userInfo?.companies?.groups?.find((group) =>
            group?.subGroups?.some((subGroup) =>
              subGroup?.stores?.some(
                (store) => store.id === userInfo?.companiesActive?.id
              )
            )
          )
        : {};
    const subGroupActive =
      userInfo?.companies?.groups?.length > 0
        ? groupActive?.subGroups?.find((subGroup) =>
            subGroup?.stores?.some(
              (store) => store.id === userInfo?.companiesActive?.id
            )
          )
        : {};

    setRestaurantGroups(userInfo?.companies?.groups);
    setRestaurantSubGroups([subGroupActive]);
    setRestaurantStores(
      props.editMode
        ? [userInfo?.companiesActive || []]
        : subGroupActive?.stores
    );
  }, [userInfo.companiesActive]);

  useEffect(() => {
    if (!storeOptions.length) {
      getOptions(
        `${environments.restaurants}/companies`,
        'restaurants',
        storeOptions,
        setStoreOptions,
        userInfo.companiesActive.id
      );
    }
  }, [environments.restaurants]);

  useEffect(() => {
    if(selectedItems.item.providerId && selectedItems.item.codeProvider) {
      Axios.get(`${environments.catalog}/skus/platform/providers?codeProvider=${selectedItems.item.codeProvider}&providerId=${selectedItems.item.providerId}&restaurantId=0`)
        .then((response) => {
          if(response?.data == null)
            setExistsPackage(null)

          if(response?.data?.id)
            setExistsPackage(response.data)
        }).catch(e => {
          setExistsPackage(null)
        })
    }
  }, [selectedItems.item.providerId, selectedItems.item.codeProvider]);

  useEffect(() => {
    if (props.selectedEan) {
      !props.editMode &&
        isAdminMode &&
        isInvalidEan(
          props.selectedEan,
          null,
          props.editMode ? props?.sku?.id : ''
        );
    } else {
      !props.editMode &&
        isAdminMode &&
        multipleSkuData.map((sku, i) => {
          isInvalidEan(sku.ean, i, props.editMode ? sku.id : '');
        });
    }
  }, [isMultipleSku]);

  useEffect(() => {
    const {
      items,
      products,
      categories,
      brands,
      skus,
      storageLocations,
      periodicities,
      consumptionUnits,
      tags,
      packageTypes
    } = props;

    setPackageTypes(packageTypes);
    setItems(items);
    setTags(tags);
    setProducts(products);
    setBrands(brands);
    setSkus(skus);
    setStorageLocations(storageLocations);
    setPeriodicities(periodicities);
    setConsumptionUnits(consumptionUnits);
    setCategories(categories);
  }, [props]);

  useEffect(() => {
    props.loadModal && setLoading(true);

    setOpenedSkus([...(props?.openedSkus || []), props?.sku?.id]);

    if (skuAllValues) {
      if (props.sku) {
        const { sku } = props;
        const storage =
          sku?.product?.setups?.length &&
          sku.product.setups[0].config?.storageLocation
            ? sku.product.setups.map((setup) =>
                storageLocations.find(
                  (storageOption) =>
                    storageOption.id === setup.config?.storageLocation.id
                )
              )
            : [];
        setJsonBody({
          ...jsonBody,
          sku: props.editMode
            ? {
                id: sku.id,
                ean: props?.selectedEan || sku.ean,
                description: sku?.descriptionLabel || sku?.description || null,
                descriptionLabel:
                  sku?.descriptionLabel || sku?.description || null,
                specification: sku.specification,
                providers: sku.providers,
                restaurants: {
                  resales: sku?.restaurants?.length
                    ? sku.restaurants[0].resales
                    : false,
                  itemPriority: sku?.restaurants?.length
                    ? sku.restaurants[0].itemPriority
                    : false,
                  brandFavorite: sku?.restaurants?.length
                    ? sku.restaurants[0].brandFavorite
                    : false,
                  brandAccepted: sku?.restaurants?.length
                    ? sku.restaurants[0].brandAccepted
                    : false,
                  categoryId: sku?.restaurants?.length
                    ? sku.restaurants[0].categoryId
                    : null,
                  user: props.user
                },
                suggestionsBrands: props?.eanInfo
                  ? props?.eanInfo
                  : sku.suggestionsBrands,
                suggestionsEan: props?.selectedEan
                  ? [props?.selectedEan]
                  : sku.suggestionsEan,
                images: sku?.skuBloom?.images || sku?.images || null,
                typePackageId: sku?.typePackages?.id,
                packageType: sku?.typePackages || {},
                categoryId: sku.categoryId,
                brandsId: sku.brandsId,
                quantity: sku.quantity,
                unitsMeasurementsId: sku.unitsMeasurementsId,
                unitsMeasurements: unitsMeasurements.find(
                  (item) => item.id === sku.unitsMeasurementsId
                ),
                user: userInfo.user
              }
            : {
                ...sku,
                ean: props?.selectedEan || sku.ean,
                images:  sku?.images,
                quantity: sku.quantity || '',
                unitsMeasurementsId: sku?.unitsMeasurements?.id,
                unitsMeasurements: unitsMeasurements.find(
                  (item) => item.id === sku?.unitsMeasurements?.id
                ),
                suggestionsBrands: props?.eanInfo
                  ? props?.eanInfo
                  : sku.suggestionsBrands,
                suggestionsEan: props?.selectedEan
                  ? [props?.selectedEan]
                  : sku.suggestionsEan
              },
          product: props.platformRegisterMode
            ? {}          
            : {
                id:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].productId 
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.id : null,
                description:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.description ||
                      sku.descriptionLabel
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.description : null,
                category:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.categories?.description
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.category : undefined,
                categoryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.categoryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.categoryId : null,
                lossPercentage:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.lossPercentage
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.lossPercentage : null,
                quantityMinStock:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.quantityMinStock
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.quantityMinStock : '',
                quantityMaxStock:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.quantityMaxStock
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.quantityMaxStock : '',
                consumptionUnitsPrimaryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.consumptionUnitsPrimaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsPrimaryId : '',
                consumptionUnitsPrimary: consumptionUnits.find((unit) =>
                  unit.id ===
                  (sku?.products?.length &&
                    sku?.products[0]?.products &&
                    sku.products[0].products?.consumptionUnitsPrimaryId)
                    ? sku.products[0].products?.consumptionUnitsPrimaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsPrimaryId : null,
                ),
                consumptionUnitsSecondaryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.consumptionUnitsSecondaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsSecondaryId : null,
                useOnCards:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.useOnCards
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.useOnCards : true,
                takeInventory: props.editMode
                  ? !!(
                      sku?.products?.length &&
                      sku.products[0]?.products &&
                      sku?.products[0]?.products?.setups?.length &&
                      sku?.products && 
                      sku?.products?.products &&
                      sku?.products?.products?.storageLocations?.length
                    )
                  : false,
                storage,
                unifiedSkus
              },
          tags: props?.sku?.tags || []
        });

        setSkuPreview(sku);
        setUnit(
          sku && unitsMeasurements
            ? unitsMeasurements.find(
                (item) => item.id === sku.unitsMeasurementsId
              )
            : {}
        );

        setSkuDefaultDescription({
          product: props.platformRegisterMode
            ? ''
            : sku?.products?.length && sku.products[0].products
            ? sku.products[0].products.description
            : '',
          brand:
            sku?.brandsId !== 29 && sku.brandsId !== 202
              ? brands?.find((item) => item.id === sku.brandsId)?.description ||
                ''
              : '',
          tags:
            sku?.tags
              ?.filter((item) => item.showInDescription)
              .map((tag) => tag?.values?.map((value) => value))
              .flat()
              .filter((item) => item || null) || '',
          quantity: sku.quantity,
          unit: props.unitsMeasurements
            ? sku
              ? props.unitsMeasurements
                  .find((item) => item.id === sku.unitsMeasurementsId)
                  ?.abbreviation?.toLocaleUpperCase() || ''
              : ''
            : sku
            ? unitsMeasurements
                .find((item) => item.id === sku.unitsMeasurementsId)
                ?.abbreviation?.toLocaleUpperCase() || ''
            : '',
          packageType: sku?.typePackages?.description || ''
        });

        const { products } = {
          ...sku,
          products: sku?.products?.length ? sku?.products : []
        };
        const [firstProduct = {}] = products;
        const { products: secondLevelProducts = {} } = firstProduct;
        const { setups = [] } = secondLevelProducts || {};

        const mapedSetups = setups?.map((setup) =>
          periodicities.find(
            (periodicityOption) =>
              periodicityOption.id === setup?.config?.periodicity?.id
          )
        );
        const filteredSetups = mapedSetups?.length
          ? mapedSetups.filter((value) => !!value)
          : [];

        const storageLocations = setups?.map((setup) =>
          props.storageLocations.find(
            (storageLocationOption) =>
              storageLocationOption.id === setup.config?.storageLocation.id
          )
        );

        setSelectedItems({
          ...selectedItems,
          unit: props.unitsMeasurements
            ? sku
              ? props.unitsMeasurements.find(
                  (item) => item.id === sku.unitsMeasurementsId
                )
              : {}
            : sku
            ? unitsMeasurements.find(
                (item) => item.id === sku.unitsMeasurementsId
              )
            : {},
          brand:
            sku && sku.brandsId
              ? brands.find((item) => item.id === sku.brandsId)
              : {},
          typePackageId: sku?.typePackages?.id,
          packageType: sku?.typePackages || {},
          product: props.platformRegisterMode
            ? {}
            : {
                ...(sku.products?.length
                  ? props?.products?.find(
                      (product) => product.id === sku.products[0].productId
                    )
                  : {}),
                id:
                  sku?.products?.length && sku.products[0].products
                    ? props.searchModal
                      ? props?.products?.find(
                          (el) =>
                            el?.description ===
                            sku?.products[0].products?.description
                        )
                        ? props?.products?.find(
                            (el) =>
                              el?.description ===
                              sku?.products[0].products?.description
                          ).id
                        : null
                      : sku.products[0].productId
                      : sku?.products && sku?.products?.products ? sku?.products?.products?.id : null,
                description:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.description
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.description : null,
                categoryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.categoryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.categoryId : null,
                quantityMinStock:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.quantityMinStock
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.quantityMinStock : '',
                quantityMaxStock:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.quantityMaxStock
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.quantityMaxStock : '',
                consumptionUnitsPrimaryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.consumptionUnitsPrimaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsPrimaryId : '',
                consumptionUnitsPrimary: consumptionUnits.find((unit) =>
                  unit.id ===
                  (sku?.products?.length &&
                    sku?.products[0]?.products &&
                    sku.products[0].products?.consumptionUnitsPrimaryId)
                    ? sku.products[0].products?.consumptionUnitsPrimaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsPrimaryId : null,
                ),
                consumptionUnitsSecondaryId:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.consumptionUnitsSecondaryId
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.consumptionUnitsSecondaryId : null,
                useOnCards:
                  sku?.products?.length && sku.products[0].products
                    ? sku.products[0].products?.useOnCards
                    : sku?.products && sku?.products?.products ? sku?.products?.products?.useOnCards : true,
                takeInventory: props.editMode
                  ? !!(
                      sku?.products?.length &&
                      sku.products[0]?.products &&
                      sku?.products[0]?.products?.setups?.length && 
                      sku?.products && 
                      sku?.products?.products &&
                      sku?.products?.products?.storageLocations?.length
                    )
                  : false,
                primaryUnit: props.platformRegisterMode
                  ? {}
                  : sku?.products?.[0]?.products
                  ? consumptionUnits.find(
                      (unit) =>
                        unit.id ===
                        sku.products[0].products?.consumptionUnitsPrimaryId || unit.id === sku?.products?.products?.consumptionUnitsPrimaryId
                    )
                  : {},
                secondaryUnit: props.platformRegisterMode
                  ? {}
                  : sku?.products?.length > 0 && sku.products[0].products
                  ? consumptionUnits.find(
                      (unit) =>
                        unit.id ===
                        sku.products[0].products?.consumptionUnitsSecondaryId || unit.id === sku?.products?.products?.consumptionUnitsSecondaryId
                    )
                  : {},
                frequencies:
                  sku?.products?.length > 0 && sku.products[0]?.products
                    ? filteredSetups
                    : [],
                storage: setups.every((item) => item?.config)
                  ? storageLocations
                  : []
              }
        });
      } else {
        setJsonBody({
          ...jsonBody,
          sku: {
            ean: props?.selectedEan || null,
            suggestionsBrands: props?.eanInfo ? props?.eanInfo : [],
            suggestionsEan: props?.selectedEan ? [props?.selectedEan] : []
          }
        });
      }
      setHideInventoryFields(
        props.editMode ? checkConditionToHideInventoryFields(props.sku) : true
      );
      setUserGroups({ groups: userInfo.companies.groups });
      setOpen(false);
    }

    props.loadModal && setLoading(false);
  }, [props.sku, skuAllValues, props?.eanInfo]);

  useEffect(() => {
    if (skuAllValues) {
      setIsMultipleSku(false);
      if (!props.menuModal && !props.searchModal) {
        setMultipleSkuData(
          props.data.map((sku) => ({
            ean: sku.ean ? sku.ean : null,
            status: 'ANALYZE',
            description: sku.descriptionSku ? sku.descriptionSku : '',
            specification: '',
            quantity: sku.quantity ? sku.quantity : null,
            unitsMeasurementsId: sku.unitsMeasurements
              ? sku.unitsMeasurements.id
              : null,
            unitsMeasurements:
              sku.unitsMeasurements || props.sku.unitsMeasurements
                ? sku.unitsMeasurements || props.sku.unitsMeasurements
                : null,
            brandsId: null,
            providers: [sku],
            suggestionsBrands:
              sku?.providersSearch?.filter((item) => item.type === 'BRAND') ||
              [],
            suggestionsEan:
              sku?.providersSearch?.filter((item) => item.type === 'EAN') || [],
            providerToAssociate: {},
            itemToAssociate: {
              providerId: null,
              name: '',
              codeProvider: '',
              purchaseLast: '',
              purchaseLastUnitary: '',
              quantity: '',
              descriptionSku: ''
            },
            categoryId: null,
            restaurants: [
              {
                resales: false,
                itemPriority: false,
                brandFavorite: false,
                brandAccepted: false,
                categoryId: null,
                purchaseAverage: sku.purchaseAverage
                  ? parseFloat(sku.purchaseAverage) / parseInt(sku.quantity, 10)
                  : 0,
                purchaseLast: sku.purchaseLast
                  ? parseFloat(sku.purchaseLast) / parseFloat(sku.quantity)
                  : 0,
                unityCost: parseFloat(
                  sku.purchaseLastUnitary ? sku.purchaseLastUnitary : 0
                )
              }
            ],
            tags: sku.tags || [],
            images: sku.images || null,
            typePackageId: null,
            packageType: {},
            skuDefaultDescription: {
              product: selectedItems?.product?.description || '',
              brand: '',
              packageType: '',
              quantity: sku.quantity || '',
              unit: sku?.unitsMeasurements?.abbreviation?.toUpperCase() || ''
            }
          }))
        );
      }

      if (props.searchModal) {
        setMultipleSkuData(
          multipleSkuData.map((sku) => ({
            ...sku,
            packageType: sku?.typePackages || {},
            skuDefaultDescription: {
              product: selectedItems?.product?.description || '',
              brand:
                sku?.brandsId !== 29
                  ? brands.find((item) => item.id === sku.brandsId)
                      ?.description || ''
                  : '',
              packageType: sku?.typePackages?.description || '',
              quantity: sku.quantity || '',
              unit: sku?.unitsMeasurements?.abbreviation?.toUpperCase() || ''
            }
          }))
        );
      }

      setData(props.menuModal ? [props.data] : props.data);
    }
  }, [props.data, skuAllValues]);

  useEffect(() => {
    if (
      data !== undefined &&
      data?.length &&
      !data.includes(undefined) &&
      skuAllValues
    ) {
      const dates = data.map((item) =>
        item && item.recivedDate ? new Date(item.recivedDate) : null
      );

      const lastPurchase =
        dates?.length && dates.some((value) => value)
          ? data.reduce((latest, current) =>
              new Date(current?.recivedDate) > new Date(latest?.recivedDate)
                ? current
                : latest
            )
          : data[0];

      if (lastPurchase?.unity) {
        setPurchaseSku(
          props.sku?.quantity
            ? lastPurchase.unity /
                (jsonBody?.sku?.quantity
                  ? jsonBody.sku.quantity
                  : props.sku.quantity)
            : 0
        );
        setPurchaseProvider(lastPurchase?.unity);
      } else {
        if (props.editMode) {
          setPurchaseSku(
            props.sku?.quantity
              ? lastPurchase?.purchaseLast /
                  lastPurchase?.quantity /
                  (jsonBody?.sku?.quantity
                    ? jsonBody.sku.quantity
                    : props.sku.quantity)
              : 0
          );
        } else {
          setPurchaseSku(
            props.sku?.quantity
              ? lastPurchase?.purchaseLast /
                  (jsonBody?.sku?.quantity
                    ? jsonBody.sku.quantity
                    : props.sku.quantity)
              : 0
          );
        }

        setPurchaseProvider(
          lastPurchase?.purchaseLast
            ? lastPurchase?.purchaseLast / lastPurchase?.quantity
            : lastPurchase?.purchaseLastUnitary / lastPurchase?.quantity
        );
      }
    }
  }, [props, data, jsonBody?.sku?.quantity, skuAllValues]);

  useEffect(() => {
    if (providersToAssociateObj.openModal) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'providerAssociation'
            ? { ...modal, open: true }
            : modal
        )
      );
      setProvidersToAssociateObj({
        ...providersToAssociateObj,
        openModal: false
      });
    }
  }, [providersToAssociateObj]);

  const getCropData = (index) => {
    if ((index || index === 0) && cropper !== 'undefined') {
      setMultipleSkuData(
        multipleSkuData.map((sku, i) => {
          if (index === i) {
            return {
              ...sku,
              images: {
                ...sku.images,
                // id: props.editMode ? sku.images?.id || '' : '',
                id: null,
                url: cropper.getCroppedCanvas().toDataURL()
              }
            };
          }
          return sku;
        })
      );
    } else if (cropper !== 'undefined') {
      setJsonBody({
        ...jsonBody,
        sku: {
          ...jsonBody.sku,
          images: {
            ...jsonBody.sku?.images,
            // id: props.editMode ? sku.images?.id || '' : '',
            id: null,
            url: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
          }
        }
      });
    }
  };

  const handleClearFileInput = (index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).value = null;
    } else {
      document.querySelector(`#fileInput`).value = null;
    }
  };

  const onChangeImage = (e, index) => {
    if (!e?.target?.files?.length) return;

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (index || index === 0) {
        setMultipleSkuData(
          multipleSkuData.map((sku, i) => {
            if (index === i) {
              return {
                ...sku,
                images: {
                  ...sku.images,
                  baseImage: reader.result,
                  name: files?.length && files[0]?.name
                }
              };
            }
            return sku;
          })
        );

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'imageCropper'
              ? { ...modal, open: true, currentSkuIndex: index }
              : modal
          )
        );
      } else {
        setJsonBody({
          ...jsonBody,
          sku: {
            ...jsonBody.sku,
            images: {
              ...jsonBody.sku?.images,
              baseImage: reader.result,
              name: files?.length && files[0]?.name
            }
          }
        });

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'imageCropper' ? { ...modal, open: true } : modal
          )
        );
      }
    };
    reader.readAsDataURL(files?.length && files[0]);
  };

  const handleSelectImage = (_e, index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).click();
    } else {
      document.querySelector(`#fileInput`).click();
    }
  };

  const handleFindImage = (_e, index, sku) => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'searchImage'
          ? {
              ...modal,
              open: true,
              currentSkuIndex: index || index === 0 ? index : '',
              currentSku: sku
            }
          : modal
      )
    );
  };

  const focusErrorField = (field, type) => {
    const autoComplete = document.querySelector(`#${field} input`);
    const treeSelector = document.querySelector(`#${field} input`);
    const normalInput = document.querySelector(`#${field} input`);

    const scroll = document.querySelector(`#${field} input`);

    if (type === 'autocomplete' && autoComplete && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      autoComplete.focus();
    }
    if (type === 'treeSelector' && treeSelector && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      treeSelector.focus();
    }
    if (type === 'input' && normalInput && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      normalInput.focus();
    }
  };

  const associateItem = () => {
    if (!selectedItems?.item?.name) return;
    if (!selectedItems?.item?.descriptionSku) {
      focusErrorField('skuDialogDescriptionProvider', 'autocomplete');
      setValidProviderDescription(false);
      return;
    }
    if (
      !data.some(
        (value) => value.providerId === selectedItems.item.providerId && value.codeProvider === selectedItems.item.codeProvider
      )
    ) {
      if (
        selectedItems.item.id &&
        data.some((value) => value.id === selectedItems.item.id)
      )
        delete selectedItems.item.id;

      if (!props.editMode) {
        const item = {
          ...selectedItems.item,
          purchaseLastUnitary:
            selectedItems.item.purchaseLast / selectedItems.item.quantity
        };
        setData([...data, item]);
      } else {
        setData([...data, selectedItems.item]);
      }

      setSelectedItems({ ...selectedItems, item: {}, provider: {} });
      setCodeProviderError(false);
      setValidProviderDescription(true);
    } else {
      setCodeProviderError(true);
    }
  };

  const handleSelectedItems = (sku) => {
    const product =
      sku.products[0] && sku?.products?.length
        ? products.find((product) => product.id === sku.products[0].productId)
        : jsonBody.product.description
        ? jsonBody.product
        : {
            useOnCards: true,
            takeInventory: false,
            status: 'ACTIVE',
            description: sku,
            restaurantId: props.getId || null,
            salePrice: null,
            discountPrice: null,
            theoreticalStock: null,
            realStock: null,
            duration: null,
            lastPrice: null,
            averagePrice: null,
            averageCost: null,
            lastCost: null,
            averageCostStock: null,
            lastCostStock: null,
            margin: null,
            markup: null,
            cmv: null,
            curve: null,
            user: props.user,
            consumptionUnitsPrimaryId: null,
            consumptionUnitsSecondaryId: null,
            categoryId: null,
            quantityMinStock: 0,
            quantityMaxStock: 0,
            originIdCard: null,
            productsGroupId: null,
            approximateCost: null,
            skus: [],
            storageLocations: []
          };

    const primaryUnit = consumptionUnits.find((unit) =>
      unit.id === (product && product.consumptionUnitsPrimaryId)
        ? product.consumptionUnitsPrimaryId
        : null
    );
    const secondaryUnit = consumptionUnits.find((unit) =>
      unit.id === (product && product.consumptionUnitsSecondaryId)
        ? product.consumptionUnitsSecondaryId
        : null
    );
    const frequencies =
      product?.setups?.length && product.setups[0].config?.periodicity
        ? product.setups.map((setup) =>
            periodicities.find(
              (periodicity) => periodicity.id === setup.config?.periodicity.id
            )
          )
        : [];
    const storage =
      product?.setups?.length && product.setups[0].config?.storageLocation
        ? product.setups.map((setup) =>
            storageLocations.find(
              (storageOption) =>
                storageOption.id === setup.config?.storageLocation.id
            )
          )
        : [];
    const category =
      product && product.categoryId && product.categoryId
        ? categories.find((category) => category.id === product.categoryId)
        : {};
    const unitValue = sku.unitsMeasurementsId
      ? unitsMeasurements.find((unit) => unit.id === sku.unitsMeasurementsId)
      : unit || {};
    setUnit(unitValue || '');
    const brand = sku.brandsId
      ? brands.find((brand) => brand.id === sku.brandsId)
      : {};

    setSelectedItems({
      ...selectedItems,
      sku,
      product,
      category,
      unit: unitValue,
      brand,
      primaryUnit,
      secondaryUnit,
      frequencies,
      storage
    });
  };

  const handleClose = () => {
    setErrMsg(false);
    setMessageProvider({
      message: '',
      severity: ''
    });
    setErrUnitsMsg(false);
    setErrProviderMsg(false);
    setOpen(false);
  };

  /* Validations */
  const validateSkuDescription = (sku) => {
    if (sku) {
      return (
        !!sku?.skuDefaultDescription?.brand ||
        !!sku?.skuDefaultDescription?.packageType ||
        !!sku?.skuDefaultDescription?.quantity ||
        !!sku?.skuDefaultDescription?.unit
      );
    }
    return (
      !!skuDefaultDescription.brand ||
      !!skuDefaultDescription.packageType ||
      !!skuDefaultDescription.quantity ||
      !!skuDefaultDescription.unit
    );
  };

  const formatThousandNumbers = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const buildSkuDefaultDescription = (sku, onlyProduct = false) => {
    if (sku) {
      const { product, brand, tags, packageType, quantity, unit } =
        sku?.skuDefaultDescription;

      if (onlyProduct) {
        return product ? `${product}` : '';
      }

      const brandNormalized = brand?.toUpperCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '').replace(/-/g, " ")
      const productNormalized = product?.toUpperCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '').replace(/-/g, " ")

      const productAndBrandDescription = brandNormalized === productNormalized ? '' : brand

      return `
        ${productAndBrandDescription}
        ${
          tags ? `${tags.map((tag) => tag.description)}`.replace(/,/g, ' ') : ''
        }
        ${packageType ?? packageType}
        ${quantity ?? formatThousandNumbers(quantity)}${unit ?? unit}`
        ?.replace(/\s\s+/g, ' ')
        ?.trim();
    }

    if (onlyProduct) {
      return skuDefaultDescription.product
        ? `${skuDefaultDescription.product}`
        : '';
    }

    const brandNormalized = skuDefaultDescription?.brand?.toUpperCase()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '').replace(/-/g, " ")
    const productNormalized = skuDefaultDescription?.product?.toUpperCase()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '').replace(/-/g, " ")

    const productAndBrandDescription = brandNormalized === productNormalized ? '' : skuDefaultDescription?.brand

    return `
        ${productAndBrandDescription}
        ${
          skuDefaultDescription?.tags
            ? `${skuDefaultDescription?.tags?.map(
                (tag) => tag.description
              )}`?.replace(/,/g, ' ')
            : ''
        }
        ${skuDefaultDescription.packageType}
        ${
          skuDefaultDescription?.quantity
            ? formatThousandNumbers(skuDefaultDescription.quantity)
            : ''
        }${skuDefaultDescription.unit}`
      ?.replace(/\s\s+/g, ' ')
      ?.trim();
  };

  const buildSkuGoogleDescription = (sku) => {
    let invalidBrand = false;

    if (sku) {
      invalidBrand = sku?.brandsId === 202 || !sku?.brandsId;

      if (sku?.descriptionLabel) {
        return {
          description:
            (
              `${sku.descriptionLabel} ${
                invalidBrand ? '' : sku.skuDefaultDescription.brand
              } ${sku.skuDefaultDescription.packageType} ${
                sku.skuDefaultDescription.quantity
              }${sku.skuDefaultDescription.unit}` ||
              (sku?.providers?.length && sku?.providers[0]?.descriptionSku)
            ).replace(/\s\s+/g, ' ') || '',
          isDefaultDescription: false
        };
      }
      return {
        description: `${skuDefaultDescription?.product} ${
          invalidBrand ? '' : skuDefaultDescription?.brand
        } ${skuDefaultDescription?.packageType} ${
          skuDefaultDescription?.quantity
        }${skuDefaultDescription?.unit}`.replace(/\s\s+/g, ' '),
        isDefaultDescription: true
      };
    }
    invalidBrand = jsonBody?.sku?.brandsId === 202 || !jsonBody?.sku?.brandsId;

    if (jsonBody?.sku?.descriptionLabel) {
      return {
        description:
          (
            `${jsonBody?.sku?.descriptionLabel}  ${
              invalidBrand ? '' : skuDefaultDescription.brand
            } ${skuDefaultDescription.packageType} ${
              skuDefaultDescription.quantity
            }${skuDefaultDescription.unit}` || data?.[0]?.descriptionSku
          ).replace(/\s\s+/g, ' ') || '',
        isDefaultDescription: false
      };
    }
    return {
      description: `${skuDefaultDescription?.product} ${
        invalidBrand ? '' : skuDefaultDescription.brand
      } ${skuDefaultDescription.packageType} ${skuDefaultDescription.quantity}${
        skuDefaultDescription.unit
      }`.replace(/\s\s+/g, ' '),
      isDefaultDescription: true
    };
  };

  const validUnitMultipleSku = (productAbbreviation, skuUnitAbbreviation) => {
    switch (productAbbreviation) {
      case 'un':
        setErrUnitsMsg(false);
        return true;
      case 'lt':
        if (skuUnitAbbreviation !== 'ml' && skuUnitAbbreviation !== 'lt') {
          setErrUnitsMsg(true);
          focusErrorField('productConsumptionPrimaryUnitField', 'autocomplete');
          return false;
        }
        setErrUnitsMsg(false);
        return true;

      case 'kg':
        if (
          skuUnitAbbreviation !== 'mg' &&
          skuUnitAbbreviation !== 'g' &&
          skuUnitAbbreviation !== 'kg'
        ) {
          setErrUnitsMsg(true);
          focusErrorField('productConsumptionPrimaryUnitField', 'autocomplete');
          return false;
        }
        setErrUnitsMsg(false);
        return true;

      default:
        break;
    }
  };

  const isValid = (validSkus) => {
    if (isMultipleSku) {
      setMultipleSkuData(
        multipleSkuData.map((sku) => {
          validUnitMultipleSku(
            jsonBody?.product?.consumptionUnitsPrimary?.abbreviation,
            sku?.unitsMeasurements?.abbreviation
          );

          return {
            ...sku,
            description: sku.description,
            skuDescriptionError: !sku.description,
            quantityError: !sku.quantity,
            unitsError: !sku.unitsMeasurementsId,
            brandError: isAdminMode || isCatalogProfile ? !sku.brandsId : false
          };
        })
      );

      if (
        multipleSkuData.map((item) => item.description).some((item) => !item)
      ) {
        focusErrorField(
          `skuDescriptionField${multipleSkuData
            .map((item) => item.description)
            .indexOf(null)}`,
          'autocomplete'
        );
      } else if (
        multipleSkuData.map((item) => item.quantity).some((item) => !item)
      ) {
        focusErrorField(
          `skuQuantityField${multipleSkuData
            .map((item) => item.quantity)
            .indexOf('')}`,
          'input'
        );
      } else if (
        multipleSkuData
          .map((item) => item.unitsMeasurementsId)
          .some((item) => !item)
      ) {
        focusErrorField(
          `skuUnitMeasurementsField${multipleSkuData
            .map((item) => item.unitsMeasurementsId)
            .indexOf('')}`,
          'autocomplete'
        );
      } else if (
        multipleSkuData.map((item) => item.brandsId).some((item) => !item) &&
        (isAdminMode || isCatalogProfile)
      ) {
        focusErrorField(
          `skuBrandField${multipleSkuData
            .map((item) => item.brandsId)
            .indexOf(null)}`,
          'autocomplete'
        );
      } else if (
        multipleSkuData.map((item) => item.eanError).some((item) => item)
      ) {
        focusErrorField(
          `skuEanField${multipleSkuData
            .map((item) => item.eanError)
            .indexOf(true)}`,
          'input'
        );
      }

      setErrProviderMsg(false);

      let valid = false;

      if (isAdminMode || isCatalogProfile) {
        valid =
          multipleSkuData
            .map((item) => item.description)
            .every((item) => item) &&
          multipleSkuData.map((item) => item.quantity).every((item) => item) &&
          multipleSkuData
            .map((item) => item.unitsMeasurementsId)
            .every((item) => item) &&
          multipleSkuData.map((item) => item.brandsId).every((item) => item);
        multipleSkuData.map((item) => item.ean).every((item) => !item);
      } else {
        valid =
          multipleSkuData
            .map((item) => item.description)
            .every((item) => item) &&
          multipleSkuData.map((item) => item.quantity).every((item) => item) &&
          multipleSkuData
            .map((item) => item.unitsMeasurementsId)
            .every((item) => item);
      }

      return valid;
    }
    setValidDescription(!!validSkus.sku.description);
    setValidQuantity(!!validSkus.sku.quantity);
    setValidUnitsMeasurements(!!validSkus.sku.unitsMeasurementsId);

    if (!validSkus.sku.description) {
      focusErrorField('skuDescriptionField', 'autocomplete');
    } else if (!validSkus.sku.quantity) {
      focusErrorField('skuQuantityField', 'input');
    } else if (!validSkus.sku.unitsMeasurementsId) {
      focusErrorField('skuUnitMeasurementsField', 'autocomplete');
    } else if (!validSkus.sku.brandsId && (isAdminMode || isCatalogProfile)) {
      focusErrorField('skuBrandField', 'autocomplete');
    } else if (invalidEan) {
      focusErrorField('ean', 'input');
    }

    if (isAdminMode || isCatalogProfile) {
      setValidBrand(!!validSkus.sku.brandsId);
    }

    if (!isAdminMode && !data.length) {
      // setErrProviderMsg(true);
      // setErrProviderMsg(false);
      // return false;
    } else {
      setErrProviderMsg(false);
      setValidPeriodicity(true);
      setValidStorageLocation(true);
    }

    if (isAdminMode) {
      return (
        !invalidEan &&
        !!validSkus.sku.description &&
        !!validSkus.sku.quantity &&
        !!validSkus.sku.unitsMeasurementsId &&
        !!validSkus.sku.brandsId
      );
    }

    return (
      !invalidEan &&
      !!validSkus.sku.description &&
      !!validSkus.sku.quantity &&
      !!validSkus.sku.unitsMeasurementsId
    );
  };

  const validateProduct = () => {
    const isValidPeriodicity =
      isAdminMode || isQuoter || isCatalogProfile
        ? true
        : selectedItems?.product?.frequencies?.length;
    const isValidStorageLocation =
      isAdminMode || isQuoter || isCatalogProfile
        ? true
        : selectedItems?.product?.storage?.length;
    setValidProductDescription(!!jsonBody.product.description);
    setValidProductPrimaryUnit(!!jsonBody.product.consumptionUnitsPrimaryId);
    setValidProductCategory(!!jsonBody.product.categoryId);
    setValidPeriodicity(isValidPeriodicity);
    setValidStorageLocation(isValidStorageLocation);

    if (!jsonBody.product.description) {
      focusErrorField('productDescriptionField', 'autocomplete');
    } else if (!jsonBody.product.consumptionUnitsPrimaryId) {
      focusErrorField('productConsumptionPrimaryUnitField', 'autocomplete');
    } else if (!jsonBody.product.categoryId || !jsonBody.product.category) {
      focusErrorField('productCategoryField', 'treeSelector');
    } else if (!hideInventoryFields ? !isValidPeriodicity : false) {
      focusErrorField('productPeriodicityField', 'autocomplete');
    } else if (!hideInventoryFields ? !isValidStorageLocation : false) {
      focusErrorField('productStorageLocationField', 'autocomplete');
    }

    return (
      !!jsonBody.product.description &&
      !!jsonBody.product.consumptionUnitsPrimaryId &&
      !!jsonBody.product.categoryId &&
      (!hideInventoryFields
        ? isValidPeriodicity && isValidStorageLocation
        : true)
    );
  };

  const validateUnits = (validSkus) => {
    if (!Array.isArray(validSkus)) {
      if (validSkus.product) {
        switch (validSkus?.product?.consumptionUnitsPrimary?.abbreviation) {
          case 'un':
            setErrUnitsMsg(false);
            return true;
          case 'lt':
            if (
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'ml' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'lt'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          case 'kg':
            if (
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'mg' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'g' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'kg'
            ) {
              setErrUnitsMsg(true);

              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          default:
            break;
        }
      }
    } else if (jsonBody.product) {
      for (const sku of validSkus) {
        switch (jsonBody?.product?.consumptionUnitsPrimary?.abbreviation) {
          case 'un':
            setErrUnitsMsg(false);
            return true;
          case 'lt':
            if (
              sku?.unitsMeasurements?.abbreviation !== 'ml' &&
              sku?.unitsMeasurements?.abbreviation !== 'lt'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          case 'kg':
            if (
              sku?.unitsMeasurements?.abbreviation !== 'mg' &&
              sku?.unitsMeasurements?.abbreviation !== 'g' &&
              sku?.unitsMeasurements?.abbreviation !== 'kg'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          default:
            break;
        }
      }
    }
  };

  function checkProperties(obj) {
    if (obj.description !== null) {
      for (const key in obj) {
        if (
          key === 'useOnCards' ||
          key === 'takeInventory' ||
          key === 'consumptionUnitsPrimary' ||
          key === 'consumptionUnitsPrimaryId' ||
          key === 'status'
        ) {
          continue;
        } else if (
          obj[key] !== null &&
          obj[key] !== '' &&
          obj[key] !== [] &&
          obj[key] !== undefined
        ) {
          return false;
        }
      }
    }

    return true;
  }

  /* Restaurant Groups Obj Builder */
  const pluckIds = (arr) => arr.map((obj) => obj?.id);

  const buildStores = (subGroup) => {
    const stores = subGroup.stores.filter((store) =>
      pluckIds(restaurantStores).includes(store.id)
    );

    return stores.map((store) => ({
      id: store.id,
      name: store.name
    }));
  };

  const buildSubGroups = (group) => {
    const subGroups = group.subGroups.filter((subGroup) =>
      pluckIds(restaurantSubGroups).includes(subGroup.id)
    );

    return subGroups.map((subGroup) => ({
      id: subGroup.id,
      name: subGroup.name,
      stores: buildStores(subGroup)
    }));
  };

  const removeSkuIdPlatform = (skuData) =>
    skuData.map((sku) => ({
      ...sku,
      id: null
    }));

  const getRestaurantsIds = useCallback(
    () => restaurantsId.map((restaurant) => restaurant._id),
    [restaurantsId]
  );

  const parseRestaurantsBody = useCallback(
    () =>
      restaurantsId.map((restaurant) => ({
        description: restaurant.companyName,
        restaurantId: restaurant._id,
        resales: restaurant.resales ? restaurant.resales : false,
        itemPriority: restaurant.itemPriority ? restaurant.itemPriority : false,
        brandFavorite: restaurant.brandFavorite
          ? restaurant.brandFavorite
          : false,
        brandAccepted: restaurant.brandAccepted
          ? restaurant.brandAccepted
          : false,
        purchaseAverage: restaurant.purchaseAverage
          ? restaurant.purchaseAverage
          : 0,
        purchaseLast: restaurant.purchaseLast ? restaurant.purchaseLast : 0,
        unityCost: restaurant.unityCost ? restaurant.unityCost : 0
      })),
    [restaurantsId]
  );

  const buildBody = (_canAssociateFreeCatalog = false) => {
    const uniqueStorageLocations = selectedItems?.product?.storage?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    const uniqueFrequencies = selectedItems?.product?.frequencies?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    if (isMultipleSku) {
      if (multipleSkuData.length > 0) {
        for (let i = 0; i < multipleSkuData.length; i += 1) {
          multipleSkuData[i].unitsMeasurementsId =
            multipleSkuData[i].unitsMeasurements.id;
        }
      }

      jsonBody.sku = {
        skus: multipleSkuData.map((sku) => ({
          ...sku,
          description: `${sku.descriptionLabel} ${buildSkuDefaultDescription(
            sku
          )}`
            ?.replace(/\s{2,}/g, ' ')
            ?.trim()
        })),
        product: checkProperties(jsonBody.product)
          ? null
          : {
              ...jsonBody.product,
              id: selectedItems.product.id,
              quantityMinStock: jsonBody.product.quantityMinStock
                ? jsonBody.product.quantityMinStock
                : null,
              quantityMaxStock: jsonBody.product.quantityMaxStock
                ? jsonBody.product.quantityMaxStock
                : null,
              storageLocations: uniqueStorageLocations,
              frequencies: uniqueFrequencies,
              unifiedSkus
            },
        groups: userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]
          ? restaurantGroups.map((group) => ({
              id: group.id,
              name: group.name,
              subGroups: buildSubGroups(group)
            }))
          : null,
        user: userInfo.user,
        restaurants: parseRestaurantsBody(),
        restaurantId: userInfo.companiesActive.id,
        restaurantIds: [
          ...new Set([userInfo.companiesActive.id, ...getRestaurantsIds()])
        ]
      };
    } else if (props.editMode) {
      const { id } = jsonBody.sku;
      jsonBody.sku = {
        ...jsonBody.sku,
        id: jsonBody.sku.id,
        description: `${
          jsonBody.sku.descriptionLabel
        } ${buildSkuDefaultDescription()}`
          ?.replace(/\s{2,}/g, ' ')
          ?.trim(),
        descriptionLabel: jsonBody.sku.descriptionLabel,
        ean:
          jsonBody.sku.ean && jsonBody.sku.ean.toUpperCase() === 'SEM GTIN'
            ? null
            : jsonBody.sku.ean,
        groups: userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]
          ? restaurantGroups.map((group) => ({
              id: group.id,
              name: group.name,
              subGroups: buildSubGroups(group)
            }))
          : null,
        restaurants: [
          ...parseRestaurantsBody(),
          {
            description: userInfo.companiesActive.name,
            restaurantId: userInfo.companiesActive.id,
            resales: jsonBody.sku.restaurants
              ? jsonBody.sku.restaurants.resales
              : false,
            itemPriority: jsonBody.sku.restaurants
              ? jsonBody.sku.restaurants.itemPriority
              : false,
            brandFavorite: jsonBody.sku.restaurants
              ? jsonBody.sku.restaurants.brandFavorite
              : false,
            brandAccepted: jsonBody.sku.restaurants
              ? jsonBody.sku.restaurants.brandAccepted
              : false,
            purchaseAverage: data?.length
              ? parseFloat(data[0].purchaseAverage) /
                parseInt(data[0].quantity, 10)
              : 0,
            purchaseLast: data?.length
              ? parseFloat(data[0].purchaseLast) / parseFloat(data[0].quantity)
              : 0,
            unityCost: parseFloat(
              purchaseSku
                ? purchaseSku.toString().replace(',', '.')
                : data?.length
                ? data[0].purchaseLastUnitary / data[0].quantity
                : 0
            )
          }
        ],
        providers: data?.map((item) => ({
          ...item,
          skuId: id,
          purchaseAverageUnitary:
            parseFloat(item.purchaseAverage) / parseInt(item.quantity, 10),
          purchaseLastUnitary:
            parseFloat(item.purchaseLast) / parseFloat(item.quantity),
          id: item.id ? item.id : null
        })),
        product: checkProperties(jsonBody.product)
          ? null
          : {
              ...jsonBody.product,
              id: selectedItems.product.id,
              quantityMinStock: jsonBody.product.quantityMinStock
                ? jsonBody.product.quantityMinStock
                : null,
              quantityMaxStock: jsonBody.product.quantityMaxStock
                ? jsonBody.product.quantityMaxStock
                : null,
              storageLocations: selectedItems?.product?.storage?.length
                ? selectedItems?.product?.storage
                : [],
              frequencies: selectedItems?.product?.frequencies?.length
                ? selectedItems?.product?.frequencies
                : [],
              unifiedSkus
            },
        user: userInfo.user,
        tags: jsonBody.tags,
        restaurantIds: [
          ...new Set([userInfo.companiesActive.id, ...getRestaurantsIds()])
        ]?.filter(el => el)
      };
    } else {
      const { id } = jsonBody.sku;
      jsonBody.sku = {
        skus: [
          {
            ...jsonBody.sku,
            id: jsonBody.sku.id,
            ean:
              jsonBody.sku.ean && jsonBody.sku.ean.toUpperCase() === 'SEM GTIN'
                ? null
                : jsonBody.sku.ean,
            description: `${
              jsonBody.sku.descriptionLabel || jsonBody.sku.description
            } ${buildSkuDefaultDescription()}`
              ?.replace(/\s{2,}/g, ' ')
              ?.trim(),
            descriptionLabel:
              jsonBody.sku.descriptionLabel || jsonBody.sku.description,
            providers: data.map((item) => ({
              ...item,
              skuId: id,
              purchaseAverageUnitary:
                parseFloat(item.purchaseAverage) / parseInt(item.quantity, 10),
              purchaseLastUnitary:
                parseFloat(item.purchaseLast) / parseFloat(item.quantity),
              id: item.id ? item.id : null
            })),
            restaurants: [
              ...parseRestaurantsBody(),
              {
                description: userInfo.companiesActive.name,
                restaurantId: userInfo.companiesActive.id,
                resales: jsonBody.sku.restaurants
                  ? jsonBody.sku.restaurants.resales
                  : false,
                itemPriority: jsonBody.sku.restaurants
                  ? jsonBody.sku.restaurants.itemPriority
                  : false,
                brandFavorite: jsonBody.sku.restaurants
                  ? jsonBody.sku.restaurants.brandFavorite
                  : false,
                brandAccepted: jsonBody.sku.restaurants
                  ? jsonBody.sku.restaurants.brandAccepted
                  : false,
                purchaseAverage: data.length
                  ? parseFloat(data[0].purchaseAverage) /
                    parseInt(data[0].quantity, 10)
                  : 0,
                purchaseLast: data.length
                  ? parseFloat(data[0].purchaseLast) /
                    parseFloat(data[0].quantity)
                  : 0,
                unityCost: parseFloat(
                  purchaseSku
                    ? purchaseSku.toString().replace(',', '.')
                    : data.length
                    ? data[0].purchaseLastUnitary / data[0].quantity
                    : 0
                )
              }
            ],
            tags: jsonBody.tags
          }
        ],
        product: checkProperties(jsonBody.product)
          ? null
          : {
              ...jsonBody.product,
              id: selectedItems.product.id,
              quantityMinStock: jsonBody.product.quantityMinStock
                ? jsonBody.product.quantityMinStock
                : null,
              quantityMaxStock: jsonBody.product.quantityMaxStock
                ? jsonBody.product.quantityMaxStock
                : null,
              storageLocations: uniqueStorageLocations,
              frequencies: uniqueFrequencies,
              unifiedSkus
            },
        groups: userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]
          ? restaurantGroups.map((group) => ({
              id: group.id,
              name: group.name,
              subGroups: buildSubGroups(group)
            }))
          : null,
        user: props.userInfo.user,
        restaurantId: props.userInfo.companiesActive.id,
        restaurantIds: [
          ...new Set([userInfo.companiesActive.id, ...getRestaurantsIds()])
        ].filter(el => el)
      };
    }
  };

  const updateSkuDefaultDescription = () => {
    if (isMultipleSku) {
      jsonBody.sku = {
        skus: multipleSkuData.map((sku) => ({
          ...sku,
          // description: sku.description ? sku.description : validateSkuDescription(sku) ? `${buildSkuDefaultDescription(sku, true)} ${buildSkuDefaultDescription(sku)}`.replace(/\s{2,}/g, ' ') : ""
          description: sku.description
        }))
      };
    } else {
      jsonBody.sku = {
        ...jsonBody.sku,
        // description: jsonBody.sku.description ? jsonBody.sku.description : validateSkuDescription() ? `${buildSkuDefaultDescription(null, true)} ${buildSkuDefaultDescription()}`.replace(/\s{2,}/g, ' ') : ""
        description: jsonBody.sku.description
      };
    }
  };

  const multipleSkuAssociateItem = (skuIndex, sku) => {
    if (sku && !sku.itemToAssociate?.name) return;
    if (!sku.itemToAssociate?.descriptionSku) {
      focusErrorField('skuDialogDescriptionProvider', 'autocomplete');
      setValidProviderDescription(false);
      return;
    }

    setMultipleSkuData(
      multipleSkuData.map((sku, index) => {
        if (skuIndex === index) {
          const newProvider = {
            ...sku.itemToAssociate,
            id:
              sku.itemToAssociate.id &&
              sku.providers.some((value) => value.id === sku.itemToAssociate.id)
                ? null
                : sku.itemToAssociate.id
          };
          return {
            ...sku,
            providerToAssociate: {},
            itemToAssociate: {},
            providerItemError: false,
            providers: [...sku.providers, newProvider]
          };
        }
        return { ...sku };
      })
    );
    setValidProviderDescription(true);
  };

  const associateProviderItemAndHideError = () => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'providerAssociation' ? { ...modal, open: false } : modal
      )
    );

    if (isMultipleSku) {
      multipleSkuData.map((sku, index) => {
        if (
          !!sku?.providerToAssociate?.providerId &&
          !!sku?.itemToAssociate?.codeProvider &&
          !!sku?.itemToAssociate?.providerId &&
          !!sku?.itemToAssociate?.purchaseLast &&
          !!sku?.itemToAssociate?.quantity
        ) {
          multipleSkuAssociateItem(index);
        }
      });
    } else {
      associateItem();
      setJsonBody({
        ...jsonBody,
        sku: { ...jsonBody.sku, providerItemError: false }
      });
    }
  };

  const handleProviderErrorMsg = (index) => {
    isMultipleSku
      ? setMultipleSkuData(
          multipleSkuData.map((sku, i) => {
            if (index === i) {
              return {
                ...sku,
                providerItemError: true
              };
            }
            return { ...sku };
          })
        )
      : setJsonBody({
          ...jsonBody,
          sku: { ...jsonBody.sku, providerItemError: true }
        });
  };

  const needProviderItemValidation = () =>
    isMultipleSku
      ? multipleSkuData
          .map(
            (sku) =>
              !!sku?.providerToAssociate?.providerId ||
              !!sku?.itemToAssociate?.codeProvider ||
              !!sku?.itemToAssociate?.providerId ||
              !!sku?.itemToAssociate?.purchaseLast ||
              !!sku?.itemToAssociate?.quantity
          )
          .some((item) => item)
      : Object.keys(selectedItems?.provider)?.length !== 0 ||
        !!selectedItems?.item?.codeProvider ||
        !!selectedItems?.item?.providerId ||
        !!selectedItems?.item?.purchaseLast ||
        !!selectedItems?.item?.quantity;

  const validateProviderItem = () => {
    if (
      Object.keys(selectedItems?.provider)?.length !== 0 &&
      !!selectedItems?.item?.codeProvider &&
      !!selectedItems?.item?.providerId &&
      !!selectedItems?.item?.purchaseLast &&
      !!selectedItems?.item?.quantity
    ) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'providerAssociation'
            ? { ...modal, open: true }
            : modal
        )
      );
      return false;
    }
    handleProviderErrorMsg();
    return false;
  };

  const validateMultipleProviderItem = () => {
    multipleSkuData.map((sku, index) => {
      if (
        !!sku?.providerToAssociate?.providerId &&
        !!sku?.itemToAssociate?.codeProvider &&
        !!sku?.itemToAssociate &&
        !!sku?.itemToAssociate?.purchaseLast &&
        !!sku?.itemToAssociate?.quantity
      ) {
        setProvidersToAssociateObj({
          openModal: true,
          length: providersToAssociateObj?.length
            ? providersToAssociateObj.length + 1
            : 0
        });
        return false;
      }
      if (
        !!sku?.providerToAssociate?.providerId ||
        !!sku?.itemToAssociate?.codeProvider ||
        !!sku?.itemToAssociate?.providerId ||
        !!sku?.itemToAssociate?.purchaseLast ||
        !!sku?.itemToAssociate?.quantity
      ) {
        handleProviderErrorMsg(index);
      }
      return false;
    });
  };

  const validateProductFields = () =>
    !!jsonBody?.product?.consumptionUnitsPrimaryId ||
    !!jsonBody?.product?.description ||
    !!jsonBody?.product?.category;

  const submitWithoutProduct = () => {
    if (
      !selectedItems?.product?.description &&
      !selectedItems?.product?.consumptionUnitsPrimary &&
      selectedItems.product.constructor === Object &&
      !jsonBody.product.description &&
      !isCatalogProfile
    ) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'productsAssociation'
            ? { ...modal, open: true }
            : modal
        )
      );
      return false;
    }

    return true;
  };

  /* Clear Modal Fields */
  const clearObjects = () => {
    setOpenedSkus(openedSkus?.filter((sku) => sku !== props?.sku?.id));
    setDisableProductUnit(false);
    setHideInventoryFields(true);
    setModalChanged(false);
    setJsonBody({
      sku: {
        ean: null,
        description: '',
        specification: '',
        providers: [],
        restaurants: {
          description: props.restaurantName,
          restaurantId: props.getId,
          resales: false,
          itemPriority: false,
          brandFavorite: false,
          brandAccepted: false,
          categoryId: null,
          user: props.user
        },
        categoryId: null,
        brandsId: null,
        quantity: null,
        unitsMeasurements: null,
        unitsMeasurementsId: null,
        user: props.user,
        typePackageId: null,
        packageType: {},
        images: null
      },
      product: {},
      tags: []
    });
    setSelectedItems({
      sku: {},
      item: {},
      product: {},
      primaryUnit: {},
      secondaryUnit: {},
      storage: [],
      frequencies: [],
      category: {},
      unit: {},
      provider: {},
      brand: {},
      tags: [],
      packageType: {},
      packagePrice: '',
      packageQuantity: '',
      providerCode: ''
    });
    // setItems([])
    // setSkus([])
    setPurchaseSku(0);
    setPurchaseProvider(0);
    setValidDescription(true);
    setValidQuantity(true);
    setValidUnitsMeasurements(true);
    setValidProductDescription(true);
    setValidProductPrimaryUnit(true);
    setValidProductCategory(true);
    setValidPeriodicity(true);
    setValidStorageLocation(true);
    setSkuPreview({ description: '' });
    setLoading(false);
    setErrProviderMsg(false);
    setErrUnitsMsg(false);
    setValidateProductArea(false);
    setCodeProviderError(false);
    modalChanged && props.setUpdateMainList && props.setUpdateMainList(true);
  };

  useEffect(() => {
    if (jsonBody?.sku?.unitsMeasurements?.abbreviation === 'un') {
      const updatedJsonBody = {
        ...jsonBody,
        sku: {
          ...jsonBody.sku,
          quantity: parseInt(jsonBody.sku?.quantity)
        }
      };

      // Verificar se o estado realmente mudou antes de atualizá-lo
      if (JSON.stringify(updatedJsonBody) !== JSON.stringify(jsonBody)) {
        setJsonBody(updatedJsonBody);
      }
    }
  }, [jsonBody]);

  const onSubmit = (withNoProduct = false, canAssociateFreeCatalog = false) => {
    const isDraftParam = isAdminMaster ? '' : 'draft=true'

    if (props.handleOnSave && isMultipleSku && isValid(multipleSkuData)) {
      const providerValidationType = needProviderItemValidation()
        ? validateMultipleProviderItem()
        : true;

      if (
        withNoProduct
          ? false
          : !submitWithoutProduct() || !providerValidationType
      )
        return;

      setLoading(true);
      setRequestProcessing(true);
      const { handleOnSave = () => {} } = props;

      handleOnSave({
        body: {
          ...jsonBody.sku,
          description: `${
            jsonBody.sku.description
          } ${buildSkuDefaultDescription()}`
            ?.replace(/\s{2,}/g, ' ')
            ?.trim(),
          restaurantIds:
            canAssociateFreeCatalog &&
            freeCatalogParamAssociate &&
            freeCatalogParamAssociate.length > 0
              ? freeCatalogParamAssociate
              : null
        },
        isEditMode: props.editMode,
        multipleSkuData: multipleSkuData.map((sku) => ({
          ...sku,
          description: `${
            sku.descriptionLabel || sku.description
          } ${buildSkuDefaultDescription(sku)}`
            ?.replace(/\s{2,}/g, ' ')
            ?.trim()
        })),
        product:
          !selectedItems.product?.description &&
          !selectedItems.product?.categoryId
            ? null
            : selectedItems.product
      });

      setLoading(false);
      setRequestProcessing(false);
      return;
    }

    updateSkuDefaultDescription();

    if (
      (validateProductArea
        ? validateProduct() &&
          (isMultipleSku
            ? validateUnits(multipleSkuData)
            : validateUnits(jsonBody))
        : true) &&
      (isMultipleSku ? isValid(multipleSkuData) : isValid(jsonBody))
    ) {
      const providerValidationType = needProviderItemValidation()
        ? isMultipleSku
          ? validateMultipleProviderItem()
          : validateProviderItem()
        : true;

      if (
        withNoProduct
          ? false
          : !submitWithoutProduct() || !providerValidationType
      )
        return;

      setLoading(true);
      setErrUnitsMsg(false);
      setRequestProcessing(true);
      buildBody(canAssociateFreeCatalog);

      if (props.handleOnSave) {
        const { handleOnSave = () => {} } = props;

        handleOnSave({
          body: {
            ...jsonBody.sku,
            product: {
              ...selectedItems?.product,
              id: selectedItems?.product?.id || ''
            },
            description: `${
              jsonBody.sku.description
            } ${buildSkuDefaultDescription()}`
              ?.replace(/\s{2,}/g, ' ')
              ?.trim(),
          },
          isEditMode: props.editMode
        });

        setLoading(false);
        return;
      }
      Axios({
        method: props.editMode ? 'PUT' : 'POST',
        url: `${environments.catalog}/skus?${isDraftParam}`,
        data: jsonBody.sku,
        headers: {
          User: `${userLocal?.user}`
        }
      })
        .then(() => {
          setLoading(false);
          setOpen(true);
          setPurchaseSku(0);
          setPurchaseProvider(0);
          setSkuPreview({ description: '' });
          clearObjects();
          setOpenedSkus([]);
          props.setUpdateMainList && props.setUpdateMainList(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrUnitsMsg(true);
            focusErrorField(
              'productConsumptionPrimaryUnitField',
              'autocomplete'
            );
            setRequestProcessing(false);
            setLoading(false);
          } else {
            setErrMsg(true);
            setMessageError(error?.response?.data?.message);
            setRequestProcessing(false);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (submitMainForm) {
      onSubmit(true);
      setSubmitMainForm(false);
    }
  }, [submitMainForm]);

  /* Handle Modals */
  const closeSkuModal = () => {
    setConfirmCloseModal(!confirmCloseModal);
  };

  const toggleBrandModal = (currentSku) => {
    setDialogBrandsSettings({
      ...dialogBrandsSettings,
      open: !dialogBrandsSettings.open,
      currentSku: currentSku || currentSku === 0 ? currentSku : '',
      updateBrands:
        dialogBrandsSettings.open && !dialogBrandsSettings.updateBrands
    });
  };

  const toggleProducerModal = () => {
    setProducerModal(!producerModal);

    if (producerModal) {
      setUpdateProducers(!updateProducers);
    }
  };

  const togglePeriodicityModal = () => {
    setPeriodicityModal(!periodicityModal);
  };

  const toggleStorageLocationModal = () => {
    setStorageLocationModal(!storageLocationModal);
  };

  const toggleProviderModal = (currentSku) => {
    setDialogProvidersSettings({
      ...dialogProvidersSettings,
      open: !dialogProvidersSettings.open,
      currentSku: currentSku || currentSku === 0 ? currentSku : '',
      updateProviders:
        dialogProvidersSettings.open && !dialogProvidersSettings.updateProviders
    });
  };

  const clearModal = () => {
    clearObjects();
    closeSkuModal();
    props.onClose();
  };

  const handleChangePurchaseSku = (value) => {
    setPurchaseSku(value);
  };

  const handleChangeEan = (event, skuId) => {
    setJsonBody({
      ...jsonBody,
      sku: { ...jsonBody.sku, ean: event.target.value }
    });

    if (isAdminMode) {
      isInvalidEan(event.target.value, null, props.editMode ? skuId : '');
    }
  };

  const handleChangeSpecification = (event) => {
    setJsonBody({
      ...jsonBody,
      sku: { ...jsonBody.sku, specification: event.target.value }
    });
  };

  /* Header Options Builder */
  const renderHeaderOptions = () => {
    if (userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]) {
      return (
        <>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              limitTags={1}
              className="autocompleteSmall"
              options={userGroups?.groups || []}
              disableCloseOnSelect
              size="small"
              disabled={props.editMode || props.onlyViewMode}
              getOptionLabel={(option) => option.name}
              onChange={(_e, value) => {
                if (value) {
                  setRestaurantGroups(value);
                  setRestaurantSubGroups(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                  );
                  setRestaurantStores(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              value={restaurantGroups}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Grupos"
                  placeholder="Grupos"
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              limitTags={1}
              className="autocompleteSmall"
              options={restaurantGroups
                .reduce((acc, group) => [...acc, group.subGroups], [])
                .flat()}
              value={restaurantSubGroups}
              disableCloseOnSelect
              size="small"
              disabled={props.editMode || props.onlyViewMode}
              getOptionLabel={(option) => option?.name}
              onChange={(_e, value) => {
                if (value) {
                  setRestaurantSubGroups(value);
                  setRestaurantStores(
                    value
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Subgrupos"
                  placeholder="Subgrupos"
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              limitTags={1}
              className="autocompleteSmall"
              options={restaurantSubGroups
                .reduce((acc, subGroup) => [...acc, subGroup?.stores || []], [])
                .flat()}
              value={restaurantStores}
              disableCloseOnSelect
              size="small"
              disabled={props.editMode || props.onlyViewMode}
              getOptionLabel={(option) => option.name}
              onChange={(_e, value) => {
                if (value) {
                  setRestaurantStores(value);
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Restaurantes"
                  placeholder="Restaurantes"
                />
              )}
            />
          </Grid>
        </>
      );
    }

    return (
      <Grid item xs={12}>
        <Autocomplete
          options={
            storeOptions?.length
              ? storeOptions
                  .map((restaurant) => ({
                    ...restaurant,
                    companyName: restaurant?.companyName?.toUpperCase()
                  }))
                  .sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
              : userInfo?.companies?.groups[0]?.subGroups[0]?.stores
                  .map((restaurant) => ({
                    ...restaurant,
                    name: restaurant?.name?.toUpperCase()
                  }))
                  .sort((a, b) => (a.name > b.name ? 1 : -1)) || []
          }
          getOptionLabel={(option) => option.name || option.companyName}
          getOptionDisabled={(option) => {
            const currentStoreValue = isAdminMode
              ? [...activeCompany, ...restaurantsId]
              : restaurantsId;

            return currentStoreValue?.some(
              (item) => (item?.id || item?._id) === (option?.id || option?._id)
            );
          }}
          size="small"
          limitTags={1}
          className="restaurantsCustomSelect autocompleteSmall"
          value={isAdminMode ? [...activeCompany, ...restaurantsId] : restaurantsId}
          multiple
          disabled={props.onlyViewMode}
          onChange={(_event, value) => {
            if (value) {
              const restaurants = value.filter((res) => res.id !== 0);
              setRestaurantsId(restaurants);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              type="text"
              name="restaurantsId"
              label="Restaurantes"
              variant="outlined"
              required
              InputLabelProps={{
                style: { fontFamily: 'Lato', fontSize: '12px' }
              }}
            />
          )}
          renderOption={(params) => (
            <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
              {params?.name || params?.companyName}
            </Typography>
          )}
        />
      </Grid>
    );
  };

  /* Multiple Skus */
  const skuEmptyObj = {
    ean: '',
    status: 'ANALYZE',
    description: '',
    specification: '',
    quantity: null,
    unitsMeasurementsId: null,
    unitsMeasurements: null,
    brandsId: null,
    typePackageId: null,
    providers: [],
    categoryId: null,
    restaurants: null,
    images: null,
    tags: [],
    skuDefaultDescription: {
      product: selectedItems?.product?.description || '',
      brand: '',
      packageType: '',
      quantity: '',
      unit: ''
    }
  };

  const handleRemoveClick = (index) => {
    const skusList = [...multipleSkuData];
    skusList.splice(index, 1);
    setMultipleSkuData(skusList);
    setData(skusList.reduce((acc, sku) => [...acc, sku.providers], []).flat());
  };

  const handleAddClick = () => {
    const newObject = skuEmptyObj;
    const restaurants = {
      resales: false,
      itemPriority: false,
      brandFavorite: false,
      brandAccepted: false,
      categoryId: null,
      purchaseAverage: 0,
      purchaseLast: 0,
      unityCost: 0
    };

    if (isAdminMode || isCatalogProfile) {
      newObject.restaurants = restaurants;
    } else {
      newObject.restaurants = [restaurants];
    }

    setMultipleSkuData([...multipleSkuData, skuEmptyObj]);
  };

  const handleChangeMultipleSku = (skuIndex, key, value) =>
    setMultipleSkuData(
      multipleSkuData.map((sku, index) => {
        if (skuIndex === index) {
          return { ...sku, [key]: value };
        }
        return { ...sku };
      })
    );

  const reprocessItem = (sku, skuIndex, isMultiple) => {
    setLoading(true);
    setGeneralSuggestionLoading(true);
    setReprocess({ status: true, message: 'Reprocessando' });

    const body = {
      description: sku?.description
        ? sku.description
        : validateSkuDescription(isMultiple && sku)
        ? `${buildSkuDefaultDescription(
            isMultiple && sku
          )} ${buildSkuDefaultDescription(isMultiple && sku, true)}`
        : '',
      providerIds: sku?.providers.map((provider) => provider.providerId),
      codeProviders: sku?.providers.map((provider) => provider.codeProvider),
      user: userInfo.user
    };

    Axios.put(`${environments.crawler}/skus/reprocess`, body)
      .then((response) => {
        setReprocess({ status: false, message: '', noMultipleSku: true });
        setLoading(false);

        if (props.setSkusList) {
          props.setSkusList(
            props.mainContent.map((item) => {
              if (item.id === props?.sku?.id) {
                return {
                  ...item,
                  suggestionsEan:
                    response.data.content.totalEan || props.sku.suggestionsEan,
                  suggestionsBrands:
                    response.data.content.totalBrand ||
                    props.sku.suggestionsBrands,
                  providers: sku.providers.map((item) => {
                    if (
                      response.data.content.providers.find(
                        (el) => el.providerIds[0] === item.id && el.hasEanInfo
                      )
                    ) {
                      return {
                        ...item,
                        providersSearch:
                          response.data.content.providers
                            .find(
                              (el) =>
                                el.providerIds[0] === item.id && el.hasEanInfo
                            )
                            .total.map((item) => ({
                              ...item,
                              type: 'EAN'
                            })) || item.providersSearch
                      };
                    }
                    return { ...item };
                  })
                };
              }
              return { ...item };
            })
          );
        } else {
          props.setCallSettings({
            ...props.callSettings,
            mainContent: props.mainContent.map((item) => {
              if (item.id === props?.sku?.id) {
                return {
                  ...item,
                  suggestionsEan:
                    response.data.content.totalEan || props.sku.suggestionsEan,
                  suggestionsBrands:
                    response.data.content.totalBrand ||
                    props.sku.suggestionsBrands,
                  providers: sku.providers.map((item) => {
                    if (
                      response.data.content.providers.find(
                        (el) => el.providerIds[0] === item.id && el.hasEanInfo
                      )
                    ) {
                      return {
                        ...item,
                        providersSearch:
                          response.data.content.providers
                            .find(
                              (el) =>
                                el.providerIds[0] === item.id && el.hasEanInfo
                            )
                            .total.map((item) => ({
                              ...item,
                              type: 'EAN'
                            })) || item.providersSearch
                      };
                    }
                    return { ...item };
                  })
                };
              }
              return { ...item };
            })
          });
        }

        if (isMultiple) {
          setMultipleSkuData(
            multipleSkuData.map((sku, i) => {
              if (skuIndex === i) {
                return {
                  ...sku,
                  suggestionsEan:
                    response.data.content.totalEan ||
                    jsonBody.sku.suggestionsEan,
                  suggestionsBrands:
                    response.data.content.totalBrand ||
                    jsonBody.sku.suggestionsBrands,
                  providers: sku.providers.map((item) => {
                    if (
                      response.data.content.providers.find(
                        (el) => el.providerIds[0] === item.id && el.hasEanInfo
                      )
                    ) {
                      return {
                        ...item,
                        providersSearch:
                          response.data.content.providers
                            .find(
                              (el) =>
                                el.providerIds[0] === item.id && el.hasEanInfo
                            )
                            .total.map((item) => ({
                              ...item,
                              type: 'EAN'
                            })) || item.providersSearch
                      };
                    }
                    return { ...item };
                  })
                };
              }
              return sku;
            })
          );
        }

        if (props.setComparationCurrentData) {
          props.setComparationCurrentData({
            ...props.sku,
            suggestionsEan:
              response.data.content.totalEan || props.sku.suggestionsEan,
            suggestionsBrands:
              response.data.content.totalBrand || props.sku.suggestionsBrands,
            providers: sku.providers.map((item) => {
              if (
                response.data.content.providers.find(
                  (el) => el.providerIds[0] === item.id && el.hasEanInfo
                )
              ) {
                return {
                  ...item,
                  providersSearch:
                    response.data.content.providers
                      .find(
                        (el) => el.providerIds[0] === item.id && el.hasEanInfo
                      )
                      .total.map((item) => ({
                        ...item,
                        type: 'EAN'
                      })) || item.providersSearch
                };
              }
              return { ...item };
            })
          });
        } else {
          setJsonBody({
            ...jsonBody,
            sku: {
              ...jsonBody.sku,
              suggestionsEan:
                response.data.content.totalEan || jsonBody.suggestionsEan,
              suggestionsBrands:
                response.data.content.totalBrand || jsonBody.suggestionsBrands,
              providers: jsonBody.sku.providers.map((item) => {
                if (
                  response.data.content.providers.find(
                    (el) => el.providerIds[0] === item.id && el.hasEanInfo
                  )
                ) {
                  return {
                    ...item,
                    providersSearch:
                      response.data.content.providers
                        .find(
                          (el) => el.providerIds[0] === item.id && el.hasEanInfo
                        )
                        .total.map((item) => ({
                          ...item,
                          type: 'EAN'
                        })) || item.providersSearch
                  };
                }
                return { ...item };
              })
            }
          });
        }

        setGeneralSuggestionLoading(false);
        setGenericMessage({
          description: 'Sugestões reprocessadas com sucesso',
          status: 'success'
        });
      })
      .catch((error) => {
        setLoading(false);
        setGeneralSuggestionLoading(false);
        setReprocess({ status: false, message: '' });
        setGenericMessage({
          description: error?.response?.data?.message,
          status: 'error'
        });
      });
  };

  const handlePriceValue = (
    unitMeasurement,
    purchaseSku,
    unitConsumption,
    purchaseProvider
  ) => {
    if (!unitMeasurement || !unitMeasurement.abbreviation) {
      return `${purchaseSku.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })}`;
    }

    const measurement = unitMeasurement.abbreviation.toLowerCase();

    if (measurement === 'un') {
      return `${purchaseSku.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })}/${measurement}`;
    }
    if (unitConsumption && unitConsumption.abbreviation) {
      const consumption = unitConsumption.abbreviation.toLowerCase();

      if (measurement === 'ml' || measurement === 'g') {
        const unLabel = `${purchaseProvider.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}/un`;
        const measurementLabel = `${purchaseSku.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}/${measurement}`;
        const consumptionLabel = `${(purchaseSku * 1000).toLocaleString(
          'pt-br',
          { minimumFractionDigits: 3 }
        )}/${consumption}`;

        return `${unLabel}${
          purchaseSku
            ? `/${measurementLabel}/${
                consumption === 'un' ? unLabel : consumptionLabel
              }`
            : ''
        }`;
      }
      if (measurement === 'mg') {
        const unLabel = `${purchaseProvider.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}/un`;
        const measurementLabel = `${purchaseSku.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}/${measurement}`;
        const consumptionLabel = `${(purchaseSku * 1000000).toLocaleString(
          'pt-br',
          { minimumFractionDigits: 3 }
        )}/${consumption}`;

        return `${unLabel}${
          purchaseSku
            ? `/${measurementLabel}/${
                consumption === 'un' ? unLabel : consumptionLabel
              }`
            : ''
        }`;
      }
      const unLabel = `${purchaseProvider.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })}/un`;
      const measurementLabel = `${purchaseSku.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })}/${measurement}`;

      return `${unLabel} | ${measurementLabel}`;
    }
    const unLabel = `${purchaseProvider.toLocaleString('pt-br', {
      minimumFractionDigits: 3
    })}/un`;
    const measurementLabel = `${purchaseSku.toLocaleString('pt-br', {
      minimumFractionDigits: 3
    })}/${measurement}`;

    return `${unLabel} | ${measurementLabel}`;
  };

  const getSkuDescriptionFixedValue = (sku) => {
    const validateSkuDescriptionValue = validateSkuDescription(sku);
    const buildSkuDefaultDescriptionValue = buildSkuDefaultDescription(sku);

    if (
      validateSkuDescriptionValue &&
      buildSkuDefaultDescriptionValue?.length > 40
    ) {
      return `${buildSkuDefaultDescriptionValue.substring(0, 40)}...`;
    }

    if (validateSkuDescriptionValue) {
      return buildSkuDefaultDescriptionValue;
    }

    return '';
  };

  useEffect(() => {
    if (addProvider) {
      props.setUpdateMainList(true);
    }
  }, [addProvider]);

  const dialogTitle = () => {
    const originPage = props?.originPage;

    let title = 'CATALOGAR';

    if (isAdminMode && originPage !== 'catalog') {
      title = 'SKUS CADASTRADOS';
    }

    if (originPage === 'catalog') {
      title = 'CATALOGAÇÃO';
    }

    return title;
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={() => {
        setOpenedSkus(openedSkus.filter((sku) => sku !== props?.sku?.id));

        if (modalChanged) {
          props.setUpdateMainList && props.setUpdateMainList(true);
          closeSkuModal();
        } else {
          clearObjects();
          props.onClose();
        }
      }}
      PaperComponent={props.PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="skuModalContainer"
    >
      <FullScreenLoading
        display={loading}
        description={
          props.loadModal
            ? 'Carregando'
            : reprocess.message
            ? reprocess.message
            : 'Salvando'
        }
      />

      <DialogTitle
        id="draggable-dialog-title"
        className="modalHeader bold textAlignCenter cursorMove"
      >
        <Typography
          className="dFlex alignCenter"
          style={{ fontFamily: 'Lato', fontSize: '16px' }}
        >
          <b>{dialogTitle()}</b>
        </Typography>

        <Tooltip title="Voltar" aria-label="back">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpenedSkus(openedSkus.filter((sku) => sku !== props?.sku?.id));

              if (modalChanged) {
                props.setUpdateMainList && props.setUpdateMainList(true);
                closeSkuModal();
              } else {
                clearObjects();
                props.onClose();
              }
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent className="modalContent pt20">
        <Grid container className="dFlex justifyCenter" style={{ margin: 0 }}>
          
          {isAdminMode &&
            <Grid
              container
              xs={12}
              spacing={4}
              className="modalBox cardDefault mb20 pr0 pl0 pb0 m0 pt16"
            >
              {renderHeaderOptions()}
            </Grid>
          }

          {/* Products */}
          <Grid
            className="modalBox justifyStart"
            container
            item
            xs={12}
            spacing={1}
          >
            <Grid
              className="modalTitle"
              item
              xs={12}
              id="productSwitchMultpleSkusInput"
            >
              <Typography>Produto</Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={2}
              className="modalBox justifyStart cardDefault mb20 pr0 pl0 pb0 m0 pt16"
              style={{ boxShadow: '4px 4px 4px 0px #00000080' }}
            >
              <Grid
                id="productDescriptionField"
                item
                style={{ minWidth: '310px', paddingRight: '32px !important' }}
                className={`customSpacing ${
                  !validProductDescription && 'labelError'
                }`}
              >
                <Autocomplete
                  options={
                    products?.filter(
                      (product) => product.description && product
                    ) || []
                  }
                  getOptionLabel={(option) => option.description}
                  className="autocompleteSmall"
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  size="small"
                  onInputChange={(_e, value) => {
                    if (value) {
                      debounceTimeList.forEach((el) => clearTimeout(el));

                      const newTimer = setTimeout(() => {
                        getOptions(
                          `${environments.catalog}/products`,
                          'products',
                          products,
                          setProducts,
                          props.getId,
                          { description: value },
                          null,
                          10
                        );
                      }, 1500);

                      setDebounceTimeList([...debounceTimeList, newTimer]);

                      setValidProductDescription(true);
                    }

                    if (value && value.inputValue) {
                      setJsonBody({
                        ...jsonBody,
                        sku: {
                          ...jsonBody.sku,
                          description: !isAdminMode
                            ? jsonBody?.sku?.description
                            : value.inputValue,
                          descriptionLabel: !isAdminMode
                            ? jsonBody?.sku?.descriptionLabel
                            : jsonBody.sku.descriptionLabel?.trim() ||
                              value.inputValue?.trim()
                        },
                        product: {
                          ...jsonBody.product,
                          description: value.inputValue?.trim(),
                          id: null
                        },
                        tags: []
                      });
                      setValidateProductArea(true);
                      setSkuDefaultDescription({
                        product: !isAdminMode
                          ? skuDefaultDescription?.product
                          : value.inputValue,
                        brand:
                          selectedItems?.brand?.id !== 29 &&
                          selectedItems?.brand?.id !== 202
                            ? selectedItems?.brand?.description || ''
                            : skuDefaultDescription.brand || '',
                        tags: '',
                        packageType:
                          selectedItems?.packageType?.description ||
                          skuDefaultDescription.packageType,
                        quantity:
                          jsonBody?.sku?.quantity ||
                          skuDefaultDescription.quantity,
                        unit:
                          selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                          jsonBody?.sku?.unitsMeasurements?.abbreviation
                      });

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: !isAdminMode
                              ? sku?.skuDefaultDescription?.product
                              : value.inputValue
                          },
                          tags:
                            value.template?.map((tag) => ({
                              ...tag,
                              values: []
                            })) || (value.skus.tags ? value.skus.tags : [])
                        }))
                      );

                      setValidProductDescription(true);
                    } else if (value) {
                      setJsonBody({
                        ...jsonBody,
                        sku: {
                          ...jsonBody.sku,
                          description: !isAdminMode
                            ? jsonBody?.sku?.description
                            : value
                        },
                        product: {
                          ...jsonBody.product,
                          description: value,
                          id: null
                        }
                      });

                      setSkuDefaultDescription({
                        product: !isAdminMode
                          ? skuDefaultDescription?.product
                          : value,
                        brand:
                          selectedItems?.brand?.id !== 29 &&
                          selectedItems?.brand?.id !== 202
                            ? selectedItems?.brand?.description || ''
                            : skuDefaultDescription.brand || '',
                        tags: skuDefaultDescription.tags,
                        packageType:
                          selectedItems?.packageType?.description ||
                          skuDefaultDescription.packageType,
                        quantity:
                          jsonBody?.sku?.quantity ||
                          skuDefaultDescription.quantity,
                        unit:
                          selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                          skuDefaultDescription.unit?.toLocaleUpperCase()
                      });

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          description: value,
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: !isAdminMode
                              ? sku?.skuDefaultDescription?.product
                              : value
                          },
                          tags: []
                        }))
                      );

                      setValidateProductArea(true);
                      setValidProductDescription(true);
                    } else {
                      setHideInventoryFields(true);
                      setSkuDefaultDescription({
                        ...skuDefaultDescription,
                        product: ''
                      });
                      setDisableProductUnit(false);
                      setSelectedItems({
                        ...selectedItems,
                        product: {},
                        primaryUnit: {},
                        secondaryUnit: {},
                        frequencies: [],
                        storage: []
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          useOnCards: true,
                          takeInventory: false,
                          status: 'ACTIVE',
                          description: '',
                          restaurantId: props.getId || null,
                          salePrice: null,
                          discountPrice: null,
                          theoreticalStock: null,
                          realStock: null,
                          duration: null,
                          lastPrice: null,
                          averagePrice: null,
                          averageCost: null,
                          lastCost: null,
                          averageCostStock: null,
                          lastCostStock: null,
                          margin: null,
                          markup: null,
                          cmv: null,
                          curve: null,
                          user: props.user,
                          consumptionUnitsPrimaryId: null,
                          consumptionUnitsSecondaryId: null,
                          categoryId: null,
                          category: undefined,
                          quantityMinStock: '',
                          quantityMaxStock: '',
                          originIdCard: null,
                          productsGroupId: null,
                          approximateCost: null,
                          skus: [],
                          storageLocations: [],
                          unifiedSkus
                        },
                        tags: []
                      });

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: ''
                          },
                          tags: []
                        }))
                      );

                      setValidateProductArea(validateProductFields());
                    }
                    // handleProductFilter(value);
                  }}
                  value={selectedItems?.product || {}}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setJsonBody({
                        ...jsonBody,
                        sku: {
                          ...jsonBody.sku,
                          descriptionLabel: !isAdminMode
                            ? jsonBody?.sku?.descriptionLabel?.trim()
                            : props.editMode
                            ? e.target.value?.trim() ||
                              jsonBody.sku.descriptionLabel?.trim()
                            : e.target.value?.trim()
                        }
                      });

                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...selectedItems.product,
                          description: e.target.value?.trim()
                        }
                      });
                    }
                  }}
                  onFocus={() => {
                    if (!products?.length) {
                      getOptions(
                        `${environments.catalog}/products`,
                        'products',
                        products,
                        setProducts,
                        props.getId,
                        null,
                        null,
                        10
                      );
                    }
                  }}
                  onChange={(_event, value) => {
                    if (value && value.inputValue) {
                      setModalChanged(true);
                      setSkuDefaultDescription({
                        product: !isAdminMode
                          ? skuDefaultDescription?.product?.trim()
                          : value.inputValue?.trim(),
                        brand:
                          selectedItems?.brand?.id !== 29 &&
                          selectedItems?.brand?.id !== 202
                            ? selectedItems?.brand?.description || ''
                            : skuDefaultDescription.brand || '',
                        tags: '',
                        packageType:
                          selectedItems?.packageType?.description ||
                          skuDefaultDescription.packageType,
                        quantity:
                          jsonBody?.sku?.quantity ||
                          skuDefaultDescription.quantity,
                        unit:
                          selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                          skuDefaultDescription.unit?.toLocaleUpperCase()
                      });

                      setSelectedItems({
                        ...selectedItems,
                        primaryUnit: {},
                        secondaryUnit: {},
                        product: {
                          ...selectedItems.product,
                          description: value.inputValue?.trim(),
                          skus: [],
                          id: null
                        }
                      });

                      setJsonBody({
                        ...jsonBody,
                        sku: {
                          ...jsonBody.sku
                        },
                        product: {
                          description: value.inputValue?.trim(),
                          useOnCards: true,
                          takeInventory: false,
                          unifiedSkus
                        },
                        tags: []
                      });

                      setValidateProductArea(true);
                      setDisableProductUnit(false);

                      if (
                        userInfo.profile.length &&
                        userInfo.profile[0][0].name !== 'admin'
                      ) {
                        setHideInventoryFields(true);
                      }

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: !isAdminMode
                              ? sku?.skuDefaultDescription?.product
                              : value.inputValue
                          },
                          tags: []
                        }))
                      );
                    } else if (value) {
                      setSkuDefaultDescription({
                        product: value.description,
                        brand:
                          selectedItems?.brand?.id !== 29 &&
                          selectedItems?.brand?.id !== 202
                            ? selectedItems?.brand?.description || ''
                            : skuDefaultDescription.brand || '',
                        tags: '',
                        packageType:
                          selectedItems?.packageType?.description ||
                          skuDefaultDescription.packageType,
                        quantity:
                          jsonBody?.sku?.quantity ||
                          skuDefaultDescription.quantity,
                        unit:
                          selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                          skuDefaultDescription.unit?.toLocaleUpperCase()
                      });
                      setDisableProductUnit(value?.skus?.length);
                      setModalChanged(true);
                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...value,
                          id: value.id,
                          primaryUnit: consumptionUnits.find(
                            (cUnit) =>
                              cUnit.id === value.consumptionUnitsPrimaryId
                          ),
                          consumptionUnitsPrimary: consumptionUnits.find(
                            (cUnit) =>
                              cUnit.id === value.consumptionUnitsPrimaryId
                          ),
                          secondaryUnit: consumptionUnits.find(
                            (cUnit) =>
                              cUnit.id === value.consumptionUnitsSecondaryId
                          ),
                          frequencies:
                            value?.setups?.length &&
                            value.setups[0].config?.periodicity
                              ? value.setups.map((setup) =>
                                  periodicities.find(
                                    (periodicity) =>
                                      periodicity.id ===
                                      setup.config?.periodicity.id
                                  )
                                )
                              : [],
                          storage:
                            value?.setups?.length &&
                            value.setups[0].config?.storageLocation
                              ? value.setups.map((setup) =>
                                  storageLocations.find(
                                    (storageOption) =>
                                      storageOption.id ===
                                      setup.config?.storageLocation.id
                                  )
                                )
                              : []
                        }
                      });

                      setJsonBody({
                        ...jsonBody,
                        sku: {
                          ...jsonBody.sku,
                          description: value.description?.trim(),
                          descriptionLabel: value.description?.trim()
                        },
                        product: {
                          id: value.id,
                          restaurantId: props.getId,
                          description: value.description?.trim(),
                          consumptionUnitsPrimaryId:
                            value.consumptionUnitsPrimaryId,
                          consumptionUnitsPrimary:
                            value.consumptionUnitsPrimary,
                          consumptionUnitsSecondaryId:
                            value.consumptionUnitsSecondaryId,
                          storageLocationId: value.storageLocations,
                          categoryId: value.categoryId,
                          category: value?.categories?.description || undefined,
                          lossPercentage: value.lossPercentage
                            ? value.lossPercentage
                            : value.categories?.lossPercentage,
                          lossPercentageChanged: !!value.lossPercentageChanged,
                          quantityMinStock: value.quantityMinStock,
                          quantityMaxStock: value.quantityMaxStock,
                          frequencies: value.frequencies,
                          useOnCards: value.useOnCards
                            ? value.useOnCards
                            : true,
                          takeInventory: value.takeInventory
                            ? value.takeInventory
                            : !!value?.setups?.length,
                          productsGroupId: value.productsGroupId,
                          unifiedSkus
                        },
                        tags:
                          value.template?.map((tag) => ({
                            ...tag,
                            values: []
                          })) || (value.skus.tags ? value.skus.tags : [])
                      });

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          description: value.description?.trim(),
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: value.description
                          },
                          tags:
                            value.template?.map((tag) => ({
                              ...tag,
                              values: []
                            })) || (value.skus.tags ? value.skus.tags : [])
                        }))
                      );

                      setHideInventoryFields(
                        value.takeInventory
                          ? !value.takeInventory
                          : !(value?.setups ? value?.setups?.length : true)
                      );
                      setValidateProductArea(true);
                    } else {
                      setHideInventoryFields(true);
                      setSkuDefaultDescription({
                        ...skuDefaultDescription,
                        product: ''
                      });
                      setDisableProductUnit(false);
                      setSelectedItems({
                        ...selectedItems,
                        product: {},
                        primaryUnit: {},
                        secondaryUnit: {},
                        frequencies: [],
                        storage: []
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          useOnCards: true,
                          takeInventory: false,
                          status: 'ACTIVE',
                          description: '',
                          restaurantId: props.getId || null,
                          salePrice: null,
                          discountPrice: null,
                          theoreticalStock: null,
                          realStock: null,
                          duration: null,
                          lastPrice: null,
                          averagePrice: null,
                          averageCost: null,
                          lastCost: null,
                          averageCostStock: null,
                          lastCostStock: null,
                          margin: null,
                          markup: null,
                          cmv: null,
                          curve: null,
                          user: props.user,
                          consumptionUnitsPrimaryId: null,
                          consumptionUnitsSecondaryId: null,
                          categoryId: null,
                          category: undefined,
                          quantityMinStock: '',
                          quantityMaxStock: '',
                          originIdCard: null,
                          productsGroupId: null,
                          approximateCost: null,
                          skus: [],
                          storageLocations: [],
                          unifiedSkus
                        },
                        tags: []
                      });

                      setMultipleSkuData(
                        multipleSkuData.map((sku) => ({
                          ...sku,
                          skuDefaultDescription: {
                            ...sku.skuDefaultDescription,
                            product: ''
                          },
                          tags: []
                        }))
                      );

                      setValidateProductArea(validateProductFields());
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options || '', params || '');

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        ...selectedItems.sku,
                        inputValue: params.inputValue,
                        description: `Criar "${params.inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  freeSolo
                  disabled={props.onlyViewMode}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="products"
                      label="Descrição"
                      placeholder="Digite aqui..."
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                id="productConsumptionPrimaryUnitField"
                item
                xs={1}
                className={`customSpacingSecondary ${
                  errUnitsMsg || (!validProductPrimaryUnit && 'labelError')
                }`}
              >
                <Autocomplete
                  options={consumptionUnits || []}
                  onFocus={() => {
                    if (!consumptionUnits?.length) {
                      getOptions(
                        `${environments.catalog}/consumptionUnits`,
                        'consumptionUnits',
                        consumptionUnits,
                        setConsumptionUnits,
                        props.getId,
                        null,
                        null,
                        10
                      );
                    }
                  }}
                  getOptionLabel={(option) => option.abbreviation}
                  className="autocompleteSmall"
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  value={selectedItems?.product?.consumptionUnitsPrimary || ''}
                  size="small"
                  disabled={disableProductUnit || props.onlyViewMode}
                  onChange={(_event, value) => {
                    if (value) {
                      setModalChanged(true);
                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...selectedItems.product,
                          primaryUnit: value,
                          consumptionUnitsPrimaryId: value ? value.id : null,
                          consumptionUnitsPrimary: value
                        }
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          consumptionUnitsPrimaryId: value ? value.id : null,
                          consumptionUnitsPrimary: value
                        }
                      });
                      setValidateProductArea(true);
                      setValidProductPrimaryUnit(true);
                    } else {
                      setModalChanged(true);
                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...selectedItems.product,
                          consumptionUnitsPrimaryId: null,
                          consumptionUnitsPrimary: null
                        }
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          consumptionUnitsPrimaryId: null,
                          consumptionUnitsPrimary: null
                        }
                      });
                      setValidateProductArea(validateProductFields());
                    }
                  }}
                  onBlur={() => {
                    setValidateProductArea(validateProductFields());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="unitsMeasurementsId"
                      label="Un. Primária"
                      placeholder="Un."
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.abbreviation}
                    </Typography>
                  )}
                />

                {errUnitsMsg && (
                  <p className="requiredWarning">
                    Unidades de medida e consumo não são compatíveis.
                  </p>
                )}
              </Grid>

              <Grid
                item
                xs={1}
                className="customSpacing"
                id="productConsumptionSecondarieUnitField"
              >
                <Autocomplete
                  options={consumptionUnits || []}
                  onFocus={() => {
                    if (!consumptionUnits?.length) {
                      getOptions(
                        `${environments.catalog}/consumptionUnits`,
                        'consumptionUnits',
                        consumptionUnits,
                        setConsumptionUnits,
                        props.getId,
                        null,
                        null,
                        10
                      );
                    }
                  }}
                  getOptionLabel={(option) => option.abbreviation}
                  className="autocompleteSmall"
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  disabled={
                    !(
                      selectedItems?.product?.primaryUnit &&
                      selectedItems?.product?.primaryUnit?.abbreviation === 'un'
                    )
                  }
                  size="small"
                  value={selectedItems?.product?.secondaryUnit}
                  onChange={(_event, value) => {
                    if (value) {
                      setModalChanged(true);
                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...selectedItems.product,
                          secondaryUnit: value,
                          consumptionUnitsSecondaryId: value ? value.id : null
                        }
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          consumptionUnitsSecondaryId: value ? value.id : null
                        }
                      });
                    } else {
                      setModalChanged(true);
                      setSelectedItems({
                        ...selectedItems,
                        product: {
                          ...selectedItems.product,
                          consumptionUnitsSecondaryId: null,
                          secondaryUnit: null
                        }
                      });
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          consumptionUnitsSecondaryId: null,
                          secondaryUnit: null
                        }
                      });
                      setValidateProductArea(validateProductFields());
                    }
                  }}
                  filterOptions={() => {
                    const currentUnit = selectedItems?.product?.primaryUnit
                      ? selectedItems?.product?.primaryUnit.abbreviation
                      : '';

                    if (currentUnit === 'un') {
                      return consumptionUnits.filter(
                        (cUnit) => cUnit.abbreviation !== 'un'
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="unitsMeasurementsId"
                      label="Un. Secun."
                      variant="outlined"
                      required
                      placeholder="Un."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.abbreviation}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                id="productCategoryField"
                item
                xs={2}
                className={`customSpacing pRelative ml10 ${
                  !validProductCategory && 'labelError'
                }`}
              >
                <label className="fontSizeDefault pAbsolute" style={{ top: 0 }}>
                  Categorias*
                </label>

                <Box display="flex" classes={{ root: 'itemWithModal' }}>
                  <TreeSelect
                    id="skuDialogCategorieTreeSelect"
                    disabled={props.onlyViewMode}
                    suffixIcon={
                      <svg
                        className="MuiSvgIcon-root pAbsolute"
                        style={{ right: 0, bottom: '-7px' }}
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    }
                    style={{ width: '100%' }}
                    allowClear
                    value={jsonBody?.product?.category || undefined}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={categories}
                    placeholder="Selecione..."
                    showSearch
                    onDropdownVisibleChange={() =>
                      getOptions(
                        `${environments.catalog}/categories`,
                        'categories',
                        categories,
                        setCategories,
                        props.getId
                      )
                    }
                    filterTreeNode={(search, item) =>
                      item?.title
                        ?.toLowerCase()
                        ?.normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        ?.indexOf(
                          search
                            ?.toLowerCase()
                            ?.normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                        ) >= 0
                    }
                    treeDefaultExpandAll
                    onChange={(element) => {
                      if (!element) {
                        setValidateProductArea(validateProductFields());
                        setLossPercentageCategory(null);
                        setJsonBody({
                          ...jsonBody,
                          product: {
                            ...jsonBody?.product,
                            category: undefined,
                            categoryId: null,
                            lossPercentage: null,
                            lossPercentageChanged: false
                          }
                        });
                      }
                    }}
                    onSelect={(element, value) => {
                      if (element) {
                        setModalChanged(true);
                        setLossPercentageCategory(
                          element && element.lossPercentage
                            ? element.lossPercentage
                            : null
                        );
                        setSelectedItems({
                          ...selectedItems,
                          product: {
                            ...selectedItems.product,
                            category: value?.title,
                            categoryId: element,
                            lossPercentage: value?.lossPercentage,
                            lossPercentageChanged: false
                          }
                        });
                        setJsonBody({
                          ...jsonBody,
                          product: {
                            ...jsonBody?.product,
                            category: value?.title,
                            categoryId: element,
                            lossPercentage: value?.lossPercentage,
                            lossPercentageChanged: false
                          }
                        });
                        setValidateProductArea(true);
                        setValidProductCategory(true);
                      }
                    }}
                  />

                  {/* <IconButton
                    classes={{ root: 'modalButton' }}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      if (props.onlyViewMode) return;

                      setOpenCategorieDialog(!openCategorieDialog);
                    }}
                  >
                    <img
                      style={{ width: '25px' }}
                      src={AddIcon}
                      alt="AddIcon"
                    />
                  </IconButton> */}
                </Box>
              </Grid>

              {!props.adminCatalogModal && !isQuoter && (
                <Grid item xs={1} className="ml10 customSpacing">
                  <CurrencyTextField
                    label="% de Perda"
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={2}
                    decimalPlacesShownOnFocus={2}
                    type="text"
                    name="lossPercentage"
                    className="textFieldSmall right"
                    disabled={props.onlyViewMode}
                    placeholder="Digite aqui..."
                    required={!props.adminCatalogModal}
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    onBlur={(event) => {
                      const value =
                        event.target.value !== ''
                          ? parseFloat(
                              event.target.value
                                .replace('.', '')
                                .replace(',', '.')
                            )
                          : 0;
                      const lossPercentageChanged =
                        value !== lossPercentageCategory;
                      setModalChanged(true);
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          lossPercentage: value,
                          lossPercentageChanged
                        }
                      });
                    }}
                    value={
                      jsonBody.product ? jsonBody.product.lossPercentage : 0
                    }
                  />
                </Grid>
              )}

              {!props.adminCatalogModal && !isQuoter && (
                <Grid item xs={2} className="ml10 customSpacing">
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="packageQuantity"
                    label="Estoque mínimo"
                    className="textFieldSmall right"
                    disabled={props.onlyViewMode}
                    placeholder="Digite aqui..."
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    onBlur={(event) => {
                      setModalChanged(true);
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          quantityMinStock: parseFloat(
                            event.target.value.replace(',', '.')
                          )
                        }
                      });
                    }}
                    value={
                      jsonBody.product && jsonBody.product.quantityMinStock
                        ? jsonBody.product.quantityMinStock
                        : ''
                    }
                  />
                </Grid>
              )}

              {!props.adminCatalogModal && !isQuoter && (
                <Grid item xs={2} className="pr16 ml10 customSpacing">
                  <CurrencyTextField
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="packageQuantity"
                    fullWidth
                    label="Estoque máximo"
                    disabled={props.onlyViewMode}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    value={
                      jsonBody.product && jsonBody.product.quantityMaxStock
                        ? jsonBody.product.quantityMaxStock
                        : ''
                    }
                    onBlur={(event) => {
                      setModalChanged(true);
                      setJsonBody({
                        ...jsonBody,
                        product: {
                          ...jsonBody.product,
                          quantityMaxStock: parseFloat(
                            event.target.value.replace(',', '.')
                          )
                        }
                      });
                    }}
                  />
                </Grid>
              )}

              {!props.adminCatalogModal && !isQuoter && (
                <div
                  className="dFlex w100 pl16 pt16"
                  style={{ minHeight: 55 }}
                >
                  <Grid
                    item
                    xs={2}
                    className="customSpacing pt8"
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    <Checkbox
                      name="productUseOnCards"
                      checked={
                        !!(
                          jsonBody.product &&
                          jsonBody.product.useOnCards === true
                        )
                      }
                      icon={<img src={EmptyCheckboxIcon} width="16px" />}
                      checkedIcon={
                        <img
                          src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                          width="16px"
                        />
                      }
                      disabled={props.onlyViewMode}
                      className="checkBoxSmall"
                      onChange={(_event, checked) => {
                        setModalChanged(true);
                        setJsonBody({
                          ...jsonBody,
                          product: {
                            ...jsonBody.product,
                            useOnCards: checked
                          }
                        });
                      }}
                      size="small"
                    />
                    Utilizar em fichas?
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    className="customSpacing pt8"
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    <Checkbox
                      name="unifySkus"
                      checked={unifiedSkus}
                      icon={<img src={EmptyCheckboxIcon} width="16px" />}
                      checkedIcon={
                        <img
                          src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                          width="16px"
                        />
                      }
                      disabled={props.onlyViewMode}
                      className="checkBoxSmall"
                      onChange={() => {
                        setUnifiedSkus(!unifiedSkus);
                      }}
                      size="small"
                    />
                    Unificar SKUs
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      title={
                        <p className="mb0" style={{ padding: 2 }}>
                          Unificar SKUs de Fornecedores diferentes com a mesma
                          embalagem
                        </p>
                      }
                      placement="right-end"
                    >
                      <img
                        src={InfoIcon}
                        alt="Info Icon"
                        style={{ margin: '0 0 2px 5px', width: '13px' }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    className="customSpacing pt8"
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    <Checkbox
                      name="productTakeInventory"
                      disabled={props.onlyViewMode}
                      checked={
                        jsonBody.product?.takeInventory
                          ? jsonBody.product.takeInventory
                          : jsonBody.product?.setups &&
                            jsonBody.product?.setups?.length
                      }
                      icon={<img src={EmptyCheckboxIcon} width="16px" />}
                      checkedIcon={
                        <img
                          src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                          width="16px"
                        />
                      }
                      className="checkBoxSmall"
                      onChange={(_event, checked) => {
                        setModalChanged(true);
                        setJsonBody({
                          ...jsonBody,
                          product: {
                            ...jsonBody.product,
                            storageLocations: [],
                            frequencies: [],
                            takeInventory: checked
                          }
                        });
                        setSelectedItems({
                          ...selectedItems,
                          frequencies: [],
                          storage: []
                        });
                        setHideInventoryFields(!checked);
                      }}
                      size="small"
                    />
                    Efetuar inventário?
                  </Grid>

                  <Grid
                    id="productPeriodicityField"
                    item
                    xs={4}
                    className={`customSpacing pr16 ${
                      !validPeriodicity && 'labelError'
                    }`}
                  >
                    {!hideInventoryFields && (
                      <>
                        <Box display="flex" classes={{ root: 'itemWithModal' }}>
                          <Autocomplete
                            options={
                              periodicities?.length
                                ? periodicities?.filter(
                                    (periodicity) =>
                                      !selectedItems?.product?.frequencies?.includes(
                                        periodicity
                                      )
                                  )
                                : []
                            }
                            getOptionLabel={(option) => option?.description}
                            className="autocompleteSmall"
                            onFocus={() =>
                              getOptions(
                                `${environments.catalog}/periodicities`,
                                'periodicities',
                                periodicities,
                                setPeriodicities,
                                props.getId,
                                null,
                                [
                                  ...new Set(
                                    selectedItems?.product?.frequencies
                                  )
                                ]
                              )
                            }
                            classes={{
                              inputRoot: classes.inputRoot,
                              input: classes.input,
                              option: classes.option
                            }}
                            multiple
                            size="small"
                            limitTags={1}
                            value={[
                              ...new Set(selectedItems?.product?.frequencies)
                            ]}
                            onChange={(_event, value) => {
                              if (value) {
                                setModalChanged(true);
                                setSelectedItems({
                                  ...selectedItems,
                                  product: {
                                    ...selectedItems.product,
                                    frequencies: value
                                  }
                                });
                                setJsonBody({
                                  ...jsonBody,
                                  product: {
                                    ...jsonBody.product,
                                    frequencies: value.map((x) => ({
                                      id: x.id,
                                      description: x.description,
                                      type: x.type
                                    }))
                                  }
                                });

                                setValidPeriodicity(true)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="frequency"
                                label="Contagem"
                                variant="outlined"
                                required
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            disabled={props.onlyViewMode}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px',
                                  fontFamily: 'Lato'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />

                          <IconButton
                            classes={{ root: 'modalButton' }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              if (props.onlyViewMode) return;
                              togglePeriodicityModal();
                            }}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        </Box>

                        <PeriodicityForm
                          isModal
                          redirectPath={null}
                          onClose={togglePeriodicityModal}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          jsonBody={jsonBody}
                          setJsonBody={setJsonBody}
                          createMode
                          openModal={periodicityModal}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid
                    id="productStorageLocationField"
                    item
                    xs={4}
                    className={`customSpacing pr16 ml10 ${
                      !validStorageLocation && 'labelError'
                    }`}
                  >
                    {!hideInventoryFields && (
                      <>
                        <Box display="flex" classes={{ root: 'itemWithModal' }}>
                          <Autocomplete
                            options={
                              storageLocations?.length
                                ? storageLocations?.filter(
                                    (storage) =>
                                      !selectedItems?.product?.storage?.includes(
                                        storage
                                      )
                                  )
                                : []
                            }
                            getOptionLabel={(option) => option?.description}
                            className="autocompleteSmall"
                            onFocus={() =>
                              getOptions(
                                `${environments.catalog}/storageLocations`,
                                'storageLocations',
                                storageLocations,
                                setStorageLocations,
                                props.getId,
                                null,
                                [...new Set(selectedItems?.product?.storage)]
                              )
                            }
                            classes={{
                              inputRoot: classes.inputRoot,
                              input: classes.input,
                              option: classes.option
                            }}
                            multiple
                            limitTags={1}
                            size="small"
                            value={
                              [...new Set(selectedItems?.product?.storage)] ||
                              []
                            }
                            onChange={(_event, value) => {
                              if (
                                !value.includes(selectedItems?.product?.storage)
                              ) {
                                setModalChanged(true);
                                setSelectedItems({
                                  ...selectedItems,
                                  product: {
                                    ...selectedItems.product,
                                    storage: value
                                  }
                                });
                                setJsonBody({
                                  ...jsonBody,
                                  product: {
                                    ...jsonBody.product,
                                    storageLocations: value.map((x) => ({
                                      id: x.id,
                                      description: x.description
                                    }))
                                  }
                                });
                                setValidStorageLocation(true)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="unitsMeasurementsId"
                                label="Local de Estoque"
                                variant="outlined"
                                required
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            disabled={props.onlyViewMode}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px',
                                  fontFamily: 'Lato'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />

                          <IconButton
                            classes={{ root: 'modalButton' }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              if (props.onlyViewMode) return;
                              toggleStorageLocationModal();
                            }}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        </Box>

                        <StorageLocationForm
                          isModal
                          openModal={storageLocationModal}
                          redirectPath={null}
                          onClose={toggleStorageLocationModal}
                          createMode
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          jsonBody={jsonBody}
                          setJsonBody={setJsonBody}
                        />
                      </>
                    )}
                  </Grid>
                </div>
              )}

              <Grid item xs={12} className="p0 associatedSkusBox">
                {openedSkus?.length ? (
                  <SkusTable
                    data={
                      selectedItems?.product?.skus
                        ? selectedItems?.product?.skus
                        : props?.sku?.products &&
                          props?.sku?.products[0]?.products?.skus
                    }
                    products={products}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    jsonBody={jsonBody}
                    setJsonBody={setJsonBody}
                    brands={brands}
                    storageLocations={storageLocations}
                    periodicities={periodicities}
                    unitsMeasurements={
                      props.unitsMeasurements || unitsMeasurements
                    }
                    consumptionUnits={consumptionUnits}
                    providers={providers}
                    packageTypes={packageTypes}
                    categories={categories}
                    items={items}
                    originId={props.getId}
                    onlyViewMode={props.onlyViewMode}
                    setUpdateMainList={props.setUpdateMainList}
                    userInfo={userInfo}
                    isAdminMode={isAdminMode}
                    handleOnSave={props.handleOnSave}
                    openedSkus={openedSkus}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          {isMultipleSku ? (
            multipleSkuData.length &&
            multipleSkuData.map((sku, index) => (
              <>
                {/* Sku */}
                <Grid
                  className={`modalBox mb0 ${index === 0 ? 'mt0' : 'mt20'}`}
                  container
                  item
                  xs={12}
                  spacing={1}
                >
                  <Grid
                    className="modalTitle smallTabSecondary dFlex justifyBetween"
                    item
                    xs={12}
                  >
                    <div className="dFlex">
                      <Typography>SKU</Typography>

                      <div className="cardTab">
                        <p>
                          R$ Unidade
                          <span>
                            {sku?.providers && sku?.providers?.length
                              ? (sku.providers[0]?.unity
                                  ? sku.providers[0]?.unity /
                                    (sku?.quantity ? sku?.quantity : 1)
                                  : sku.providers[0]?.purchaseLastUnitary /
                                    (sku?.quantity ? sku?.quantity : 1)
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              : null}
                            {sku?.unitsMeasurements &&
                            sku?.unitsMeasurements?.abbreviation
                              ? `/${sku.unitsMeasurements.abbreviation}`
                              : ''}
                          </span>
                        </p>
                      </div>

                      {!props.adminCatalogModal && !isQuoter && (
                        <Grid
                          item
                          className="pl16 customSpacing alignCenter dFlex"
                        >
                          <Grid item className="dFlex alignCenter mr20">
                            <Checkbox
                              disabled={props.onlyViewMode}
                              checked={
                                sku?.restaurants?.length
                                  ? sku.restaurants[0].resales
                                  : null
                              }
                              name="resales"
                              icon={
                                <img src={EmptyCheckboxIcon} width="16px" />
                              }
                              checkedIcon={
                                <img
                                  src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                  width="16px"
                                />
                              }
                              size="small"
                              onChange={(_event, checked) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        restaurants: [
                                          {
                                            ...sku.restaurants[0],
                                            resales: checked
                                          }
                                        ]
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              fontSize="small"
                            />{' '}
                            Revenda
                          </Grid>

                          <Grid item className="dFlex alignCenter mr20">
                            <Checkbox
                              name="itemPriority"
                              className="checkBoxSmall pb0"
                              disabled={props.onlyViewMode}
                              checked={
                                sku?.restaurants?.length
                                  ? sku.restaurants[0].itemPriority
                                  : null
                              }
                              icon={
                                <img src={EmptyCheckboxIcon} width="16px" />
                              }
                              checkedIcon={
                                <img
                                  src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                  width="16px"
                                />
                              }
                              onChange={(_event, checked) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        restaurants: [
                                          {
                                            ...sku.restaurants[0],
                                            itemPriority: checked
                                          }
                                        ]
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              size="small"
                            />{' '}
                            Item prioritário
                          </Grid>

                          <Grid item className="dFlex alignCenter mr20">
                            <Checkbox
                              name="brandFavorite"
                              className="checkBoxSmall pb0"
                              disabled={props.onlyViewMode}
                              checked={
                                sku?.restaurants?.length
                                  ? sku.restaurants[0].brandFavorite
                                  : null
                              }
                              icon={
                                <img src={EmptyCheckboxIcon} width="16px" />
                              }
                              checkedIcon={
                                <img
                                  src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                  width="16px"
                                />
                              }
                              onChange={(_event, checked) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        restaurants: [
                                          {
                                            ...sku.restaurants[0],
                                            brandFavorite: checked
                                          }
                                        ]
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              size="small"
                            />{' '}
                            Marca preferida
                          </Grid>

                          <Grid item className="dFlex alignCenter mr20">
                            <Checkbox
                              name="brandAccepted"
                              className="checkBoxSmall pb0"
                              disabled={props.onlyViewMode}
                              checked={
                                sku?.restaurants?.length
                                  ? sku.restaurants[0].brandAccepted
                                  : null
                              }
                              icon={
                                <img src={EmptyCheckboxIcon} width="16px" />
                              }
                              checkedIcon={
                                <img
                                  src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                  width="16px"
                                />
                              }
                              onChange={(_event, checked) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        restaurants: [
                                          {
                                            ...sku.restaurants[0],
                                            brandAccepted: checked
                                          }
                                        ]
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              size="small"
                            />{' '}
                            Marca aceita
                          </Grid>
                        </Grid>
                      )}
                    </div>

                    <div className="dFlex">
                      {!props.onlyViewMode &&
                        !props.adminCatalogModal &&
                        !props.searchModal && (
                          <IconButton
                            className="p0"
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => handleAddClick()}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        )}

                      {multipleSkuData.length > 1 && (
                        <IconButton
                          className="pt0 pb0 pl10"
                          style={{ outline: 0 }}
                          edge="start"
                          size="small"
                          color="inherit"
                          onClick={() => handleRemoveClick(index)}
                        >
                          <img
                            style={{ width: '25px' }}
                            src={DeleteIcon}
                            alt="DeleteIcon"
                          />
                        </IconButton>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={isAdminMode ? 9 : 12}
                    spacing={2}
                    className="cardDefault mb0 pr0 pl0 pb0 m0"
                    style={{
                      paddingTop: '16px',
                      boxShadow: '4px 4px 4px 0px #00000080',
                      borderRadius: '0px, 4px, 4px, 4px !important'
                    }}
                  >
                    <Grid
                      id={`skuEanField${index}`}
                      item
                      style={{ minWidth: isAdminMode ? '170px' : '185px' }}
                      className={`${
                        isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                      } pRelative pr20 ${sku?.eanError && 'labelError'}`}
                    >
                      <TextField
                        label="EAN"
                        name="ean"
                        id={`ean-number-${index}`}
                        fullWidth
                        disabled={props.onlyViewMode || props.searchModal}
                        variant="outlined"
                        inputProps={{
                          maxLength: 13
                        }}
                        value={sku?.ean ? sku?.ean : ''}
                        onInput={(e) => {
                          setModalChanged(true);
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                        }}
                        onChange={(e) => {
                          handleChangeMultipleSku(index, 'ean', e.target.value);

                          if (isAdminMode) {
                            isInvalidEan(
                              e.target.value,
                              index,
                              props.editMode ? sku.id : ''
                            );
                          }
                        }}
                        className="textFieldSmall"
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                        size="small"
                      />
                    </Grid>

                    <Grid
                      style={{ maxWidth: isAdminMode && '24%' }}
                      id={`skuDescriptionField${index}`}
                      item
                      xs={3}
                      className={`${
                        isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                      } pRelative skuDescriptionField ${
                        sku?.skuDescriptionError && 'labelError'
                      }`}
                    >
                      <Autocomplete
                        options={skus || []}
                        getOptionLabel={(option) => option.description}
                        inputValue={
                          sku?.descriptionLabel ||
                          sku?.description ||
                          (validateSkuDescription(sku)
                            ? buildSkuDefaultDescription(sku, true)
                            : '')
                        }
                        onInputChange={(_e, value) => {
                          if (value && value.inputValue) {
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    description: value.inputValue,
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      product: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          } else if (value) {
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    description: value,
                                    descriptionLabel: value,
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      product: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          } else if (value) {
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    description: '',
                                    descriptionLabel: '',
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      product: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          }
                        }}
                        classes={{
                          inputRoot: classes.inputRoot,
                          input: classes.input,
                          option: classes.option
                        }}
                        onFocus={(_event) =>
                          !skus.length &&
                          getOptions(
                            `${environments.catalog}/skus`,
                            'skus',
                            skus,
                            setSkus,
                            props.getId
                          )
                        }
                        value={
                          sku?.descriptionLabel ||
                          sku?.description ||
                          (validateSkuDescription(sku)
                            ? buildSkuDefaultDescription(sku, true)
                            : '')
                        }
                        id={`description-sku-${index}`}
                        required
                        onChange={(_e, value) => {
                          if (value && value.inputValue) {
                            setModalChanged(true);
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    description: value.inputValue,
                                    providerToAssociate: {},
                                    itemToAssociate: {
                                      providerId: null,
                                      name: '',
                                      codeProvider: '',
                                      purchaseLast: '',
                                      purchaseLastUnitary: '',
                                      quantity: '',
                                      descriptionSku: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          } else if (value) {
                            setModalChanged(true);
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...value,
                                    providerToAssociate: {},
                                    description: value,
                                    descriptionLabel: value,
                                    itemToAssociate: {
                                      providerId: null,
                                      name: '',
                                      codeProvider: '',
                                      purchaseLast: '',
                                      purchaseLastUnitary: '',
                                      quantity: '',
                                      descriptionSku: ''
                                    },
                                    tags: sku.tags || [],
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      product: value?.products?.length
                                        ? value.products[0].products
                                            ?.description
                                        : jsonBody?.product?.description
                                        ? jsonBody?.product?.description
                                        : ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          } else {
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    description: '',
                                    descriptionLabel: '',
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      product: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              ...selectedItems.sku,
                              inputValue: params.inputValue,
                              description: `Criar "${params.inputValue}"`
                            });
                          }

                          return filtered;
                        }}
                        freeSolo
                        className="autocompleteSmall"
                        onOpen={() => {
                          setHideAutocompleteTooltip(true);
                          setTimeout(() => {
                            document
                              .querySelector(
                                `#skuDescriptionField${index} input`
                              )
                              .focus();
                          }, 500);
                        }}
                        onClose={() => {
                          setHideAutocompleteTooltip(false);
                        }}
                        renderInput={(params) =>
                          hideAutocompleteTooltip ? (
                            <TextField
                              {...params}
                              label="Descrição"
                              variant="outlined"
                              name="skus"
                              size="small"
                              type="text"
                              placeholder="Selecione..."
                              InputLabelProps={{
                                shrink: true,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                )
                              }}
                            />
                          ) : (
                            <Tooltip
                              disableHoverListener={
                                (
                                  sku?.descriptionLabel ||
                                  sku?.description ||
                                  (validateSkuDescription(sku)
                                    ? buildSkuDefaultDescription(sku, true)
                                    : '')
                                )?.length < 20
                              }
                              title={
                                <p
                                  className="mb0"
                                  style={{ padding: '4px 6px' }}
                                >
                                  {sku?.descriptionLabel ||
                                    sku?.description ||
                                    (validateSkuDescription(sku)
                                      ? buildSkuDefaultDescription(sku, true)
                                      : '')}
                                </p>
                              }
                            >
                              <TextField
                                {...params}
                                label="Descrição"
                                variant="outlined"
                                name="skus"
                                size="small"
                                type="text"
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true,
                                  endAdornment: (
                                    <>{params.InputProps.endAdornment}</>
                                  )
                                }}
                              />
                            </Tooltip>
                          )
                        }
                        renderOption={(params) => (
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Lato'
                            }}
                          >
                            {params.description}
                          </Typography>
                        )}
                        disabled={props.onlyViewMode || props.searchModal}
                      />
                    </Grid>

                    <Grid
                      style={{
                        maxWidth: isAdminMode && '38%',
                        marginRight: isAdminMode ? '0' : '16px'
                      }}
                      item
                      xs={5}
                      className={`customSpacing pRelative skuDescriptionField ${
                        sku?.skuDescriptionError && 'labelError'
                      }`}
                    >
                      <Tooltip
                        disableHoverListener={
                          (validateSkuDescription(sku)
                            ? buildSkuDefaultDescription(sku)
                            : ''
                          )?.length < 40
                        }
                        title={
                          <p style={{ padding: '4px 6px' }}>
                            {validateSkuDescription(sku)
                              ? buildSkuDefaultDescription(sku)
                              : ''}
                          </p>
                        }
                      >
                        <TextField
                          name="skuDescriptionFixed"
                          fullWidth
                          variant="outlined"
                          disabled
                          size="small"
                          value={
                            (validateSkuDescription(sku)
                              ? buildSkuDefaultDescription(sku)
                              : ''
                            )?.length > 40
                              ? `${(validateSkuDescription(sku)
                                  ? buildSkuDefaultDescription(sku)
                                  : ''
                                ).substring(0, 40)}...`
                              : validateSkuDescription(sku)
                              ? buildSkuDefaultDescription(sku)
                              : ''
                          }
                          className="textFieldSmall"
                          placeholder="Digite aqui..."
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid
                      style={{
                        maxWidth: isAdminMode && '10.3%',
                        flexBasis: '10.3%',
                        marginRight: isAdminMode ? '0' : '16px'
                      }}
                      id={`skuQuantityField${index}`}
                      item
                      xs={1}
                      className={`customSpacing ${
                        sku?.quantityError && 'labelError'
                      } skuQuantityFieldClass`}
                    >
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        required
                        name="quantity"
                        value={sku?.quantity ? sku.quantity : ''}
                        fullWidth
                        label="Quantidade"
                        className="textFieldSmall right"
                        placeholder="Digite aqui..."
                        onBlur={(e) => {
                          setModalChanged(true);

                          setMultipleSkuData(
                            multipleSkuData.map((sku, i) => {
                              if (index === i) {
                                return {
                                  ...sku,
                                  quantity:
                                    parseFloat(
                                      e.target.value
                                        .replace(/\./g, '')
                                        .replace(',', '.')
                                    ) || '',
                                  skuDefaultDescription: {
                                    product: selectedItems.product?.description,
                                    tags: sku.skuDefaultDescription.tags,
                                    brand:
                                      brands.length &&
                                      brands.find(
                                        (obj) => obj.id === sku?.brandsId
                                      )?.id !== 29 &&
                                      brands.find(
                                        (obj) => obj.id === sku?.brandsId
                                      )?.id !== 202
                                        ? brands.find(
                                            (obj) => obj.id === sku?.brandsId
                                          )?.description || ''
                                        : sku?.skuDefaultDescription?.brand ||
                                          '',
                                    packageType:
                                      packageTypes.find(
                                        (obj) => obj.id === sku?.typePackageId
                                      )?.description ||
                                      sku?.skuDefaultDescription?.packageType,
                                    quantity: parseFloat(
                                      e.target.value
                                        .replace(/\./g, '')
                                        .replace(',', '.')
                                    ),
                                    unit:
                                      sku?.unitsMeasurements?.abbreviation?.toLocaleUpperCase() ||
                                      sku?.skuDefaultDescription?.unit?.toLocaleUpperCase()
                                  }
                                };
                              }
                              return sku;
                            })
                          );
                        }}
                        InputProps={{
                          style: {
                            fontFamily: 'Lato',
                            fontSize: '12px'
                          }
                        }}
                        disabled={props.onlyViewMode || props.searchModal}
                      />
                    </Grid>

                    <Grid
                      style={{
                        maxWidth: isAdminMode && '11%',
                        flexBasis: '11%'
                      }}
                      id={`skuUnitMeasurementsField${index}`}
                      item
                      xs={1}
                      className={`customSpacing skuUnitMeasurementsBox pr16 ${
                        sku?.unitsError && 'labelError'
                      }`}
                    >
                      <Autocomplete
                        options={unitsMeasurements || []}
                        getOptionLabel={(option) => option.abbreviation}
                        classes={{
                          inputRoot: classes.inputRoot,
                          input: classes.input,
                          option: classes.option
                        }}
                        onFocus={() => {
                          if (!unitsMeasurements?.length) {
                            getOptions(
                              `${environments.catalog}/unitsMeasurements`,
                              'units',
                              unitsMeasurements,
                              setUnitsMeasurements,
                              props.getId,
                              null,
                              null,
                              10
                            );
                          }
                        }}
                        size="small"
                        value={unitsMeasurements?.find(
                          (el) =>
                            el.id === sku?.unitsMeasurements?.id ||
                            el.id === selectedItems?.unit?.id
                        )}
                        onChange={(_event, value) => {
                          if (value) {
                            setModalChanged(true);
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    unitsMeasurements: value,
                                    unitsMeasurementsId: value.id,
                                    skuDefaultDescription: {
                                      product:
                                        selectedItems.product?.description,
                                      brand:
                                        brands.length &&
                                        brands.find(
                                          (obj) => obj.id === sku?.brandsId
                                        )?.id !== 29 &&
                                        brands.find(
                                          (obj) => obj.id === sku?.brandsId
                                        )?.id !== 202
                                          ? brands.find(
                                              (obj) => obj.id === sku?.brandsId
                                            )?.description || ''
                                          : sku?.skuDefaultDescription?.brand ||
                                            '',
                                      tags: sku.skuDefaultDescription.tags,
                                      packageType:
                                        packageTypes.find(
                                          (obj) => obj.id === sku?.typePackageId
                                        )?.description ||
                                        sku?.skuDefaultDescription?.packageType,
                                      quantity:
                                        sku?.quantity ||
                                        sku?.skuDefaultDescription?.quantity,
                                      unit: value.abbreviation?.toLocaleUpperCase()
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          } else {
                            setSelectedItems({
                              ...selectedItems,
                              unit: ''
                            });
                            setMultipleSkuData(
                              multipleSkuData.map((sku, i) => {
                                if (index === i) {
                                  return {
                                    ...sku,
                                    unitsMeasurements: '',
                                    unitsMeasurementsId: '',
                                    skuDefaultDescription: {
                                      ...sku.skuDefaultDescription,
                                      unit: ''
                                    }
                                  };
                                }
                                return sku;
                              })
                            );
                          }
                        }}
                        className="autocompleteSmall"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            type="text"
                            name="unitsMeasurementsId"
                            label="Unidade"
                            variant="outlined"
                            required
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Lato'
                            }}
                          >
                            {params.abbreviation}
                          </Typography>
                        )}
                        disabled={props.onlyViewMode || props.searchModal}
                      />
                    </Grid>

                    <Grid
                      item
                      style={{ minWidth: isAdminMode ? '240px' : '185px' }}
                      className={`${
                        isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                      }`}
                    >
                      <Box
                        display="flex"
                        classes={{ root: 'itemWithModalSecondary' }}
                      >
                        <Autocomplete
                          options={packageTypes || []}
                          getOptionLabel={(option) => option?.description}
                          classes={{
                            inputRoot: classes.inputRoot,
                            input: classes.input,
                            option: classes.option
                          }}
                          onOpen={() => {
                            setHideAutocompleteTooltip(true);
                          }}
                          onClose={() => {
                            setHideAutocompleteTooltip(false);
                          }}
                          value={sku?.packageType}
                          onChange={(_event, value) => {
                            if (value) {
                              setModalChanged(true);
                              setMultipleSkuData(
                                multipleSkuData.map((sku, i) => {
                                  if (index === i) {
                                    return {
                                      ...sku,
                                      typePackageId: value.id,
                                      packageType: value,
                                      skuDefaultDescription: {
                                        product:
                                          selectedItems.product?.description,
                                        brand:
                                          brands.length &&
                                          brands.find(
                                            (obj) => obj.id === sku?.brandsId
                                          )?.id !== 29 &&
                                          brands.find(
                                            (obj) => obj.id === sku?.brandsId
                                          )?.id !== 202
                                            ? brands.find(
                                                (obj) =>
                                                  obj.id === sku?.brandsId
                                              )?.description || ''
                                            : sku?.skuDefaultDescription
                                                ?.brand || '',
                                        tags: sku.skuDefaultDescription.tags,
                                        packageType: value.description,
                                        quantity:
                                          sku?.quantity ||
                                          sku?.skuDefaultDescription?.quantity,
                                        unit:
                                          sku?.unitsMeasurements?.abbreviation?.toLocaleUpperCase() ||
                                          sku?.skuDefaultDescription?.unit?.toLocaleUpperCase()
                                      }
                                    };
                                  }
                                  return sku;
                                })
                              );
                            } else {
                              setMultipleSkuData(
                                multipleSkuData.map((sku, i) => {
                                  if (index === i) {
                                    return {
                                      ...sku,
                                      typePackageId: null,
                                      packageType: {},
                                      skuDefaultDescription: {
                                        ...sku.skuDefaultDescription,
                                        packageType: ''
                                      }
                                    };
                                  }
                                  return sku;
                                })
                              );
                            }
                          }}
                          className="autocompleteSmall"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Embalagem"
                              variant="outlined"
                              name="packageType"
                              size="small"
                              type="text"
                              placeholder="Selecione..."
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          )}
                          renderOption={(params) => (
                            <Typography
                              style={{
                                fontSize: '12px',
                                fontFamily: 'Lato'
                              }}
                            >
                              {params.description}
                            </Typography>
                          )}
                          disabled={props.onlyViewMode || props.searchModal}
                        />

                        {(isAdminMode || isCatalogProfile) && (
                          <IconButton
                            disabled={props.onlyViewMode}
                            classes={{ root: 'modalButton' }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              setDialogRegisterPackageTypeSettings({
                                ...dialogRegisterPackageTypeSettings,
                                open: true,
                                currentSku: index
                              });
                            }}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>

                    <Grid
                      id={`skuBrandField${index}`}
                      item
                      xs={3}
                      className={`${
                        isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                      } ${sku?.brandError && 'labelError'} ${
                        (!isAdminMode && 'mb20') || ''
                      }`}
                    >
                      <Box
                        display="flex"
                        className={`itemWithModalSecondary ${
                          !validBrand && 'labelError'
                        } pRelative`}
                      >
                        <Autocomplete
                          options={brands || []}
                          getOptionLabel={(option) => option.description}
                          classes={{
                            inputRoot: classes.inputRoot,
                            input: classes.input,
                            option: classes.option
                          }}
                          value={
                            brands.length && sku?.brandsId
                              ? brands?.find(
                                  (obj) => obj.id === sku?.brandsId
                                ) || sku.brand
                              : {}
                          }
                          onFocus={() => {
                            if (!brands?.length) {
                              getOptions(
                                `${environments.catalog}/brands`,
                                'brands',
                                brands,
                                setBrands,
                                props.getId,
                                null,
                                null,
                                10
                              );
                            }
                          }}
                          onInputChange={(_e, value) => {
                            if (value) {
                              debounceTimeList.forEach((el) =>
                                clearTimeout(el)
                              );

                              const newTimer = setTimeout(() => {
                                getOptions(
                                  `${environments.catalog}/brands`,
                                  'brands',
                                  brands,
                                  setBrands,
                                  props.getId,
                                  { description: value },
                                  null,
                                  10
                                );
                              }, 1500);

                              setDebounceTimeList([
                                ...debounceTimeList,
                                newTimer
                              ]);
                            }
                            setValidBrand(true);
                          }}
                          onChange={(_, value) => {
                            if (value) {
                              setModalChanged(true);

                              setMultipleSkuData(
                                multipleSkuData.map((sku, i) => {
                                  if (index === i) {
                                    return {
                                      ...sku,
                                      brandsId: value.id,
                                      brand: value,
                                      skuDefaultDescription: {
                                        product:
                                          selectedItems.product?.description,
                                        brand:
                                          value.id !== 29 && value.id !== 202
                                            ? value.description
                                            : '',
                                        tags: sku.skuDefaultDescription.tags,
                                        packageType:
                                          sku.packageType?.description ||
                                          sku?.skuDefaultDescription
                                            ?.packageType,
                                        quantity:
                                          sku?.quantity ||
                                          sku?.skuDefaultDescription?.quantity,
                                        unit:
                                          sku?.unitsMeasurements?.abbreviation?.toLocaleUpperCase() ||
                                          sku?.skuDefaultDescription?.unit?.toLocaleUpperCase()
                                      }
                                    };
                                  }
                                  return sku;
                                })
                              );
                              setValidBrand(true);
                            } else {
                              setMultipleSkuData(
                                multipleSkuData.map((sku, i) => {
                                  if (index === i) {
                                    return {
                                      ...sku,
                                      brandsId: null,
                                      skuDefaultDescription: {
                                        ...sku.skuDefaultDescription,
                                        brand: ''
                                      }
                                    };
                                  }
                                  return sku;
                                })
                              );
                            }
                          }}
                          className="autocompleteSmall"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Marcas ${isAdminMode ? '*' : ''}`}
                              className="skuDialogBrandsInput"
                              variant="outlined"
                              name="brands"
                              size="small"
                              type="text"
                              placeholder="Selecione..."
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          )}
                          renderOption={(params) => (
                            <Typography
                              style={{
                                fontSize: '12px',
                                fontFamily: 'Lato'
                              }}
                            >
                              {params.description}
                            </Typography>
                          )}
                          disabled={props.onlyViewMode || props.searchModal}
                        />

                        {(isAdminMode || isCatalogProfile) && (
                          <IconButton
                            disabled={props.onlyViewMode}
                            classes={{ root: 'modalButton' }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => toggleBrandModal(index)}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={3} className="customSpacing">
                      <Box display="flex" classes={{ root: 'itemWithModal' }}>
                        <TextField
                          name="producersDescription"
                          fullWidth
                          label="Fabricante"
                          variant="outlined"
                          disabled
                          size="small"
                          className="textFieldSmall"
                          value={
                            brands.length && sku?.brandsId
                              ? brands.find((obj) => obj.id === sku?.brandsId)
                                  ?.producers?.description ||
                                sku?.brand?.producers?.description
                              : ''
                          }
                          placeholder="Selecione..."
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        {(isAdminMode || isCatalogProfile) && (
                          <IconButton
                            disabled={props.onlyViewMode}
                            classes={{ root: 'modalButton' }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={toggleProducerModal}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        )}
                      </Box>

                      <Modal
                        className="defaultModal modalRegisterContainer"
                        open={producerModal}
                        onClose={toggleProducerModal}
                        aria-labelledby="Producer Modal"
                      >
                        <ProducerForm
                          isModal
                          redirectPath={null}
                          onClose={toggleProducerModal}
                          originIds={restaurantsId}
                          createMode
                        />
                      </Modal>
                    </Grid>

                    {(isRestaurant ? props.editMode : true) && (
                      <Grid
                        item
                        xs={3}
                        className="customSpacing pr16 pb0 pt0 cropImageBox"
                      >
                        <Box
                          display="flex"
                          classes={{ root: 'itemWithModal' }}
                          style={{ alignItems: 'flex-start' }}
                        >
                          {sku?.images?.url ? (
                            <Tooltip
                              arrow
                              placement="right"
                              title={
                                <div
                                  className={`cropDataSmallBox skuImgPreview mr0 ${
                                    sku?.images?.url && 'bgWhite'
                                  } largeSize`}
                                >
                                  <img src={sku?.images?.url} alt="image" />
                                </div>
                              }
                            >
                              <div
                                className={`cropDataSmallBox ${
                                  sku?.images?.url && 'bgWhite'
                                }`}
                              >
                                <img src={sku?.images?.url} alt="image" />
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={`cropDataSmallBox ${
                                sku?.images?.url && 'bgWhite'
                              }`}
                            >
                              <img src={DefaultImage} alt="No Sku Image" />
                            </div>
                          )}

                          {(isAdminMode || isCatalogProfile) && (
                            <div className="dFlex flexColumn alignStart">
                              <Button
                                disabled={props.onlyViewMode}
                                className="defaultButton submitButton mb5"
                                design="outlined"
                                label="Pesquisar imagem"
                                onClick={(e) => handleFindImage(e, index, sku)}
                                style={{ outline: 0 }}
                              />

                              <Button
                                disabled={props.onlyViewMode}
                                className="defaultButton backButton"
                                design="outlined"
                                label="Enviar imagem"
                                onClick={(e) => handleSelectImage(e, index)}
                                style={{ outline: 0 }}
                              />

                              {sku?.images?.name && (
                                <div className="dFlex justifyBetween">
                                  <p className="w100 dFlex justifyCenter mt10 mr10 mb0">
                                    {ValidationLength(sku?.images?.name, 15)}
                                  </p>

                                  <img
                                    className="cursorPointer"
                                    src={CloseGrayIcon}
                                    alt="CloseGrayIcon"
                                    onClick={() => {
                                      if (props.onlyViewMode) return;

                                      handleClearFileInput(index);
                                      setMultipleSkuData(
                                        multipleSkuData.map((sku, i) => {
                                          if (index === i) {
                                            return {
                                              ...sku,
                                              images: {
                                                name: '',
                                                baseImage: '',
                                                id: '',
                                                url: null
                                              }
                                            };
                                          }
                                          return sku;
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              )}

                              <input
                                id={`fileInput${index}`}
                                type="file"
                                onChange={(e) => onChangeImage(e, index)}
                                style={{ display: 'none' }}
                              />
                            </div>
                          )}
                        </Box>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="pr16 customSpacing pt0"
                    >
                      <TextField
                        label="Especificações"
                        multiline
                        disabled={props.onlyViewMode || props.searchModal}
                        name="specification"
                        value={sku?.specification ? sku.specification : ''}
                        onChange={(e) => {
                          setModalChanged(true);
                          handleChangeMultipleSku(
                            index,
                            'specification',
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        className="textFieldSmall"
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>

                    {!isAdminMode && (
                      <Grid
                        className="skuTable"
                        item
                        xs={12}
                        style={{ padding: '12px 16px' }}
                      >
                        <ModalTable
                          currentSkuIndex={index}
                          data={sku?.providers ? sku.providers : []}
                          multipleSkuData={multipleSkuData}
                          setData={setData}
                          setMultipleSkuData={setMultipleSkuData}
                          editMode={props.editMode}
                          quantity={parseFloat(
                            sku?.quantity
                              ? sku.quantity.toString().replace(',', '.')
                              : null
                          )}
                          unit={sku?.unitsMeasurements}
                          providers={providers}
                          handleChangePurchaseSku={(e) =>
                            handleChangePurchaseSku(e)
                          }
                          getId={props.getId}
                          onlyViewMode={props.onlyViewMode}
                          userInfo={userInfo}
                          currentSku={sku}
                          searchModal={props.searchModal}
                          setUpdateMainList={props.setUpdateMainList}
                        />
                      </Grid>
                    )}
                  </Grid>

                  {isAdminMode && !isCatalogProfile && (
                    <Grid
                      className="modalBox mb0"
                      container
                      item
                      xs={3}
                      spacing={4}
                      className="cardDefault mb0 p0 m0 tagsManagerBox"
                    >
                      <TagsManager
                        tags={tags}
                        sku={sku}
                        skuIndex={index}
                        jsonBody={multipleSkuData}
                        setJsonBody={setMultipleSkuData}
                        getId={props.getId}
                        onlyViewMode={props.onlyViewMode}
                      />
                    </Grid>
                  )}

                  {isAdminMode && (
                    <Grid
                      className="modalBox cardDefault boxShadowCustom p0 mb0"
                      container
                      item
                      xs={12}
                      spacing={1}
                    >
                      <p
                        className="pl16 mb0"
                        style={{
                          color: '#1F2445',
                          fontSize: 12,
                          fontFamily: 'Lato',
                          fontWeight: 'bold'
                        }}
                      >
                        Adicionar novo Fornecedor/Embalagem
                      </p>

                      <form
                        id={`providerForm${index}`}
                        className="mt10 mb0 w100 mr0 ml0"
                        style={{ padding: '0' }}
                        onSubmit={handleSubmit(() =>
                          multipleSkuAssociateItem(index, sku)
                        )}
                      >
                        <Grid container spacing={2} className="w100 m0">
                          <Grid
                            item
                            xs={4}
                            className="customSpacing"
                            id="skuDialogProviderPackDescriptionInput"
                          >
                            <Box
                              display="flex"
                              classes={{ root: 'itemWithModal' }}
                            >
                              <Autocomplete
                                options={providers || []}
                                getOptionLabel={(option) => option.providerName}
                                classes={{
                                  inputRoot: classes.inputRoot,
                                  input: classes.input,
                                  option: classes.option
                                }}
                                onFocus={() => {
                                  if (!providers?.length) {
                                    getOptions(
                                      `${environments.providers}/providers`,
                                      'providers',
                                      providers,
                                      setProviders,
                                      props.getId,
                                      null,
                                      null,
                                      10
                                    );
                                  }
                                }}
                                onInputChange={(_, value) => {
                                  if (value) {
                                    debounceTimeList.forEach((el) =>
                                      clearTimeout(el)
                                    );

                                    const newTimer = setTimeout(() => {
                                      getOptions(
                                        `${environments.providers}/providers`,
                                        'providers',
                                        providers,
                                        setProviders,
                                        props.getId,
                                        { providerName: value },
                                        null,
                                        10
                                      );
                                    }, 1500);

                                    setDebounceTimeList([
                                      ...debounceTimeList,
                                      newTimer
                                    ]);
                                  }
                                }}
                                value={
                                  sku?.providerToAssociate &&
                                  sku?.providerToAssociate?.providerId
                                    ? providers.filter(
                                        (obj) =>
                                          obj.providerId ===
                                          sku.providerToAssociate.providerId
                                      )[0] || sku.providerToAssociate
                                    : {}
                                }
                                size="small"
                                onChange={(_event, value) => {
                                  if (value) {
                                    setModalChanged(true);
                                    setMultipleSkuData(
                                      multipleSkuData.map((sku, i) => {
                                        if (i === index) {
                                          return {
                                            ...sku,
                                            providerToAssociate: value,
                                            itemToAssociate: {
                                              ...sku?.itemToAssociate,
                                              providerId: value.providerId,
                                              name: value.providerName
                                            }
                                          };
                                        }
                                        return { ...sku };
                                      })
                                    );
                                  } else {
                                    setMultipleSkuData(
                                      multipleSkuData.map((sku, i) => {
                                        if (i === index) {
                                          return {
                                            ...sku,
                                            providerToAssociate: {},
                                            itemToAssociate: {}
                                          };
                                        }
                                        return { ...sku };
                                      })
                                    );
                                  }
                                }}
                                className="autocompleteSmall"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id={params.id}
                                    type="text"
                                    name="providers"
                                    label="Fornecedores"
                                    variant="outlined"
                                    placeholder="Selecione..."
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                                renderOption={(params) => (
                                  <Typography
                                    style={{
                                      fontSize: '12px',
                                      fontFamily: 'Lato'
                                    }}
                                  >
                                    {params.providerName}
                                  </Typography>
                                )}
                                disabled={
                                  props.onlyViewMode || props.searchModal
                                }
                              />

                              <IconButton
                                disabled={props.onlyViewMode}
                                classes={{ root: 'modalButton' }}
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => toggleProviderModal(index)}
                              >
                                <img
                                  style={{ width: '25px' }}
                                  src={AddIcon}
                                  alt="AddIcon"
                                />
                              </IconButton>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            className="customSpacing"
                            id="skuDialogCodProvider"
                          >
                            <TextField
                              type="text"
                              name="providers"
                              label="Cód. Forn."
                              variant="outlined"
                              size="small"
                              className="textFieldSmall w100"
                              placeholder="Digite aqui..."
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={
                                sku?.itemToAssociate &&
                                sku?.itemToAssociate?.codeProvider
                                  ? sku.itemToAssociate.codeProvider
                                  : ''
                              }
                              onChange={(e) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        itemToAssociate: {
                                          ...sku.itemToAssociate,
                                          codeProvider: e.target.value
                                        }
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              disabled={props.onlyViewMode || props.searchModal}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            className="customSpacing"
                            className={`customSpacing skuUnitMeasurementsBox pr16 ${
                              !validProviderDescription && 'labelError'
                            }`}
                          >
                            <Autocomplete
                              options={
                                items?.filter((item) => item.descriptionSku) ||
                                []
                              }
                              getOptionLabel={(option) => option.descriptionSku}
                              classes={{
                                inputRoot: classes.inputRoot,
                                input: classes.input,
                                option: classes.option
                              }}
                              onFocus={(_event) =>
                                sku.providerToAssociate &&
                                getOptions(
                                  `${environments.catalog}/skus/providers`,
                                  'items',
                                  items,
                                  setItems,
                                  props.getId,
                                  {
                                    providerId:
                                      sku.providerToAssociate.providerId
                                  }
                                )
                              }
                              // disabled={!sku.providerToAssociate && !sku.providerToAssociate.providerId}
                              disabled={props.onlyViewMode || props.searchModal}
                              freeSolo
                              size="small"
                              value={sku?.itemToAssociate || ''}
                              onChange={(_event, value) => {
                                if (value) {
                                  setModalChanged(true);
                                  setMultipleSkuData(
                                    multipleSkuData.map((sku, i) => {
                                      if (i === index) {
                                        return {
                                          ...sku,
                                          itemToAssociate: value
                                        };
                                      }
                                      return { ...sku };
                                    })
                                  );
                                } else {
                                  setMultipleSkuData(
                                    multipleSkuData.map((sku, i) => {
                                      if (i === index) {
                                        return {
                                          ...sku,
                                          itemToAssociate: {}
                                        };
                                      }
                                      return { ...sku };
                                    })
                                  );
                                }
                              }}
                              onInputChange={(_event, value) => {
                                if (value) {
                                  setMultipleSkuData(
                                    multipleSkuData.map((sku, i) => {
                                      if (i === index) {
                                        return {
                                          ...sku,
                                          itemToAssociate: {
                                            ...sku.itemToAssociate,
                                            descriptionSku: value
                                          }
                                        };
                                      }
                                      return { ...sku };
                                    })
                                  );
                                } else {
                                  setMultipleSkuData(
                                    multipleSkuData.map((sku, i) => {
                                      if (i === index) {
                                        return {
                                          ...sku,
                                          itemToAssociate: {}
                                        };
                                      }
                                      return { ...sku };
                                    })
                                  );
                                }
                              }}
                              className="autocompleteSmall"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id={params.id}
                                  type="text"
                                  name="item"
                                  label="Descrição do Fornecedor *"
                                  variant="outlined"
                                  placeholder="Selecione..."
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              )}
                              renderOption={(params) => (
                                <Typography
                                  style={{
                                    fontSize: '12px',
                                    fontFamily: 'Lato'
                                  }}
                                >
                                  {params.descriptionSku}
                                </Typography>
                              )}
                            />
                          </Grid>

                          <Grid item xs={1} className="customSpacing">
                            <CurrencyTextField
                              value={
                                sku?.itemToAssociate?.purchaseLast
                                  ? sku.itemToAssociate.purchaseLast
                                  : ''
                              }
                              onBlur={(_event, value) => {
                                setModalChanged(true);
                                setMultipleSkuData(
                                  multipleSkuData.map((sku, i) => {
                                    if (i === index) {
                                      return {
                                        ...sku,
                                        itemToAssociate: {
                                          ...sku.itemToAssociate,
                                          purchaseLast: parseFloat(value),
                                          purchaseLastUnitary: !isNaN(
                                            sku?.itemToAssociate?.quantity
                                          )
                                            ? parseFloat(value) /
                                              sku.itemToAssociate.quantity
                                            : 0
                                        }
                                      };
                                    }
                                    return { ...sku };
                                  })
                                );
                              }}
                              variant="outlined"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              digitGroupSeparator="."
                              decimalCharacter=","
                              decimalPlaces={2}
                              decimalPlacesShownOnFocus={2}
                              type="text"
                              name="packagePrice"
                              fullWidth
                              disabled={props.onlyViewMode || props.searchModal}
                              label="R$ Embalagem"
                              placeholder="Digite aqui..."
                              className="textFieldSmall right"
                            />
                          </Grid>

                          <Grid item xs={2} className="customSpacing pr16">
                            <Box
                              display="flex"
                              classes={{ root: 'itemWithModal' }}
                            >
                              <CurrencyTextField
                                value={
                                  sku?.itemToAssociate?.quantity
                                    ? sku.itemToAssociate.quantity
                                    : ''
                                }
                                onBlur={(_event, value) => {
                                  setModalChanged(true);
                                  setMultipleSkuData(
                                    multipleSkuData?.length
                                      ? multipleSkuData.map((sku, i) => {
                                          if (i === index) {
                                            return {
                                              ...sku,
                                              itemToAssociate: {
                                                ...sku.itemToAssociate,
                                                quantity: parseFloat(value),
                                                purchaseLastUnitary: !isNaN(
                                                  sku?.itemToAssociate
                                                    ?.purchaseLast
                                                )
                                                  ? sku.itemToAssociate
                                                      .purchaseLast /
                                                    parseFloat(value)
                                                  : 0
                                              }
                                            };
                                          }
                                          return { ...sku };
                                        })
                                      : null
                                  );
                                }}
                                variant="outlined"
                                size="small"
                                currencySymbol=""
                                minimumValue="0"
                                outputFormat="string"
                                digitGroupSeparator="."
                                decimalCharacter=","
                                decimalPlaces={2}
                                decimalPlacesShownOnFocus={2}
                                type="text"
                                name="packageQuantity"
                                fullWidth
                                disabled={
                                  props.onlyViewMode || props.searchModal
                                }
                                label="Qtd. Emb."
                                placeholder="Digite aqui..."
                                className="textFieldSmall right"
                              />

                              <button
                                disabled={
                                  !sku?.providerToAssociate ||
                                  props.onlyViewMode
                                }
                                className={`ml16 fontSizeDefault defaultButton ${
                                  !sku?.providerToAssociate
                                    ? 'backButton'
                                    : 'submitButton borderNone'
                                }`}
                                type="submit"
                              >
                                Adicionar
                              </button>
                            </Box>
                          </Grid>
                        </Grid>
                      </form>

                      {sku?.providerItemError && (
                        <p
                          className="pl16 mb30"
                          style={{
                            color: 'red',
                            fontSize: 12,
                            fontFamily: 'Lato',
                            fontWeight: 'bold'
                          }}
                        >
                          Por favor preencha todos os campos referentes ao novo
                          fornecedor
                        </p>
                      )}

                      {isAdminMode && (
                        <Grid className="skuTable p12 pb12" xs={12}>
                          <ModalTable
                            currentSkuIndex={index}
                            data={sku?.providers ? sku.providers : []}
                            multipleSkuData={multipleSkuData}
                            setData={setData}
                            setMultipleSkuData={setMultipleSkuData}
                            editMode={props.editMode}
                            quantity={parseFloat(
                              sku?.quantity
                                ? sku.quantity.toString().replace(',', '.')
                                : null
                            )}
                            unit={sku?.unitsMeasurements}
                            providers={providers}
                            handleChangePurchaseSku={(e) =>
                              handleChangePurchaseSku(e)
                            }
                            getId={props.getId}
                            onlyViewMode={props.onlyViewMode}
                            userInfo={userInfo}
                            currentSku={sku}
                            searchModal={props.searchModal}
                            setUpdateMainList={props.setUpdateMainList}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {!isAdminMode && props?.sku?.providersPlatform.length ? (
                    <Grid
                      className="mb5"
                      container
                      xs={12}
                      style={{ padding: '25px 20px' }}
                    >
                      <Grid
                        container
                        item
                        xs={8}
                        justify="flex-start"
                        alignItems="center"
                        style={{ gap: '5px' }}
                      >
                        <Typography
                          className="bold fontSizeDefault"
                          style={{ color: '#5062F0' }}
                        >
                          FORNECEDORES / EMBALAGENS DISPONÍVEIS NA BLOOM
                        </Typography>
                        <img
                          src={BloomIcon}
                          alt="BloomIcon"
                          style={{ width: '16px' }}
                        />
                      </Grid>
                      <ProvidersTable
                        providersPlataform={props?.sku?.providersPlatform}
                        unit={sku?.unitsMeasurements}
                        setData={setData}
                        data={data}
                        skuId={props?.sku?.id}
                        restId={props.getId}
                        setAddProvider={setAddProvider}
                        setMessageProvider={setMessageProvider}
                        messageProvider={messageProvider}
                      />
                    </Grid>
                  ) : (
                    <div />
                  )}
                </Grid>
              </>
            ))
          ) : (
            <>
              {/* Sku */}
              <Grid className="modalBox mb0" container item xs={12} spacing={1}>
                <Grid className="modalTitle smallTabSecondary" item xs={12}>
                  <div className="dFlex">
                    <Typography className="dFlex">
                      SKU

                      <div id={`platformSkuRedirect-0`} style={{ width: 1, color: 'transparent' }}>
                        {`${window?.location?.origin}/admin/skus/0/${jsonBody?.sku?.id}`}
                      </div>

                      {isUserPlatform &&
                        <Tooltip
                          placement="bottom"
                          title={copied ? 'Copiado' : 'Copiar'}
                        >
                          <a
                            onMouseLeave={() => {
                              setTimeout(() => {
                                setCopied(false);
                              }, 1000);
                            }}
                            onClick={() => {
                              copyClipBoard(
                                'platformSkuRedirect', '0', ''
                              );
                              setCopied(true);
                            }}
                            className={classes.copyText}
                          >
                            <img
                              style={{
                                marginLeft: '5px',
                                marginBottom: '3px'
                              }}
                              src={CopyClipBoardIcon}
                              alt="CopyClipboard"
                            />
                          </a>
                        </Tooltip>
                      }
                    </Typography>

                    <div className="cardTab">
                      <p>
                        Preço R$
                        <span>
                          {handlePriceValue(
                            selectedItems.unit ? selectedItems.unit : null,
                            purchaseSku || 0,
                            selectedItems.product &&
                              selectedItems.product.consumptionUnitsPrimary
                              ? selectedItems.product.consumptionUnitsPrimary
                              : null,
                            purchaseProvider || 0
                          )}
                          {/* {selectedItems.unit && selectedItems.unit.abbreviation ? `/${selectedItems.unit.abbreviation}` : ""} */}
                        </span>
                      </p>
                    </div>

                    {!props.adminCatalogModal && !isQuoter && (
                      <Grid
                        item
                        className="pl16 customSpacing alignCenter dFlex"
                      >
                        <Grid item className="dFlex alignCenter mr20">
                          <Checkbox
                            checked={
                              jsonBody?.sku?.restaurants
                                ? jsonBody.sku.restaurants.resales
                                : null
                            }
                            name="resales"
                            icon={<img src={EmptyCheckboxIcon} width="16px" />}
                            checkedIcon={
                              <img
                                src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                width="16px"
                              />
                            }
                            disabled={props.onlyViewMode}
                            size="small"
                            onChange={(_event, checked) => {
                              setModalChanged(true);
                              setJsonBody({
                                ...jsonBody,
                                sku: {
                                  ...jsonBody.sku,
                                  restaurants: {
                                    ...jsonBody.sku.restaurants,
                                    resales: checked
                                  }
                                }
                              });
                            }}
                            fontSize="small"
                          />{' '}
                          Revenda
                        </Grid>

                        <Grid item className="dFlex alignCenter mr20">
                          <Checkbox
                            name="itemPriority"
                            checked={
                              jsonBody?.sku?.restaurants
                                ? jsonBody.sku.restaurants.itemPriority
                                : null
                            }
                            icon={<img src={EmptyCheckboxIcon} width="16px" />}
                            checkedIcon={
                              <img
                                src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                width="16px"
                              />
                            }
                            disabled={props.onlyViewMode}
                            className="checkBoxSmall pb0"
                            onChange={(_event, checked) => {
                              setModalChanged(true);
                              setJsonBody({
                                ...jsonBody,
                                sku: {
                                  ...jsonBody.sku,
                                  restaurants: {
                                    ...jsonBody.sku.restaurants,
                                    itemPriority: checked
                                  }
                                }
                              });
                            }}
                            size="small"
                          />{' '}
                          Item prioritário
                        </Grid>

                        <Grid item className="dFlex alignCenter mr20">
                          <Checkbox
                            name="brandFavorite"
                            checked={
                              jsonBody?.sku?.restaurants
                                ? jsonBody.sku.restaurants.brandFavorite
                                : null
                            }
                            icon={<img src={EmptyCheckboxIcon} width="16px" />}
                            checkedIcon={
                              <img
                                src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                width="16px"
                              />
                            }
                            disabled={props.onlyViewMode}
                            className="checkBoxSmall pb0"
                            onChange={(_event, checked) => {
                              setModalChanged(true);
                              setJsonBody({
                                ...jsonBody,
                                sku: {
                                  ...jsonBody.sku,
                                  restaurants: {
                                    ...jsonBody.sku.restaurants,
                                    brandFavorite: checked
                                  }
                                }
                              });
                            }}
                            size="small"
                          />{' '}
                          Marca preferida
                        </Grid>

                        <Grid item className="dFlex alignCenter mr20">
                          <Checkbox
                            name="brandAccepted"
                            checked={
                              jsonBody?.sku?.restaurants
                                ? jsonBody.sku.restaurants.brandAccepted
                                : null
                            }
                            icon={<img src={EmptyCheckboxIcon} width="16px" />}
                            checkedIcon={
                              <img
                                src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                                width="16px"
                              />
                            }
                            disabled={props.onlyViewMode}
                            className="checkBoxSmall pb0"
                            onChange={(_event, checked) => {
                              setModalChanged(true);
                              setJsonBody({
                                ...jsonBody,
                                sku: {
                                  ...jsonBody.sku,
                                  restaurants: {
                                    ...jsonBody.sku.restaurants,
                                    brandAccepted: checked
                                  }
                                }
                              });
                            }}
                            size="small"
                          />{' '}
                          Marca aceita
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>

                <Grid
                  className="modalBox mb0"
                  container
                  item
                  xs={isAdminMode ? 9 : 12}
                  spacing={2}
                  className="cardDefault mb0 pr0 pl0 pb0 m0"
                  style={{
                    paddingTop: '16px',
                    boxShadow: '4px 4px 4px 0px #00000080',
                    borderRadius: '0px, 4px, 4px, 4px !important'
                  }}
                >
                  <Grid
                    id="skuEanField"
                    item
                    style={{ minWidth: isAdminMode ? '170px' : '185px' }}
                    className={`${
                      isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                    } pRelative ${invalidEan && 'labelError'}`}
                  >
                    <TextField
                      label="EAN"
                      name="ean"
                      id="ean-number-0"
                      fullWidth
                      disabled={props.onlyViewMode || props.searchModal}
                      variant="outlined"
                      inputProps={{
                        maxLength: 13
                      }}
                      value={jsonBody?.sku?.ean || ''}
                      onInput={(e) => {
                        setModalChanged(true);
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onChange={(e) => handleChangeEan(e, jsonBody?.sku?.id)}
                      size="small"
                      className="textFieldSmall"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />

                    {eanFilterValue &&
                      eanFilterValue !== jsonBody?.sku?.ean && (
                        <Tooltip
                          placement="right"
                          title={
                            <p style={{ padding: '4px 6px' }}>
                              {eanFilterValue}
                            </p>
                          }
                        >
                          <img
                            className="pAbsolute cursorPointer"
                            onClick={() => {
                              setJsonBody({
                                ...jsonBody,
                                sku: { ...jsonBody.sku, ean: eanFilterValue }
                              });
                              setEanFilterValue(jsonBody?.sku?.ean);
                            }}
                            style={{ top: '3px', left: '82px', width: '12px' }}
                            src={SwitchIcon}
                            alt="SwitchIcon"
                          />
                        </Tooltip>
                      )}
                  </Grid>

                  <Grid
                    style={{ maxWidth: isAdminMode && '24%' }}
                    id="skuDescriptionField"
                    item
                    xs={3}
                    className={`${
                      isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                    } pRelative ${
                      !validDescription && 'labelError'
                    } skuDescriptionField`}
                  >
                    <Autocomplete
                      options={skus || []}
                      getOptionLabel={(option) => option.description}
                      inputValue={
                        jsonBody?.sku?.descriptionLabel ||
                        jsonBody?.sku?.description ||
                        (validateSkuDescription()
                          ? buildSkuDefaultDescription(null, true)
                          : '')
                      }
                      onInputChange={(_event, value) => {
                        if (value && value.inputValue) {
                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            product: value.inputValue
                          });
                          setSkuPreview({
                            ...skuPreview,
                            description: value.inputValue
                          });
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              description: value.inputValue,
                              descriptionLabel: value.inputValue
                            }
                          });
                          setValidDescription(true);
                        } else if (value) {
                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            product: value
                          });
                          setSkuPreview({
                            ...skuPreview,
                            description: value
                          });
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              description: value,
                              descriptionLabel: value
                            }
                          });
                          setValidDescription(true);
                        }
                      }}
                      classes={{
                        inputRoot: classes.inputRoot,
                        input: classes.input,
                        option: classes.option
                      }}
                      onFocus={(_event) =>
                        !skus.length &&
                        getOptions(
                          `${environments.catalog}/skus`,
                          'skus',
                          skus,
                          setSkus,
                          props.getId
                        )
                      }
                      value={
                        jsonBody?.sku?.descriptionLabel ||
                        jsonBody?.sku?.description ||
                        (validateSkuDescription()
                          ? buildSkuDefaultDescription(null, true)
                          : '')
                      }
                      id="description-sku-0"
                      required
                      disabled={props.onlyViewMode || props.searchModal}
                      onChange={(_event, value) => {
                        if (value && value.inputValue) {
                          setModalChanged(true);
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              description: value.inputValue?.trim(),
                              descriptionLabel: value.inputValue?.trim()
                            },
                            product: { ...jsonBody.product }
                          });
                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            product: value.inputValue
                          });
                          setValidDescription(true);
                        } else if (value) {
                          setModalChanged(true);
                          handleSelectedItems(value);
                          setJsonBody({
                            sku: {
                              ...jsonBody.sku,
                              ean: value.ean,
                              description: value?.trim(),
                              descriptionLabel: value?.trim(),
                              quantity: value.quantity,
                              id: value.id,
                              unitsMeasurements: value.unitsMeasurementsId
                                ? unitsMeasurements.find(
                                    (unit) =>
                                      unit.id === value.unitsMeasurementsId
                                  )
                                : unit || {},
                              unitsMeasurementsId: value.unitsMeasurementsId
                                ? unitsMeasurements.find(
                                    (unit) =>
                                      unit.id === value.unitsMeasurementsId
                                  ).id
                                : unit
                                ? unit.id
                                : {}
                            },
                            tags: value.tags || [],
                            product: value?.products?.length
                              ? products.find(
                                  (item) =>
                                    item.id === value.products[0].productId
                                )
                              : jsonBody.product.description
                              ? jsonBody.product
                              : {}
                          });

                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            product: value?.products?.length
                              ? value.products[0].products?.description
                              : jsonBody?.product?.description
                              ? jsonBody?.product?.description
                              : ''
                          });

                          if (value?.products?.length && !isAdminMode) {
                            setHideInventoryFields(
                              products.find(
                                (item) =>
                                  item.id === value.products[0]?.productId
                              )?.takeInventory
                                ? false
                                : !(
                                    products.find(
                                      (item) =>
                                        item.id === value.products[0]?.productId
                                    )?.setups &&
                                    products.find(
                                      (item) =>
                                        item.id === value.products[0]?.productId
                                    )?.setups.length
                                  )
                            );
                          }

                          if (value.providers.length) {
                            const exists = data.some(
                              (item) => item.id == value.providers[0].id
                            );

                            if (!exists) {
                              setData([...props.data, value.providers].flat());
                            }
                          }
                          setValidDescription(true);
                        } else {
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              description: '',
                              descriptionLabel: ''
                            }
                          });
                          setSelectedItems({ ...selectedItems, sku: {} });
                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            product: ''
                          });
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options || '', params || '');

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            ...selectedItems.sku,
                            inputValue: params.inputValue,
                            description: `Criar "${params.inputValue}"`
                          });
                        }

                        return filtered;
                      }}
                      freeSolo
                      className="autocompleteSmall"
                      placeholder="Selecione..."
                      onOpen={() => {
                        setHideAutocompleteTooltip(true);
                        setTimeout(() => {
                          document
                            .querySelector(`#skuDescriptionField input`)
                            .focus();
                        }, 500);
                      }}
                      onClose={() => {
                        setHideAutocompleteTooltip(false);
                      }}
                      renderInput={(params) =>
                        hideAutocompleteTooltip ? (
                          <TextField
                            {...params}
                            label="Descrição"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true,
                              endAdornment: (
                                <>{params.InputProps.endAdornment}</>
                              )
                            }}
                          />
                        ) : (
                          <Tooltip
                            disableHoverListener={
                              (
                                jsonBody?.sku?.descriptionLabel ||
                                jsonBody?.sku?.description ||
                                (validateSkuDescription()
                                  ? buildSkuDefaultDescription(null, true)
                                  : '')
                              )?.length < 20
                            }
                            title={
                              <p className="mb0" style={{ padding: '4px 6px' }}>
                                {jsonBody?.sku?.descriptionLabel ||
                                  jsonBody?.sku?.description ||
                                  (validateSkuDescription()
                                    ? buildSkuDefaultDescription(null, true)
                                    : '')}
                              </p>
                            }
                          >
                            <TextField
                              {...params}
                              label="Descrição"
                              variant="outlined"
                              size="small"
                              type="text"
                              placeholder="Selecione..."
                              InputLabelProps={{
                                shrink: true,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                )
                              }}
                            />
                          </Tooltip>
                        )
                      }
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.description}
                        </Typography>
                      )}
                    />
                  </Grid>

                  <Grid
                    style={{
                      maxWidth: isAdminMode && '38%',
                      marginRight: isAdminMode ? '0' : '16px'
                    }}
                    id="skuDescriptionField"
                    item
                    xs={5}
                    className={`customSpacing pRelative ${
                      !validDescription && 'labelError'
                    } skuDescriptionField`}
                  >
                    <Tooltip
                      disableHoverListener={
                        (validateSkuDescription()
                          ? buildSkuDefaultDescription()
                          : ''
                        )?.length < 40
                      }
                      title={
                        <p style={{ padding: '4px 6px' }}>
                          {validateSkuDescription()
                            ? buildSkuDefaultDescription()
                            : ''}
                        </p>
                      }
                    >
                      <TextField
                        name="skuDescriptionFixed"
                        fullWidth
                        variant="outlined"
                        disabled
                        size="small"
                        value={
                          (validateSkuDescription()
                            ? buildSkuDefaultDescription()
                            : ''
                          ).length > 40
                            ? `${(validateSkuDescription()
                                ? buildSkuDefaultDescription()
                                : ''
                              ).substring(0, 40)}...`
                            : validateSkuDescription()
                            ? buildSkuDefaultDescription()
                            : ''
                        }
                        className="textFieldSmall"
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid
                    style={{
                      maxWidth: isAdminMode && '10.3%',
                      flexBasis: '10.3%',
                      marginRight: isAdminMode ? '0' : '16px'
                    }}
                    id="skuQuantityField"
                    item
                    xs={1}
                    className={`customSpacing ${
                      !validQuantity && 'labelError'
                    } skuQuantityFieldClass`}
                  >
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      required
                      name="quantity"
                      value={
                        jsonBody.sku?.quantity ? jsonBody.sku?.quantity : ''
                      }
                      fullWidth
                      label="Quantidade"
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      onBlur={(event) => {
                        if (event?.target?.value) {
                          setValidQuantity(true);
                        }

                        setModalChanged(true);

                        setSkuDefaultDescription({
                          ...skuDefaultDescription,
                          quantity:
                            parseFloat(
                              event.target.value
                                .replace(/\./g, '')
                                .replace(',', '.')
                            ) || ''
                        });

                        setJsonBody({
                          ...jsonBody,
                          sku: {
                            ...jsonBody.sku,
                            quantity: parseFloat(
                              event.target.value
                                .replace(/\./g, '')
                                .replace(',', '.')
                            )
                          }
                        });
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      disabled={props.onlyViewMode || props.searchModal}
                    />
                  </Grid>

                  <Grid
                    style={{ maxWidth: isAdminMode && '11%', flexBasis: '11%' }}
                    id="skuUnitMeasurementsField"
                    item
                    xs={1}
                    className={`customSpacing skuUnitMeasurementsBox pr16 ${
                      !validUnitsMeasurements && 'labelError'
                    }`}
                  >
                    <Autocomplete
                      options={unitsMeasurements || []}
                      getOptionLabel={(option) => option.abbreviation}
                      classes={{
                        inputRoot: classes.inputRoot,
                        input: classes.input,
                        option: classes.option
                      }}
                      onFocus={() => {
                        if (!unitsMeasurements?.length) {
                          getOptions(
                            `${environments.catalog}/unitsMeasurements`,
                            'units',
                            unitsMeasurements,
                            setUnitsMeasurements,
                            props.getId,
                            null,
                            null,
                            10
                          );
                        }
                      }}
                      size="small"
                      value={
                        jsonBody?.sku?.unitsMeasurements || selectedItems?.unit
                      }
                      onChange={(_event, value) => {
                        if (value) {
                          setModalChanged(true);
                          setSelectedItems({ ...selectedItems, unit: value });
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              unitsMeasurementsId: value.id,
                              unitsMeasurements: value
                            }
                          });
                          setUnit(value);

                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            unit: value.abbreviation?.toLocaleUpperCase()
                          });
                          setValidUnitsMeasurements(true);
                        } else {
                          setSkuDefaultDescription({
                            ...skuDefaultDescription,
                            unit: ''
                          });
                          setSelectedItems({ ...selectedItems, unit: '' });
                          setJsonBody({
                            ...jsonBody,
                            sku: {
                              ...jsonBody.sku,
                              unitsMeasurementsId: '',
                              unitsMeasurements: ''
                            }
                          });
                          setUnit('');
                        }
                      }}
                      className="autocompleteSmall"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={params.id}
                          type="text"
                          name="unitsMeasurementsId"
                          label="Unidade"
                          variant="outlined"
                          required
                          placeholder="Selecione..."
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.abbreviation}
                        </Typography>
                      )}
                      disabled={props.onlyViewMode || props.searchModal}
                    />
                  </Grid>

                  <Grid
                    item
                    style={{ minWidth: isAdminMode ? '240px' : '185px' }}
                    className={`${
                      isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                    }`}
                  >
                    <Box
                      display="flex"
                      classes={{ root: 'itemWithModalSecondary' }}
                    >
                      <Autocomplete
                        options={packageTypes || []}
                        getOptionLabel={(option) => option?.description}
                        classes={{
                          inputRoot: classes.inputRoot,
                          input: classes.input,
                          option: classes.option
                        }}
                        onOpen={() => {
                          setHideAutocompleteTooltip(true);
                        }}
                        onClose={() => {
                          setHideAutocompleteTooltip(false);
                        }}
                        onFocus={() => {
                          if (!packageTypes?.length) {
                            getOptions(
                              `${environments.catalog}/typePackages`,
                              'packageTypes',
                              packageTypes,
                              setPackageTypes,
                              props.getId,
                              null,
                              null,
                              10
                            );
                          }
                        }}
                        value={selectedItems?.packageType}
                        onChange={(_event, value) => {
                          if (value) {
                            setModalChanged(true);
                            setSelectedItems({
                              ...selectedItems,
                              packageType: value
                            });
                            setJsonBody({
                              ...jsonBody,
                              sku: {
                                ...jsonBody.sku,
                                typePackageId: value.id,
                                packageType: value
                              }
                            });
                            setSkuDefaultDescription({
                              ...skuDefaultDescription,
                              packageType: value.description
                            });
                          } else {
                            setSelectedItems({
                              ...selectedItems,
                              packageType: {}
                            });
                            setJsonBody({
                              ...jsonBody,
                              sku: {
                                ...jsonBody.sku,
                                typePackageId: null,
                                packageType: ''
                              }
                            });
                            setSkuDefaultDescription({
                              ...skuDefaultDescription,
                              packageType: ''
                            });
                          }
                        }}
                        className="autocompleteSmall"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de Embalagem"
                            variant="outlined"
                            name="packageType"
                            size="small"
                            type="text"
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.description}
                          </Typography>
                        )}
                        disabled={props.onlyViewMode || props.searchModal}
                      />

                      {(isAdminMode || isCatalogProfile) && (
                        <IconButton
                          disabled={props.onlyViewMode}
                          classes={{ root: 'modalButton' }}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={() => {
                            setDialogRegisterPackageTypeSettings({
                              ...dialogRegisterPackageTypeSettings,
                              open: true
                            });
                          }}
                        >
                          <img
                            style={{ width: '25px' }}
                            src={AddIcon}
                            alt="AddIcon"
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    id="skuBrandField"
                    item
                    xs={3}
                    className={`${
                      isAdminMode ? 'customSpacing' : 'customSpacingAutocomplete'
                    } ${!validBrand && 'labelError'} ${
                      (!isAdminMode && 'mb20') || ''
                    }`}
                  >
                    <Box
                      display="flex"
                      className="itemWithModalSecondary pRelative"
                    >
                      <Autocomplete
                        options={brands || []}
                        getOptionLabel={(option) => option.description}
                        classes={{
                          inputRoot: classes.inputRoot,
                          input: classes.input,
                          option: classes.option
                        }}
                        value={selectedItems?.brand}
                        onInputChange={(_e, value) => {
                          if (value) {
                            debounceTimeList.forEach((el) => clearTimeout(el));

                            const newTimer = setTimeout(() => {
                              getOptions(
                                `${environments.catalog}/brands`,
                                'brands',
                                brands,
                                setBrands,
                                props.getId,
                                { description: value },
                                null,
                                10
                              );
                            }, 1500);

                            setDebounceTimeList([
                              ...debounceTimeList,
                              newTimer
                            ]);
                            setValidBrand(true);
                          }
                        }}
                        onFocus={() => {
                          if (!brands?.length) {
                            getOptions(
                              `${environments.catalog}/brands`,
                              'brands',
                              brands,
                              setBrands,
                              props.getId,
                              null,
                              null,
                              10
                            );
                          }
                        }}
                        onChange={(_event, value) => {
                          if (value) {
                            setModalChanged(true);
                            setSelectedItems({
                              ...selectedItems,
                              brand: value
                            });
                            setJsonBody({
                              ...jsonBody,
                              sku: { ...jsonBody.sku, brandsId: value.id }
                            });

                            setSkuDefaultDescription({
                              ...skuDefaultDescription,
                              brand:
                                value.id !== 29 && value.id !== 202
                                  ? value.description
                                  : ''
                            });
                            setValidBrand(true);
                          } else {
                            setSelectedItems({
                              ...selectedItems,
                              brand: null
                            });
                            setJsonBody({
                              ...jsonBody,
                              sku: { ...jsonBody.sku, brandsId: null }
                            });

                            setSkuDefaultDescription({
                              ...skuDefaultDescription,
                              brand: ''
                            });
                          }
                        }}
                        className="autocompleteSmall"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Marcas ${isAdminMode ? '*' : ''}`}
                            className="skuDialogBrandsInput"
                            variant="outlined"
                            name="brands"
                            size="small"
                            type="text"
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.description}
                          </Typography>
                        )}
                        disabled={props.onlyViewMode || props.searchModal}
                      />

                      {(isAdminMode || isCatalogProfile) && (
                        <IconButton
                          disabled={props.onlyViewMode}
                          classes={{ root: 'modalButton' }}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={toggleBrandModal}
                        >
                          <img
                            style={{ width: '25px' }}
                            src={AddIcon}
                            alt="AddIcon"
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={3} className="customSpacing">
                    <Box display="flex" classes={{ root: 'itemWithModal' }}>
                      <TextField
                        name="producersDescription"
                        fullWidth
                        label="Fabricante"
                        variant="outlined"
                        disabled
                        size="small"
                        value={
                          selectedItems.brand && selectedItems.brand.producers
                            ? selectedItems.brand.producers.description
                            : ''
                        }
                        className="textFieldSmall"
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={props.onlyViewMode || props.searchModal}
                      />

                      {(isAdminMode || isCatalogProfile) && (
                        <IconButton
                          disabled={props.onlyViewMode}
                          classes={{ root: 'modalButton' }}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={toggleProducerModal}
                        >
                          <img
                            style={{ width: '25px' }}
                            src={AddIcon}
                            alt="AddIcon"
                          />
                        </IconButton>
                      )}
                    </Box>

                    <Modal
                      className="defaultModal modalRegisterContainer"
                      open={producerModal}
                      onClose={toggleProducerModal}
                      aria-labelledby="Producer Modal"
                    >
                      <ProducerForm
                        isModal
                        redirectPath={null}
                        onClose={toggleProducerModal}
                        originIds={restaurantsId}
                        createMode
                        openModal={producerModal}
                      />
                    </Modal>
                  </Grid>

                  {(isRestaurant ? props.editMode : true) && (
                    <Grid
                      item
                      xs={3}
                      className="customSpacing pr16 pb0 pt0 cropImageBox"
                    >
                      <Box
                        display="flex"
                        classes={{ root: 'itemWithModal' }}
                        style={{ alignItems: 'flex-start' }}
                      >
                        {jsonBody?.sku?.images?.url ? (
                          <Tooltip
                            arrow
                            placement="right"
                            title={
                              <div
                                className={`cropDataSmallBox skuImgPreview mr0 ${
                                  jsonBody?.sku?.images?.url && 'bgWhite'
                                } largeSize`}
                              >
                                <img
                                  src={jsonBody?.sku?.images?.url}
                                  alt="skuImage"
                                />
                              </div>
                            }
                          >
                            <div
                              className={`cropDataSmallBox ${
                                jsonBody?.sku?.images?.url && 'bgWhite'
                              }`}
                            >
                              <img
                                src={jsonBody?.sku?.images?.url}
                                alt="skuImage"
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <div
                            className={`cropDataSmallBox ${
                              jsonBody?.sku?.images?.url && 'bgWhite'
                            }`}
                          >
                            <img src={DefaultImage} alt="No SkuImage" />
                          </div>
                        )}

                        {(isAdminMode || isCatalogProfile) && (
                          <div className="dFlex flexColumn alignStart">
                            <Button
                              disabled={props.onlyViewMode}
                              className="defaultButton submitButton mb5"
                              design="outlined"
                              label="Pesquisar imagem"
                              onClick={(e) => handleFindImage(e)}
                              style={{ outline: 0 }}
                            />

                            <Button
                              disabled={props.onlyViewMode}
                              className="defaultButton backButton"
                              design="outlined"
                              label="Enviar imagem"
                              onClick={(e) => handleSelectImage(e)}
                              style={{ outline: 0 }}
                            />

                            {jsonBody?.sku?.images?.name && (
                              <div className="dFlex justifyBetween">
                                <p className="w100 dFlex justifyCenter mt10 mr10 mb0">
                                  {ValidationLength(
                                    jsonBody?.sku?.images?.name,
                                    15
                                  )}
                                </p>

                                <img
                                  className="cursorPointer"
                                  src={CloseGrayIcon}
                                  alt="CloseGrayIcon"
                                  onClick={() => {
                                    if (props.onlyViewMode) return;

                                    handleClearFileInput();
                                    setJsonBody({
                                      ...jsonBody,
                                      sku: {
                                        ...jsonBody.sku,
                                        images: {
                                          ...jsonBody.sku.images,
                                          name: '',
                                          baseImage: '',
                                          id: '',
                                          url: null
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <input
                              id="fileInput"
                              type="file"
                              onChange={onChangeImage}
                              style={{ display: 'none' }}
                            />
                          </div>
                        )}
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} className="pr16 customSpacing pt0">
                    <TextField
                      label="Especificações"
                      multiline
                      name="specification"
                      value={
                        jsonBody?.sku?.specification
                          ? jsonBody.sku.specification
                          : ''
                      }
                      onChange={(e) => {
                        setModalChanged(true);
                        handleChangeSpecification(e);
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      className="textFieldSmall"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={props.onlyViewMode || props.searchModal}
                    />
                  </Grid>

                  {!isAdminMode && (
                    <Grid
                      className="skuTable"
                      item
                      xs={12}
                      style={{ padding: '12px 16px' }}
                    >
                      <ModalTable
                        lastReceivedProvider={props?.sku?.lastReceivedProvider}
                        data={data}
                        setData={setData}
                        editMode={props.editMode}
                        quantity={parseFloat(
                          jsonBody?.sku?.quantity
                            ? jsonBody.sku.quantity.toString().replace(',', '.')
                            : null
                        )}
                        unit={unit}
                        providers={providers}
                        handleChangePurchaseSku={(e) =>
                          handleChangePurchaseSku(e)
                        }
                        getId={props.getId}
                        onlyViewMode={props.onlyViewMode}
                        userInfo={userInfo}
                        currentSku={jsonBody?.sku}
                        searchModal={props.searchModal}
                        setUpdateMainList={props.setUpdateMainList}
                      />
                    </Grid>
                  )}
                </Grid>

                {isAdminMode && !isCatalogProfile && (
                  <Grid
                    className="modalBox mb0"
                    container
                    item
                    xs={3}
                    spacing={4}
                    className="cardDefault mb0 p0 m0 tagsManagerBox"
                  >
                    <TagsManager
                      tags={tags}
                      jsonBody={jsonBody}
                      setJsonBody={setJsonBody}
                      skuDefaultDescription={skuDefaultDescription}
                      setSkuDefaultDescription={setSkuDefaultDescription}
                      getId={props.getId}
                      onlyViewMode={props.onlyViewMode}
                    />
                  </Grid>
                )}

                {isAdminMode && (
                  <Grid
                    className="modalBox cardDefault boxShadowCustom p0 mb0"
                    container
                    item
                    xs={12}
                    spacing={1}
                  >
                    <p
                      className="pl16 mb0"
                      style={{
                        color: '#1F2445',
                        fontSize: 12,
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      }}
                    >
                      Adicionar novo Fornecedor/Embalagem
                    </p>

                    <form
                      id="providerForm"
                      className="mt10 mb0 w100 mr0 ml0"
                      style={{ padding: '0' }}
                      onSubmit={handleSubmit(associateItem)}
                    >
                      <Grid container spacing={2} className="w100 m0">
                        <Grid
                          item
                          xs={4}
                          className="customSpacing"
                          id="skuDialogProviderPackDescriptionInput"
                        >
                          <Box
                            display="flex"
                            classes={{ root: 'itemWithModal' }}
                          >
                            <Autocomplete
                              options={providers || []}
                              getOptionLabel={(option) => option.providerName}
                              classes={{
                                inputRoot: classes.inputRoot,
                                input: classes.input,
                                option: classes.option
                              }}
                              onFocus={() => {
                                if (!providers?.length) {
                                  getOptions(
                                    `${environments.providers}/providers`,
                                    'providers',
                                    providers,
                                    setProviders,
                                    props.getId == 0 ? '' : props.getId,
                                    null,
                                    null,
                                    10
                                  );
                                }
                              }}
                              onInputChange={(_, value) => {
                                if (value) {
                                  debounceTimeList.forEach((el) =>
                                    clearTimeout(el)
                                  );

                                  const newTimer = setTimeout(() => {
                                    getOptions(
                                      `${environments.providers}/providers`,
                                      'providers',
                                      providers,
                                      setProviders,
                                      props.getId == 0 ? '' : props.getId,
                                      { providerName: value },
                                      null,
                                      10
                                    );
                                  }, 1500);

                                  setDebounceTimeList([
                                    ...debounceTimeList,
                                    newTimer
                                  ]);
                                }
                              }}
                              value={selectedItems.provider}
                              size="small"
                              onChange={(_event, value) => {
                                if (value) {
                                  setModalChanged(true);
                                  setSelectedItems({
                                    ...selectedItems,
                                    provider: value,
                                    item: {
                                      ...selectedItems.item,
                                      providerId: value.providerId,
                                      name: value.providerName,
                                      suppliers: {addresses: value.addresses || []}
                                    }
                                  });
                                } else {
                                  setSelectedItems({
                                    ...selectedItems,
                                    provider: {},
                                    item: value
                                  });
                                }
                              }}
                              className="autocompleteSmall"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id={params.id}
                                  type="text"
                                  name="providers"
                                  label="Fornecedores"
                                  variant="outlined"
                                  placeholder="Selecione..."
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              )}
                              renderOption={(params) => (
                                <Typography
                                  style={{
                                    fontSize: '12px',
                                    fontFamily: 'Lato'
                                  }}
                                >
                                  {params.providerName}
                                </Typography>
                              )}
                              disabled={props.onlyViewMode || props.searchModal}
                            />

                            <IconButton
                              disabled={props.onlyViewMode}
                              classes={{ root: 'modalButton' }}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={toggleProviderModal}
                            >
                              <img
                                style={{ width: '25px' }}
                                src={AddIcon}
                                alt="AddIcon"
                              />
                            </IconButton>
                          </Box>

                          {existsPackage && (
                            <p className="requiredWarning">
                              Embalagem já cadastrada no SKU ({existsPackage?.skuId}) {existsPackage?.skus?.description || existsPackage?.descriptionSku}
                            </p>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          className="customSpacing"
                          id="skuDialogCodProvider"
                        >
                          <TextField
                            type="text"
                            name="providers"
                            label="Cód. Forn."
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              style: { fontFamily: 'Lato', fontSize: '12px' }
                            }}
                            InputProps={{
                              style: { fontFamily: 'Lato', fontSize: '12px' }
                            }}
                            value={
                              selectedItems?.item &&
                              selectedItems?.item?.codeProvider
                                ? selectedItems.item.codeProvider
                                : ''
                            }
                            className="textFieldSmall w100"
                            placeholder="Digite aqui..."
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={(e) => {
                              setSelectedItems({
                                ...selectedItems,
                                item: {
                                  ...selectedItems.item,
                                  codeProvider: e.target.value
                                }
                              });
                            }}
                            disabled={props.onlyViewMode || props.searchModal}
                          />

                          {codeProviderError && (
                            <p className="requiredWarning">
                              Código já cadastrado
                            </p>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          id="skuDialogDescriptionProvider"
                          className={`customSpacing skuUnitMeasurementsBox pr16 ${
                            !validProviderDescription && 'labelError'
                          }`}
                        >
                          <Autocomplete
                            options={
                              items?.length
                                ? items?.filter((item) => item.descriptionSku)
                                : []
                            }
                            getOptionLabel={(option) => option.descriptionSku}
                            classes={{
                              inputRoot: classes.inputRoot,
                              input: classes.input,
                              option: classes.option
                            }}
                            onInputChange={(_event, value) => {
                              setSelectedItems({
                                ...selectedItems,
                                provider: { ...selectedItems.provider },
                                item: {
                                  ...selectedItems.item,
                                  ...selectedItems.provider,
                                  descriptionSku: value
                                }
                              });
                            }}
                            onFocus={() =>
                              getOptions(
                                `${environments.catalog}/skus/providers`,
                                'items',
                                items,
                                setItems,
                                props.getId,
                                {
                                  providerId: selectedItems.provider.providerId
                                }
                              )
                            }
                            disabled={
                              !selectedItems.provider.providerId ||
                              props.onlyViewMode ||
                              props.searchModal
                            }
                            size="small"
                            value={selectedItems?.item}
                            onChange={(_event, value) => {
                              setModalChanged(true);
                              const newItemObj = {
                                codeProvider: value ? value.codeProvider : null,
                                descriptionSku: value
                                  ? value.descriptionSku
                                  : null,
                                purchaseLast: value ? value.purchaseLast : null,
                                purchaseLastUnitary: value
                                  ? value.purchaseLastUnitary
                                  : null,
                                quantity: value ? value.quantity : null
                              };

                              setSelectedItems({
                                ...selectedItems,
                                provider: { ...selectedItems.provider },
                                item: {
                                  ...selectedItems.item,
                                  ...selectedItems.provider,
                                  ...newItemObj
                                }
                              });
                            }}
                            className="autocompleteSmall"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="item"
                                label="Descrição do Fornecedor *"
                                variant="outlined"
                                value={selectedItems.item}
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px',
                                  fontFamily: 'Lato'
                                }}
                              >
                                {params.descriptionSku}
                              </Typography>
                            )}
                          />
                        </Grid>

                        <Grid item xs={1} className="customSpacing">
                          <CurrencyTextField
                            value={
                              selectedItems.item &&
                              selectedItems?.item?.purchaseLast
                                ? selectedItems?.item?.purchaseLast
                                : ''
                            }
                            onBlur={(_event, value) => {
                              setModalChanged(true);
                              setSelectedItems({
                                ...selectedItems,
                                item: {
                                  ...selectedItems.item,
                                  purchaseLast: parseFloat(value),
                                  purchaseLastUnitary: selectedItems.item
                                    ? calcSKUPriceFromPackage(
                                        selectedItems.item.quantity,
                                        value
                                      )
                                    : 0
                                }
                              });
                            }}
                            variant="outlined"
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            digitGroupSeparator="."
                            decimalCharacter=","
                            decimalPlaces={2}
                            decimalPlacesShownOnFocus={2}
                            type="text"
                            name="packagePrice"
                            fullWidth
                            label="R$ Embalagem"
                            className="textFieldSmall right"
                            placeholder="Digite aqui..."
                            disabled={props.onlyViewMode || props.searchModal}
                          />
                        </Grid>

                        <Grid item xs={2} className="customSpacing pr16">
                          <Box
                            display="flex"
                            classes={{ root: 'itemWithModal' }}
                          >
                            <CurrencyTextField
                              value={
                                selectedItems.item &&
                                selectedItems?.item?.quantity
                                  ? selectedItems?.item?.quantity
                                  : ''
                              }
                              onBlur={(_event, value) => {
                                setModalChanged(true);
                                setSelectedItems({
                                  ...selectedItems,
                                  item: {
                                    ...selectedItems.item,
                                    quantity: parseFloat(value),
                                    purchaseLastUnitary:
                                      selectedItems.item.purchaseLast
                                  }
                                });
                              }}
                              variant="outlined"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              digitGroupSeparator="."
                              decimalCharacter=","
                              decimalPlaces={2}
                              decimalPlacesShownOnFocus={2}
                              type="text"
                              name="packageQuantity"
                              fullWidth
                              label="Qtd. Emb."
                              className="textFieldSmall right"
                              placeholder="Digite aqui..."
                              disabled={props.onlyViewMode || props.searchModal}
                            />

                            <button
                              id="skuDialogAddProviderPackageButton"
                              disabled={props.onlyViewMode || existsPackage}
                              className="ml16 borderNone fontSizeDefault defaultButton submitButton"
                              type="submit"
                            >
                              Adicionar
                            </button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>

                    {jsonBody?.sku?.providerItemError && (
                      <p
                        className="pl16 mb30"
                        style={{
                          color: 'red',
                          fontSize: 12,
                          fontFamily: 'Lato',
                          fontWeight: 'bold'
                        }}
                      >
                        Por favor preencha todos os campos referentes ao novo
                        fornecedor
                      </p>
                    )}

                    {isAdminMode && (
                      <Grid className="skuTable p12 pb12" xs={12} >
                        <ModalTable
                          defaultStoreOtpions={storeOptions}
                          isAdmin={isAdminMode}
                          lastReceivedProvider={props?.sku?.lastReceivedProvider}
                          data={data}
                          setData={setData}
                          editMode={props.editMode}
                          quantity={parseFloat(
                            jsonBody?.sku?.quantity
                              ? jsonBody.sku.quantity.toString().replace(',', '.')
                              : null
                          )}
                          unit={unit}
                          providers={providers}
                          handleChangePurchaseSku={(e) =>
                            handleChangePurchaseSku(e)
                          }
                          getId={props.getId}
                          onlyViewMode={props.onlyViewMode}
                          userInfo={userInfo}
                          currentSku={jsonBody?.sku}
                          searchModal={props.searchModal}
                          setUpdateMainList={props.setUpdateMainList}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              {!isAdminMode && props?.sku?.providersPlatform?.length ? (
                <Grid
                  className="mb5"
                  container
                  xs={12}
                  style={{ padding: '25px 15px', gap: '5px' }}
                >
                  <Grid
                    container
                    item
                    xs={8}
                    justify="flex-start"
                    alignItems="center"
                    style={{ gap: '5px' }}
                  >
                    <Typography
                      className="bold fontSizeDefault"
                      style={{ color: '#5062F0' }}
                    >
                      FORNECEDORES / EMBALAGENS DISPONÍVEIS NA BLOOM
                    </Typography>
                    <img
                      src={BloomIcon}
                      alt="BloomIcon"
                      style={{ width: '16px' }}
                    />
                  </Grid>
                  <ProvidersTable
                    providersPlataform={props?.sku?.providersPlatform}
                    unit={unit}
                    setData={setData}
                    data={data}
                    skuId={props?.sku?.id}
                    restId={props.getId}
                    setAddProvider={setAddProvider}
                    setMessageProvider={setMessageProvider}
                    messageProvider={messageProvider}
                  />
                </Grid>
              ) : (
                <div />
              )}
            </>
          )}
        </Grid>

        <Snackbar
          open={!!genericMessage?.description}
          autoHideDuration={2000}
          onClose={() => {
            setGenericMessage({
              ...genericMessage,
              description: '',
              status: ''
            });
          }}
        >
          <Alert
            onClose={() => {
              setGenericMessage({
                ...genericMessage,
                description: '',
                status: ''
              });
            }}
            severity={genericMessage?.status}
          >
            {genericMessage?.description}
          </Alert>
        </Snackbar>

        <CommonAlert
          open={errMsg}
          indexMessage={messageError}
          onClose={handleClose}
        />

        <CommonAlert
          open={messageProvider.message}
          indexMessage={messageProvider.message}
          messagePersonal={messageProvider.message}
          severity={messageProvider.severity}
          onClose={handleClose}
        />

        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            props.setUpdateMainList && props.setUpdateMainList(true);
            props.onClose();
            handleClose();
            setSkuAllValues(false);
          }}
        >
          <Alert
            onClose={() => {
              props.setUpdateMainList && props.setUpdateMainList(true);
              props.onClose();
              handleClose();
              setSkuAllValues(false);
            }}
            severity="success"
          >
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        {errProviderMsg && (
          <p className="requiredWarning textAlignCenter">
            É necessário ter pelo menos uma embalagem por sku.
          </p>
        )}
      </DialogContent>

      <DialogActions style={{ padding: '10px 22px 16px 16px' }}>
        <Grid container item xs={12} justify="flex-end" alignItems="center">
          {props.editMode && (
            <div className="dFlex flexColumn mr20">
              <span className="bold fontSizeDefault">
                Data da criação:{' '}
                {moment(props?.sku?.created_at).format('DD/MM/YYYY')}
              </span>

              <span className="bold fontSizeDefault">
                Última atualização:{' '}
                {moment(props?.sku?.updated_at).format('DD/MM/YYYY')}
              </span>

              {props?.sku?.user ? (
                <span className="bold fontSizeDefault">
                  Usuário: {props?.sku?.user}
                </span>
              ) : null}
            </div>
          )}

          {!props.onlyViewMode && !props?.canApproveSku && (
            <>
              <Button
                className="defaultButton backButton"
                design="outlined"
                label="Voltar"
                onClick={() => {
                  setOpenedSkus(
                    openedSkus.filter((sku) => sku !== props?.sku?.id)
                  );

                  if (modalChanged) {
                    props.setUpdateMainList && props.setUpdateMainList(true);
                    closeSkuModal();
                  } else {
                    clearObjects();
                    props.onClose();
                  }
                }}
                style={{ outline: 0 }}
              />

              <Button
                id="skuDialogSaveBtn"
                className="defaultButton submitButton"
                design="contained"
                disabled={requestProcessing}
                onClick={() => {
                  onSubmit();
                }}
                style={{ marginLeft: '20px' }}
                label={loading ? 'Salvando...' : 'Salvar'}
              />
            </>
          )}

          {props?.canApproveSku && 
            <Button
              id="skuDialogSaveBtn"
              className="defaultButton submitButton"
              design="contained"
              disabled={requestProcessing}
              onClick={() => {
                onSubmit();
              }}
              style={{ marginLeft: '20px' }}
              label={loading ? 'Processando...' : 'Aprovar'}
            />
          }

          {freeCatalogParamAssociate &&
          freeCatalogParamAssociate.length > 0 &&
          canAssociateShow > 0 ? (
            <Button
              design="contained"
              className="defaultButton submitButton"
              disabled={requestProcessing}
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'productsAssociation'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                onSubmit(true, true);
              }}
              style={{ marginLeft: '20px' }}
              label={loading ? 'Salvando...' : 'Salvar e associar'}
            />
          ) : null}
        </Grid>
      </DialogActions>

      {(isAdminMode || isCatalogProfile) && (
        <ImageCropper
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          getCropData={getCropData}
          obj={isMultipleSku ? multipleSkuData : jsonBody}
          setObj={isMultipleSku ? setMultipleSkuData : setJsonBody}
          setCropper={setCropper}
          handleSelectImage={handleSelectImage}
          handleClearFileInput={handleClearFileInput}
        />
      )}

      <Modal
        className="defaultModal modalConfirmationContainer small customZIndex"
        open={confirmCloseModal}
        disableBackdropClick
        aria-labelledby="Sku Close Confirmation Modal"
      >
        <div className="modalContent">
          <h4
            style={{
              fontSize: '18px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Deseja realmente abandonar a edição?
          </h4>

          <div className="modalActions pb10">
            <Button
              className="defaultButton backButton"
              design="outlined"
              label="Voltar"
              onClick={() => {
                props.setUpdateMainList && props.setUpdateMainList(true);
                setOpenedSkus(
                  openedSkus.filter((sku) => sku !== props?.sku?.id)
                );

                if (modalChanged) {
                  closeSkuModal();
                } else {
                  clearObjects();
                  props.onClose();
                }
              }}
              style={{ outline: 0 }}
            />
            <Button
              className="defaultButton submitButton"
              type="submit"
              design="contained"
              onClick={clearModal}
              label="Sim"
            />
          </div>
        </div>
      </Modal>

      <Dialog
        fullScreen={
          modalSettings.find((modal) => modal.name === 'productsAssociation')
            .fullScreen
        }
        maxWidth={
          modalSettings.find((modal) => modal.name === 'productsAssociation')
            .maxWidth
        }
        open={
          modalSettings.find((modal) => modal.name === 'productsAssociation')
            .open
        }
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'productsAssociation'
                ? { ...modal, open: false }
                : modal
            )
          );
        }}
        className="customZIndex"
        PaperComponent={props.PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          <Typography
            style={{
              fontSize: '18px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Produto não associado.{'\n'}
            Tem certeza que deseja criar{'\n'}1 SKU sem 1 Produto associado?
          </Typography>
        </DialogTitle>

        <DialogActions className="justifyCenter pb20">
          <Button
            className="defaultButton backButton"
            design="outlined"
            label="Cancelar"
            onClick={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productsAssociation'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            style={{ outline: 0 }}
          />
          <Button
            design="contained"
            className="defaultButton submitButton"
            disabled={requestProcessing}
            onClick={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productsAssociation'
                    ? { ...modal, open: false }
                    : modal
                )
              );
              onSubmit(true);
            }}
            style={{ marginLeft: '20px' }}
            label={loading ? 'Salvando...' : 'Salvar'}
          />
          {freeCatalogParamAssociate &&
          freeCatalogParamAssociate.length > 0 &&
          canAssociateShow > 0 ? (
            <Button
              design="contained"
              className="defaultButton submitButton"
              disabled={requestProcessing}
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'productsAssociation'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                onSubmit(true, true);
              }}
              style={{ marginLeft: '20px' }}
              label={loading ? 'Salvando...' : 'Salvar e associar'}
            />
          ) : null}
        </DialogActions>
      </Dialog>

      <Dialog
        className="customZIndex"
        fullScreen={
          modalSettings.find((modal) => modal.name === 'providerAssociation')
            .fullScreen
        }
        maxWidth={
          modalSettings.find((modal) => modal.name === 'providerAssociation')
            .maxWidth
        }
        open={
          modalSettings.find((modal) => modal.name === 'providerAssociation')
            .open
        }
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'providerAssociation'
                ? { ...modal, open: false }
                : modal
            )
          );
        }}
        PaperComponent={props.PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          <Typography
            style={{
              fontSize: '18px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {isMultipleSku
              ? providersToAssociateObj?.length === 1
                ? `${providersToAssociateObj?.length} fornecedor não associado, deseja associar?`
                : `${providersToAssociateObj?.length} fornecedores não associados, deseja associá-los?`
              : 'Fornecedor não associado, deseja associar?'}
          </Typography>
        </DialogTitle>

        <DialogActions className="justifyCenter pb20">
          <Button
            className="defaultButton backButton"
            design="outlined"
            label={loading ? 'Salvando...' : 'Não associar e Salvar'}
            onClick={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'providerAssociation'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            style={{ outline: 0 }}
          />

          <Button
            design="contained"
            className="defaultButton submitButton"
            onClick={() => {
              associateProviderItemAndHideError();
              setSubmitMainForm(true);
            }}
            style={{ marginLeft: '20px' }}
            label={loading ? 'Salvando...' : 'Associar e Salvar'}
          />
        </DialogActions>
      </Dialog>

      {dialogProvidersSettings.open && (
        <Modal
          className="defaultModal modalRegisterContainer customZIndex"
          open={dialogProvidersSettings.open}
          onClose={toggleProviderModal}
          aria-labelledby="Provider Modal"
        >
          <ProviderForm
            isModal
            redirectPath={null}
            onClose={toggleProviderModal}
            createMode
            skuModal
            dialogProvidersSettings={dialogProvidersSettings}
            multipleSkuData={multipleSkuData}
            setMultipleSkuData={setMultipleSkuData}
            isMultipleSku={isMultipleSku}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </Modal>
      )}

      {dialogBrandsSettings.open && (
        <BrandForm
          updateOptions={props.updateOptions}
          setUpdateOptions={props.setUpdateOptions}
          openModal={dialogBrandsSettings.open}
          isModal
          redirectPath={null}
          onClose={toggleBrandModal}
          originIds={restaurantsId}
          createMode
          skuModal
          dialogBrandsSettings={dialogBrandsSettings}
          multipleSkuData={multipleSkuData}
          setMultipleSkuData={setMultipleSkuData}
          isMultipleSku={isMultipleSku}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          jsonBody={jsonBody}
          setJsonBody={setJsonBody}
          skuDefaultDescription={skuDefaultDescription}
          setSkuDefaultDescription={setSkuDefaultDescription}
        />
      )}

      {dialogRegisterPackageTypeSettings.open && (
        <RegisterPackageType
          updateOptions={props.updateOptions}
          setUpdateOptions={props.setUpdateOptions}
          open={dialogRegisterPackageTypeSettings.open}
          dialogRegisterPackageTypeSettings={dialogRegisterPackageTypeSettings}
          setDialogRegisterPackageTypeSettings={
            setDialogRegisterPackageTypeSettings
          }
          isModal
          isMultipleSku={isMultipleSku}
          multipleSkuData={multipleSkuData}
          setMultipleSkuData={setMultipleSkuData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          jsonBody={jsonBody}
          skuModal
          setJsonBody={setJsonBody}
          skuDefaultDescription={skuDefaultDescription}
          setSkuDefaultDescription={setSkuDefaultDescription}
          userInfo={userInfo}
        />
      )}

      {openCategorieDialog && (
        <TreeContainer
          onOpenDialog={openCategorieDialog}
          handleClose={() => {
            setOpenCategorieDialog(!openCategorieDialog);
          }}
          getId={props.getId}
          profileType={userInfo?.profile?.[0]?.[0]?.name}
          setOutsideData={setCategories}
        />
      )}

      {(isAdminMode || isCatalogProfile) &&
        modalSettings.find((modal) => modal.name === 'searchImage').open && (
          <GoogleImageSearcher
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            multipleSkuData={multipleSkuData}
            setMultipleSkuData={setMultipleSkuData}
            jsonBody={jsonBody}
            setJsonBody={setJsonBody}
            providers={data}
            defaultValue={
              isMultipleSku
                ? validateSkuDescription(
                    modalSettings.find((modal) => modal.name === 'searchImage')
                      ?.currentSku
                  )
                  ? buildSkuGoogleDescription(
                      modalSettings.find(
                        (modal) => modal.name === 'searchImage'
                      )?.currentSku
                    )
                  : modalSettings.find((modal) => modal.name === 'searchImage')
                      ?.currentSku?.description || ''
                : validateSkuDescription()
                ? buildSkuGoogleDescription()
                : jsonBody?.sku?.description
                ? jsonBody.sku.description
                : Object.keys(selectedItems.sku)?.length
                ? selectedItems.sku
                : ''
            }
          />
        )}

      {isAdminMode &&
        modalSettings.find((modal) => modal.name === 'eanSuggestions').open && (
          <EanSuggestion
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            multipleSkuData={multipleSkuData}
            setMultipleSkuData={setMultipleSkuData}
            jsonBody={jsonBody}
            setJsonBody={setJsonBody}
            userInfo={props.userInfo}
          />
        )}

      {(isAdminMode || isCatalogProfile) &&
        modalSettings.find((modal) => modal.name === 'brandSuggestions')
          .open && (
          <BrandSuggestion
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            multipleSkuData={multipleSkuData}
            setMultipleSkuData={setMultipleSkuData}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            jsonBody={jsonBody}
            setJsonBody={setJsonBody}
            skuDefaultDescription={skuDefaultDescription}
            setSkuDefaultDescription={setSkuDefaultDescription}
            userInfo={userInfo}
          />
        )}

      {(isAdminMode || isCatalogProfile) &&
        modalSettings.find((modal) => modal.name === 'generalSuggestions')
          .open && (
          <GeneralSuggestions
            suggestionsSelected={suggestionsSelected}
            setSuggestionsSelected={setSuggestionsSelected}
            generalSuggestionLoading={generalSuggestionLoading}
            reprocessItem={reprocessItem}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            multipleSkuData={multipleSkuData}
            setMultipleSkuData={setMultipleSkuData}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            jsonBody={jsonBody}
            setJsonBody={setJsonBody}
            skuDefaultDescription={skuDefaultDescription}
            setSkuDefaultDescription={setSkuDefaultDescription}
            userInfo={userInfo}
            handleClearFileInput={handleClearFileInput}
            providers={data}
            defaultValue={
              isMultipleSku
                ? validateSkuDescription(
                    modalSettings.find((modal) => modal.name === 'searchImage')
                      ?.currentSku
                  )
                  ? buildSkuGoogleDescription(
                      modalSettings.find(
                        (modal) => modal.name === 'searchImage'
                      )?.currentSku
                    )
                  : modalSettings.find(
                      (modal) => modal.name === 'generalSuggestions'
                    )?.currentSku?.description
                  ? modalSettings.find(
                      (modal) => modal.name === 'generalSuggestions'
                    ).currentSku.description
                  : ''
                : validateSkuDescription()
                ? buildSkuGoogleDescription()
                : jsonBody?.sku?.description
                ? jsonBody.sku.description
                : Object.keys(selectedItems.sku)?.length
                ? selectedItems.sku
                : ''
            }
          />
        )}
    </Dialog>
  );
}
