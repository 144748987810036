/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useState } from 'react';

import { 
  Checkbox,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography, 
  Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import TreeSelector from 'components/NewTreeSelector';
import ArrowRightYellowBg from 'images/icons/arrows/arrowRightWhiteBgYellowCircle.svg';
import CheckBoxIcon from 'images/icons/signals/checks/checkWhiteNavyBlueBgCircle.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import { calcValueDifference } from '../../ValuesList';
import {
  filterProducts,
  filterByDiff,
  handleChangeRadio,
  handleFilterOnlyNotCounted,
  handleOrderByQuantity
} from '../MainFunctions';
import {
  HeaderContainer,
  Title,
  TotalContainer,
  StyledGrid,
  TotalDiscard
} from '../styles';

const environments = Environment(process.env.REACT_APP_ENV);

function CountHeader({
  enabledFilterByDiff,
  differenceBetweenCountAndMenuAlert,
  orderByQuantityDesc,
  filterOnlyNotCounted,
  getId,
  products,
  countDate,
  periodicity,
  isCmvCount,
  storageLocationDescription,
  countCategoryDescription,
  setCountCategoryDescription,
  filterSelectedProduct,
  setFilterSelectedProduct,
  countGroupInfos,
  updateTotalDiffValues,
  smallScreen,
  setEnabledFilterByDiff,
  setProducts,
  setFilterOnlyNotCounted,
  isSelectedRealDiff,
  setOrderByQuantityDesc,
  salesInfos,
  isReportTabActive,
  lastExtractSale,
  hasProcessingInProgress,
  setModalStates,
  modalStates,
  valuesDiscard,
  setRadioValue,
  radioValue,
  date,
  countId,
  assortmentDate,
  setLoading,
  assortmentsOptions,
  setAssortmentDate
}) {
  const [productOptions, setProductOptions] = useState([]);
  const [productFilterValue, setProductFilterValue] = useState(
    filterSelectedProduct
  );

  const totalDifferenceInReal = (type) => {
    const productsFiltered = filterProducts(type, true, products);

    const total = productsFiltered?.reduce(
      (acc, curr) => acc + calcValueDifference(curr),
      0
    );

    return (
      total.toLocaleString('pt-br', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }) || '0,00'
    );
  };

  const closureTotal = useMemo(() => {
    if (salesInfos.length) {
      const total = salesInfos.find(el => el._id === parseFloat(getId))?.total || 0

      return (
        total.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }) || '0,00'
      );
    }

    return '0,00';
  }, [salesInfos]);

  const validateQueue = () => {
    if (
      (hasProcessingInProgress?.existQueue === 'reprocessing' &&
        hasProcessingInProgress?.isToday) ||
      hasProcessingInProgress?.existQueue === 'processing'
    ) {
      return 'Em andamento';
    }
    return 'Concluído';
  };

  const frequencyTranslated = () => {
    const frequencyType = countGroupInfos?.countGroup?.counts?.length && (countGroupInfos?.countGroup?.periodicity?.type || periodicity?.type)
    let type = '';

    switch (frequencyType) {
      case 'DIARY':
        type = 'Diário';
        break;
      case 'MONTHLY':
        type = 'Mensal';
        break;
      case 'BIMONTHLY':
        type = 'Bimensal';
        break;
      case 'WEEKLY':
        type = 'Semanal';
        break;
      case 'BIWEEKLY':
        type = 'Bissemanal';
        break;
      case 'FREE_WEEKLY':
        type = 'Semenal Livre';
        break;
      case 'FREE_MONTHLY':
        type = 'Mensal Livre';
        break;
      default:
        break;
    }
    
    return type
  }

  return (
    <HeaderContainer showAlert={differenceBetweenCountAndMenuAlert}>
      <StyledGrid
        className="alignCenter mb0 pSticky gridTableCount alignStart"
        container
      >
        <Grid container item className="countMainInfosBox p0">
          <section className="countInfos">
            <div className="countDateInfo bold">
              {countDate ? moment(countDate).format('DD/MM/YYYY') : '-'}
            </div>

            <div className="countOtherInfos">
              <div className="lineInfo dFlex">
                <span className="bold">
                  Contagem
                </span>

                <span className="">
                  {countGroupInfos?.countGroup?.counts?.length &&
                    countGroupInfos.isCountGroup
                      ? ValidationLength(
                          periodicity?.description
                            ? ` ${periodicity?.description}`
                            : '',
                          smallScreen ? 30 : 40
                        )
                      : periodicity?.description
                      ? ` ${periodicity?.description}`
                      : ''
                  }
                </span>

                {isCmvCount ? 
                  <div
                    style={{
                      width: "38.89px",
                      height: "20px",
                      backgroundColor: "#5062F0",
                      borderRadius: "10px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "8px"
                    }}
                  >
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        lineHeight: "15px",
                        fontSize: "10px"
                      }}
                    >
                      CMV
                    </span>
                  </div>
                : null}
              </div>

              <div className="lineInfo dFlex">
                <span className="bold">
                  Frequência
                </span>

                <span className="uppercase">
                  {countGroupInfos?.countGroup?.counts?.length &&
                    countGroupInfos.isCountGroup
                      ? ValidationLength(
                          frequencyTranslated()
                            ? ` ${frequencyTranslated()}`
                            : '',
                          smallScreen ? 30 : 40
                        )
                      : frequencyTranslated()
                      ? ` ${frequencyTranslated()}`
                      : ''
                  }
                </span>
              </div>

              <div className="lineInfo dFlex">
                <span className="bold">
                  Local de Estoque
                </span>

                <span className="">
                  {isReportTabActive
                    ? '-'
                    : countGroupInfos?.countGroup?.counts?.length &&
                      countGroupInfos.isCountGroup
                    ? ValidationLength(
                        periodicity?.description
                          ? `${storageLocationDescription || '-'}`
                          : '-',
                        smallScreen ? 30 : 40
                      )
                    : periodicity?.description || '-'
                    ? `${storageLocationDescription || '-'}`
                    : ''
                  }
                </span>
              </div>
            </div>
          </section>
        </Grid>

        <Grid className="categoryFilter dFlex flexColumn">
          <div className="dFlex flexColumn">
            <div className="dFlex alignCenter">
              <TreeSelector
                label="Categoria"
                inputOptions={{
                  endpoint: 'categories',
                  originId: getId,
                  type: 'Shopping'
                }}
                placeHolder="Selecione..."
                countCategoryDescription={countCategoryDescription}
                boxSize="50%"
                customInputSize="66%"
                justifyBoxContent="flex-start"
                setCountCategoryDescription={setCountCategoryDescription}
              />

              <div className="dFlex alignCenter diaryCountsProductAutocomplete">
                <label
                  className="bold fontSizeDefault mr10 mb0"
                  style={{ marginTop: '-8px' }}
                >
                  Produto
                </label>

                <Autocomplete
                  key="productFilter"
                  tabIndex={-1}
                  options={productOptions || []}
                  disablePortal
                  freeSolo
                  onKeyDown={(event) => {
                    if (event.keyCode === 8 || event.keyCode === 13) {
                      setProductFilterValue(event.target.value);
                      setFilterSelectedProduct(event.target.value);
                    }
                  }}
                  onFocus={() => {
                    if (products.length && productOptions.length) return;

                    getOptions(
                      `${environments.catalog}/products`,
                      'productOptions',
                      productOptions,
                      setProductOptions,
                      getId,
                      { autoComplete: true },
                      null,
                      2000
                    );
                  }}
                  getOptionLabel={(option) => option?.description || option}
                  className="autocompleteSmall"
                  defaultValue={
                    typeof productFilterValue === 'string'
                      ? productFilterValue
                      : productFilterValue?.description || ''
                  }
                  inputValue={
                    typeof productFilterValue === 'string'
                      ? productFilterValue
                      : productFilterValue?.description || ''
                  }
                  onInputChange={(_, value) => {
                    setProductFilterValue(value);
                  }}
                  size="small"
                  onChange={(_, value) => {
                    setProductFilterValue(value || '');
                    setFilterSelectedProduct(value || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      tabIndex={-1}
                      id={params.id}
                      type="text"
                      name="Products"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </div>
            </div>

            <div className="dFlex w100 mt5" style={{ marginLeft: '-2px', marginBottom: 7 }}>
              <div className="dFlex alignCenter">
                <Checkbox
                  checked={filterOnlyNotCounted}
                  size="small"
                  onChange={(_, checked) =>
                    handleFilterOnlyNotCounted(
                      checked,
                      setFilterOnlyNotCounted,
                      setProducts,
                      products
                    )
                  }
                />

                <p
                  className="mb0 fontSizeDefault cursorPointer"
                  onClick={() =>
                    handleFilterOnlyNotCounted(
                      !filterOnlyNotCounted,
                      setFilterOnlyNotCounted,
                      setProducts,
                      products
                    )
                  }
                >
                  Visualizar produtos sem contagem
                </p>
              </div>

              {/* <div className="dFlex alignCenter" style={{ marginLeft: 20 }}>
                <Checkbox
                  checked={orderByQuantityDesc}
                  size="small"
                  onChange={(_, checked) =>
                    handleOrderByQuantity(
                      checked,
                      products,
                      isSelectedRealDiff,
                      setOrderByQuantityDesc,
                      setProducts
                    )
                  }
                />

                <p
                  className="mb0 fontSizeDefault cursorPointer"
                  onClick={() =>
                    handleOrderByQuantity(
                      !filterOnlyNotCounted,
                      products,
                      isSelectedRealDiff,
                      setOrderByQuantityDesc,
                      setProducts
                    )
                  }
                >
                  Ordenar pelas diferenças (maiores para menores)
                </p>
              </div> */}
            </div>

            <div className="dFlex w100 ordinationAndUserFilter">
              <Typography
                className="dFlex alignCenter bold fontSizeDefault"
                style={{
                  marginRight: smallScreen ? '15px' : '17px'
                }}
              >
                {smallScreen ? "Ord." : "Ordenação"}
              </Typography>

              <RadioGroup
                className="radio-button"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0 3px'
                }}
                aria-label="order"
                name="order"
                value={radioValue}
                onChange={(e) => {
                  handleChangeRadio(
                    e,
                    date,
                    countId,
                    getId,
                    assortmentDate,
                    setLoading,
                    setProducts
                  );
                  setRadioValue(e.target.value);
                }}
              >
                <FormControlLabel
                  className="orderButton"
                  value="ALPHABETICAL"
                  control={<Radio />}
                  label="Alfabética"
                />

                <FormControlLabel
                  className="orderButton"
                  value="PHYSICAL"
                  control={<Radio />}
                  label="Últ. Contagens"
                />
              </RadioGroup>

              <Autocomplete
                fullWidth
                size="small"
                getOptionSelected={(option, value) =>
                  option.date === value.date
                }
                getOptionLabel={(option) =>
                  `${moment(option.date).format('DD/MM/YYYY')}    ${option.user
                  }`
                }
                options={assortmentsOptions}
                value={assortmentDate}
                onChange={(e, value) => {
                  setAssortmentDate(value);
                  setRadioValue('PHYSICAL');
                }}
                disablePortal
                className="autocompleteSmall userAutocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id={params.id}
                    name="typeFilter"
                    required
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <div className="dFlex alignCenter w100">
                    <div style={{ width: "31%" }}>
                      <Typography className="fontSizeDefault bold w100">
                        {moment(params.date).format('DD/MM/YYYY')}
                      </Typography>
                    </div>

                    <div style={{ width: "69%" }}>
                      <Typography
                        className="fontSizeDefault w100"
                        style={{
                          fontStyle: 'italic',
                          color: '#666770'
                        }}
                      >
                        {params.user}
                      </Typography>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </Grid>

        <Grid className="dFlex salesAndDiffFilterBox flexColumn justifyBetween pt5 pb3">
          <div className="dFlex justifyEnd salesBox">
            <div className="dFlex alignStart">
              <p className="mb0 salesTitle bold">
                VENDAS
              </p>

              <Tooltip
                placement="right"
                title={
                  <p style={{ padding: '4px 6px' }}>
                    Processamento das baixas: {validateQueue()}
                  </p>
                }
              >
                <img
                  style={{ marginLeft: 5, marginTop: 2, marginRight: 2, width: 14, borderRadius: '50px' }}
                  src={validateQueue() === 'Em andamento' ? ArrowRightYellowBg : CheckBoxIcon}
                  alt={validateQueue() === 'Em andamento' ? ArrowRightYellowBg : CheckBoxIcon}
                />
              </Tooltip>
            </div>

            <div className="dFlex flexColumn alignEnd salesInfos">
              <p className="mb0 bold">R$ {closureTotal}</p>

              {parseFloat(closureTotal?.replace(',', '.')) > 0 &&
              lastExtractSale?.updated_at ? (
                <span
                  className="textAlignRight pb3"
                  style={{ fontSize: 8, lineHeight: 1, height: 10 }}
                >
                  última atualização:{' '}
                  {moment(lastExtractSale?.updated_at).format('LT')}hs
                </span>
              ) : null}
            </div>
          </div>

          <TotalDiscard
            onClick={() =>
              setModalStates({
                ...modalStates,
                discardPercentageAndValue: true
              })
            }
          >
            <div className="iconTotal">
              <p>DESCARTES:</p>

              <span>
                ({valuesDiscard?.products ? valuesDiscard.products : 0}){' '}
                {/* {valuesDiscard?.products > 1 ? 'produtos' : 'produto'} */}
              </span>
            </div>

            <div className="valuesDiscard">
              <p>
                R${' '}
                {valuesDiscard?.totalValue
                  ? valuesDiscard.totalValue.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : '0,00'}
              </p>
            </div>
          </TotalDiscard>

          <div className="dFlex w100 justifyEnd" style={{ height: 29 }}>
            <TotalContainer>
              <Title
                onClick={() => {
                  filterByDiff(
                    'positive',
                    enabledFilterByDiff,
                    products,
                    setEnabledFilterByDiff,
                    setProducts,
                    isReportTabActive
                  );
                }}
                color={
                  enabledFilterByDiff.includes('positive')
                    ? '#3DCC6E'
                    : '#1F2445'
                }
                bgColor={
                  enabledFilterByDiff.includes('positive')
                    ? '#DFF7E8'
                    : '#FFFFFF'
                }
                bold
              >
                R$ {updateTotalDiffValues && totalDifferenceInReal('positive')}{' '}
                ({filterProducts('positive', true, products).length})
              </Title>

              <Title
                onClick={() => {
                  filterByDiff(
                    'expected',
                    enabledFilterByDiff,
                    products,
                    setEnabledFilterByDiff,
                    setProducts,
                    isReportTabActive
                  );
                }}
                color={
                  enabledFilterByDiff.includes('expected')
                    ? '#FFBF3E'
                    : '#1F2445'
                }
                bgColor={
                  enabledFilterByDiff.includes('expected')
                    ? '#FCF5E3'
                    : '#FFFFFF'
                }
                bold
              >
                R$ {updateTotalDiffValues && totalDifferenceInReal('expected')}{' '}
                ({filterProducts('expected', true, products).length})
              </Title>

              <Title
                onClick={() => {
                  filterByDiff(
                    'negative',
                    enabledFilterByDiff,
                    products,
                    setEnabledFilterByDiff,
                    setProducts,
                    isReportTabActive
                  );
                }}
                color={
                  enabledFilterByDiff.includes('negative')
                    ? '#F53D4C'
                    : '#1F2445'
                }
                bgColor={
                  enabledFilterByDiff.includes('negative')
                    ? '#F7D4D7'
                    : '#FFFFFF'
                }
                bold
              >
                R$ {updateTotalDiffValues && totalDifferenceInReal('negative')}{' '}
                ({filterProducts('negative', true, products).length})
              </Title>
            </TotalContainer>
          </div>
        </Grid>
      </StyledGrid>
    </HeaderContainer>
  );
}

export default CountHeader;
