/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Fade, LinearProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios'
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';
import ReasonsTable from '../Reasons'
import InfoIcon from 'images/icons/signals/infos/info.svg'
import numberToReal from 'utils/numberToReal'
import * as S from '../styles'

function CountTab({ getSalesInfos,
    handleOpenExtract,
    periodicities,
    setPeriodicities,
    periodicity,
    setPeriodicity,
    diversion,
    setDiversion,
    productsNegative,
    setProductsNegative,
    productsPositive,
    setProductsPositive,
    productsLength,
    setProductsLength,
    totalProducts,
    setTotalProducts,
    date,
    setDate,
    setIdRest,
    idRest,
    periodicitySave,
    setPeriodicitySave,
    dateSave,
    setDateSave,
    dateCount,
    setDateCount,
    setLengthNegative,
    lengthNegative,
    setLengthPositive,
    lengthPositive,
    setProductsTotalNegative,
    productsTotalNegative,
    setProductsTotalPositive,
    productsTotalPositive,
    userInfo,
    setAnalysisStarted,
    setAnalysisFinished,
    setCanShowInfoAnalysis
}) {
    const { id: getId } = useParams();
    const [loading, setLoading] = useState(false)
    const environments = Environment(process.env.REACT_APP_ENV);
    const formatDate = moment(date).format('YYYY-MM-DD')
    const [currentActiveTabs, setCurrentActiveTabs] = useState()
    const [countObj, setCountObj] = useState()

    const getReportInfos = () => {
        if (periodicity?.id) {
            setLoading(true)

            let url = undefined
            const canUseV2Counts = true

            if (canUseV2Counts) {
                url = `${environments.catalog}/inventories/counts/consolidated/${periodicity?.id}/${formatDate}/${getId}?outdata=json`
            } else {
                url = `${environments.catalog}/inventories/counts/consolidated/${periodicity?.id}/${formatDate}/${getId}?getReasons=true&getCountAnalysis=true`
            }

            setCanShowInfoAnalysis(false)
            setAnalysisStarted({ startedAt: null, startedBy: null })
            setAnalysisFinished({ finishedAt: null, finishedBy: null })

            axios
                .get(url)
                .then((response) => {
                    const countAnalysis = response.data?.countAnalysis || undefined
                    const analysisStarted = Boolean(countAnalysis?.startedAt)

                    if (analysisStarted) {
                        const started = {
                            startedAt: countAnalysis?.startedAt || null,
                            startedBy: countAnalysis?.startedBy || null
                        }

                        const finished = {
                            finishedAt: countAnalysis?.finishedAt || null,
                            finishedBy: countAnalysis?.finishedBy || null
                        }

                        setAnalysisStarted(started)
                        setAnalysisFinished(finished)
                    }

                    setCanShowInfoAnalysis(true)

                    const formattedList = response.data.listProducts.map((product) => {

                        const tooltipDifference = {
                            canShow: false,
                            theoreticalStock: undefined,
                            unity: undefined,
                            before: {
                                counted: undefined,
                                diffQuantity: undefined,
                                diffValue: undefined
                            },
                            after: {
                                counted: undefined,
                                diffQuantity: undefined,
                                diffValue: undefined
                            }
                        }

                        // Se possuir análise iniciada, considera a foto para realizar alguns cálculos e depois define o objeto da foto como objeto final
                        if (analysisStarted && Boolean(product?.countAnalysis)) {
                            tooltipDifference.canShow = product?.realStock !== product?.countAnalysis?.realStock
                            tooltipDifference.theoreticalStock = product?.theoreticalStock || 0
                            tooltipDifference.unity = product?.consumptionUnitsPrimary?.abbreviation

                            let differenceValue = 0
                            let diffToReal = 0
                            let symbol = ""

                            tooltipDifference.after.counted = product?.realStock || 0
                            tooltipDifference.after.diffQuantity = (tooltipDifference.after.counted - tooltipDifference.theoreticalStock)

                            differenceValue = (tooltipDifference.after.diffQuantity * (product?.cost || 0))
                            diffToReal = numberToReal(differenceValue)
                            symbol = differenceValue < 0 ? "-" : ""

                            tooltipDifference.after.diffValue = `${symbol} R$ ${diffToReal?.replace("-", "")}`

                            // -----

                            differenceValue = 0
                            diffToReal = 0
                            symbol = ""

                            tooltipDifference.before.counted = product?.countAnalysis?.realStock || 0
                            tooltipDifference.before.diffQuantity = (tooltipDifference.before.counted - tooltipDifference.theoreticalStock)

                            differenceValue = (tooltipDifference.before.diffQuantity * (product?.countAnalysis?.cost || 0))
                            diffToReal = numberToReal(differenceValue)
                            symbol = differenceValue < 0 ? "-" : ""

                            tooltipDifference.before.diffValue = `${symbol} R$ ${diffToReal?.replace("-", "")}`

                            product = {
                                ...product?.countAnalysis,
                                countReason: product?.countReason || undefined
                            }
                        }

                        const otherStock = product?.otherStock ? parseFloat(Number(product?.otherStock)) : 0
                        const theoreticalStock =
                            product?.theoreticalStock || product?.theoricalStock || 0;
                        const difference =
                            product?.realStock !== null || product?.realStock !== undefined
                                ? otherStock +
                                (product?.realStock ? parseFloat(Number(product?.realStock)) : 0) -
                                (theoreticalStock ? parseFloat(Number(theoreticalStock)) : 0)
                                : null;

                        const percentage = product?.consumed
                            ? (difference / parseFloat(Number(product?.consumed))) * 100
                            : 0;
                        const percent = percentage
                            ? percentage.toLocaleString('pt-br', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                            : '0,00';
                        const differenceInReal =
                            typeof parseFloat(product?.realStock) === 'number'
                                ? Number(difference) * Number(product?.cost)
                                : null;

                        const diff = difference
                            ? difference.toLocaleString('pt-br', {
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3
                            })
                            : '0,000';

                        return {
                            ...product,
                            diffInReal: differenceInReal,
                            difference: diff,
                            diffPercent: percent,
                            diffValid: difference,
                            tooltipDifference: tooltipDifference
                        }
                    })

                    const orderNegativeValue = formattedList.filter((item) => item.diffValid < 0).sort((a, b) => (a.diffInReal > b.diffInReal ? 1 : -1))
                    const orderPositiveValue = formattedList.filter((item) => item.diffValid > 0).sort((a, b) => (a.diffInReal > b.diffInReal ? -1 : 1))

                    const productsCountedStocksNegative = orderNegativeValue.filter((item) => item.stocks.countedStocks)
                    const productsCountedStocksPositive = orderPositiveValue.filter((item) => item.stocks.countedStocks)

                    const productsDifferencePercentNegative = productsCountedStocksNegative.filter((item, index) => {
                        const diff = parseFloat(item?.diffPercent.replace(',', '.'))
                        return item.diffValid < 0 && (Math.abs(diff) >= item.lossPercentage || diff == 0)
                    })
                    const productsDifferencePercentPositive = productsCountedStocksPositive.filter((item, index) => {
                        const diff = parseFloat(item?.diffPercent.replace(',', '.'))
                        return item.diffValid > 0 && (diff >= item.lossPercentage || diff == 0)
                    })

                    const unifyQuantity = productsDifferencePercentNegative.concat(productsDifferencePercentPositive)
                    const unifyTotalValueProducts = productsCountedStocksNegative.concat(productsDifferencePercentPositive)

                    setProductsTotalNegative(productsDifferencePercentNegative)
                    setProductsTotalPositive(productsDifferencePercentPositive)
                    setLengthNegative(productsDifferencePercentNegative.length)
                    setLengthPositive(productsDifferencePercentPositive.length)
                    setDiversion(response.data.diversionPeriodicity)
                    setProductsLength(response.data.listProducts)
                    setProductsNegative(productsDifferencePercentNegative)
                    setProductsPositive(productsDifferencePercentPositive)
                    setTotalProducts(unifyTotalValueProducts)
                    setLoading(false)
                    setPeriodicitySave(periodicity)
                    setDateSave(formatDate)
                    setDateCount(formatDate)
                })
                .catch((error) => {
                    setDiversion('0,00')
                    setLoading(false)
                    console.log(error);
                });
        } else {
            setDiversion('0,00')
            setProductsNegative([])
            setProductsPositive([])
            setTotalProducts([])
            setPeriodicity('')
            setProductsLength([])
        }
    };

    const totalDiffCalc = (value) => (
        Math.abs(value?.reduce(
            (total, value) =>
                value
                    ? parseFloat(value?.diffInReal) + parseFloat(total)
                    : total,
            0
        ))
    )

    const productsDiffInReal = totalProducts.filter((item) => item.diffValid)
    const totalValueProducts = productsDiffInReal.reduce((accumulator, value) => parseFloat(value?.diffInReal) + parseFloat(accumulator), 0)

    useEffect(() => {
        if (periodicity !== periodicitySave) {
            getReportInfos()
        }
    }, [periodicity, date, getId])

    useEffect(() => {
        if (dateCount !== dateSave) {
            getReportInfos()
            setDateCount(formatDate)
        }
    }, [formatDate])

    useEffect(() => {
        if (formatDate !== dateSave) {
            getReportInfos()
        }

        getOptions(
            `${environments.catalog}/periodicities/byDate`,
            'periodicities',
            periodicities,
            setPeriodicities,
            getId,
            {
                restaurantId: getId,
                startDate: formatDate,
                finishDate: formatDate
            },
        )
    }, [date, getId])

    useEffect(() => {
        if (periodicities?.length) {
            setPeriodicity(periodicities[0])
        }
    }, [periodicities, getId])

    useEffect(() => {
        const currentTabs = periodicity?.counts?.map((item) => item?.setup?.storageLocationId).flat()
        const set = new Set(currentTabs)
        const notDuplicate = Array.from(set);

        const countGroupObj = {
            counts: periodicity?.counts?.map((item) => item),
            periodicity: periodicity?.counts && periodicity?.counts[0].setup.periodicity
        }

        setCountObj(countGroupObj)
        setCurrentActiveTabs(notDuplicate)
    }, [periodicity])

    // const countRedirectUrl = periodicity?.counts.length && `/inventario/contagem-diaria/${getId}/${periodicity?.counts[0]?.id}?countDate=${formatDate}&countAssortDate=${periodicity?.counts[0]?.assortmentInfo?.date}&countAssortUser=${periodicity?.counts[0]?.assortmentInfo?.user}&startDate=${formatDate}&endDate=${formatDate}`;
    return (
        <>
            <S.Filters>
                <div className='dFlex' style={{ gap: '16px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="dataInicio"
                            variant="inline"
                            autoOk
                            label="Data"
                            maxDate={new Date()}
                            className='dateSmall'
                            style={{ width: '70px' }}
                            format="dd/MM"
                            value={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                            placeholder="Selecione"
                            onChange={(value) => {
                                setDate(value)
                                getSalesInfos(value, periodicity)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                fontSize: '12px'
                            }}
                            InputProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px', margin: 0 }
                            }}
                            KeyboardButtonProps={{
                                size: 'small',
                                'aria-label': 'change time',
                                style: { outline: 0 }
                            }}

                        />
                    </MuiPickersUtilsProvider>
                    <Autocomplete
                        size="small"
                        onChange={(e, value) => {
                            setPeriodicity(value)
                            getSalesInfos(date, value)
                        }}
                        getOptionLabel={(option) => option.description}
                        options={periodicities}
                        value={periodicity}
                        className="autocompleteSmall discard "
                        variant="outlined"
                        style={{ marginTop: '16px', width: '280px' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="periodicity"
                                label="Contagens"
                                variant="outlined"
                                placeholder="Selecione..."
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                        renderOption={(params) => (
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    fontFamily: 'Lato'
                                }}
                            >
                                {params.description}
                            </Typography>
                        )}
                    />
                    {/* <S.Indicator>
                        <p>{diversion.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}%</p>
                        <img
                            style={{ width: '12px' }}
                            src={IndicatorIcon}
                            alt="IndicatorIcon"
                            className="ml5"
                        />
                    </S.Indicator> */}
                    <S.Comparison>
                        {/* <Tooltip
                            TransitionProps={{ timeout: 600 }}
                            placement="right"
                            title={
                                <span
                                    className="dBlock"
                                    style={{ padding: '4px 7px' }}
                                >
                                    Visualizar contagem completa
                                </span>
                            }
                            > */}
                        <div>
                            {periodicity &&
                                <p
                                    className={`bold
                                            ${!periodicity && 'disabledLink'}
                                        `}
                                    target="_blank"
                                // to={{
                                //     pathname: countRedirectUrl,
                                //     search: `?${url.split('?')[1]}`,
                                //     state: {
                                //         isCountGroup: true,
                                //         currentActiveTab: currentActiveTabs,
                                //         countGroup: countObj
                                //     }
                                // }}

                                // onClick={() => {
                                //     const url = `/inventario/contagem-diaria/${getId}/${periodicity?.counts[0]?.id}?countDate=${formatDate}&countAssortDate=${periodicity?.counts[0]?.assortmentInfo?.date}&countAssortUser=${periodicity?.counts[0]?.assortmentInfo?.user}&startDate=${formatDate}&endDate=${formatDate}`;

                                //     history.push({
                                //         pathname: url,
                                //         search: `?${url.split('?')[1]}`,
                                //         state: {
                                //             isCountGroup: true,
                                //             currentActiveTab: currentActiveTabs,
                                //             countGroup: countObj
                                //         }
                                //     })
                                // }}
                                >
                                    <p>
                                        {productsLength?.filter((item) => item.stocks.countedStocks)?.length}/{productsLength?.length} produtos
                                    </p>
                                </p>
                            }
                        </div>
                        {/* </Tooltip> */}
                    </S.Comparison>
                </div>

                <S.Totaldifferences
                    backgroundDiv={totalValueProducts > 0
                        ? 'rgba(242, 185, 13, 0.6)'
                        : 'rgba(245, 61, 76, 0.6)'}
                    backgroundColor={totalValueProducts > 0 ? '#FFF2CC' : '#F7D4D7'}>
                    <div>
                        {productsNegative.length + productsPositive.length}
                    </div>
                    <p>
                        {totalValueProducts > 0 ? 'R$' : '- R$'}{' '}
                        {totalValueProducts > 0 ? totalValueProducts.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) : totalValueProducts.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).toString().replace('-', '')}
                    </p>
                </S.Totaldifferences>
            </S.Filters>

            {loading && <LinearProgress variant="query" />}

            {productsNegative.length ? (
                <>
                    <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ width: "61%" }}>
                            <div className="headerSubtitle">
                                <div className='subheader'>
                                    <th
                                        width="32.1%"
                                        className="subtitle"
                                    >
                                        PRODUTO{' '}
                                    </th>

                                    <th width="10.9%" className="subtitle textAlignRight lineLeft">
                                        REAL{' '}
                                    </th>

                                    <th width="10.8%" className="subtitle textAlignRight lineLeft">
                                        TEÓRICO{' '}
                                    </th>

                                    <th width="10.7%" className="subtitle textAlignRight lineLeft">
                                        CONSUMO{' '}
                                    </th>

                                    <th width="13.1%" className="subtitle textAlignRight lineLeft">
                                        EM QTDE{' '}
                                    </th>
                                    <th width="10.8%" className="subtitle textAlignRight lineLeft">
                                        % / CONSUMO{' '}
                                    </th>
                                    <th width="15%" className="subtitle textAlignRight lineLeft">
                                        EM R${' '}
                                    </th>
                                </div>
                            </div>

                            <table
                                className="tableNegative"
                                width="100%"
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                            >
                                <thead className='theadNegative'>
                                    <tr className="headerTitle">
                                        <th className="subtitle" colSpan={6}>
                                            DIFERENÇAS NEGATIVAS{' '}
                                        </th>
                                        <th width="15%" className="subtitle lineLeft alignCollumn">
                                            <div className='gapItens dFlex justifyBetween alignCenter'>
                                                <Tooltip
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="right"
                                                    disableHoverListener
                                                    title={
                                                        <span
                                                            className="dBlock"
                                                            style={{ padding: '4px 7px' }}
                                                        >
                                                            Visualizar todas as diferenças negativas
                                                        </span>
                                                    }
                                                >
                                                    <Link
                                                        className="disabledLink"
                                                        target="_blank"
                                                        to={`/inventario/contagens/${getId}?startAt=${moment(date).format('YYYY-MM-DD')}?endAt=${moment(date).format('YYYY-MM-DD')}&periodId=${periodicity?.id}&periodDescription=${periodicity?.description}&difference=lack`}
                                                    >
                                                        <div className='totalNumber'>
                                                            {lengthNegative || 0}
                                                        </div>
                                                    </Link>
                                                </Tooltip>

                                                <p className='totalValue'>
                                                    - R$ {totalDiffCalc(productsTotalNegative)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {productsNegative.slice(0, 5).map((item, index) => (
                                        <>
                                            <tr className='list'>
                                                <td width="30.6%" className="titleLink pb0">
                                                    <Link
                                                        className="td0"
                                                        target="_blank"
                                                        to={`/inventario/contagem-diaria/${getId}/${item.countIds.find((el) => el)}?countDate=${moment(date).format('YYYY-MM-DD')}`}
                                                    >
                                                        {ValidationLength(
                                                            item?.description,
                                                            25
                                                        )}
                                                    </Link>
                                                </td>
                                                <td
                                                    width="10%"
                                                    className='titleLink lineLeft textAlignRight cursorPointer'
                                                    onClick={() => handleOpenExtract(item)}
                                                >
                                                    {(item?.realStock || 0).toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdParagraph textAlignRight'>
                                                    {(item?.theoreticalStock || 0)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdParagraph textAlignRight'>
                                                    {(item?.consumed || 0)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td >
                                                <td width="12%" className='lineLeft tdNegative textAlignRight'>
                                                    {
                                                        item?.tooltipDifference?.canShow ?
                                                            <Tooltip
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}
                                                                title={
                                                                    <div
                                                                        className="dFlex flexColumn"
                                                                        style={{
                                                                            width: "210px",
                                                                            padding: 1.2,
                                                                            fontSize: "12px"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            Correções efetuadas nesta Contagem
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            {`Estoque téorico: ${item?.tooltipDifference?.theoreticalStock ? parseFloat(item?.tooltipDifference?.theoreticalStock).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            INÍCIO DA ANÁLISE
                                                                        </span>
                                                                        <span>
                                                                            {`Contado: ${item?.tooltipDifference?.before?.counted ? parseFloat(item?.tooltipDifference?.before?.counted).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em Qtde: ${item?.tooltipDifference?.before?.diffQuantity ? parseFloat(item?.tooltipDifference?.before?.diffQuantity).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em R$: ${item?.tooltipDifference?.before?.diffValue ? `${item?.tooltipDifference?.before?.diffValue}` : "R$ 0,00"}`}
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            APÓS AJUSTE
                                                                        </span>
                                                                        <span>
                                                                            {`Contado: ${item?.tooltipDifference?.after?.counted ? parseFloat(item?.tooltipDifference?.after?.counted).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em Qtde: ${item?.tooltipDifference?.after?.diffQuantity ? parseFloat(item?.tooltipDifference?.after?.diffQuantity).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em R$: ${item?.tooltipDifference?.after?.diffValue ? `${item?.tooltipDifference?.after?.diffValue}` : "R$ 0,00"}`}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            >
                                                                <img src={InfoIcon} alt="Info Icon" style={{ marginRight: "16px", marginBottom: "1px" }} />
                                                            </Tooltip>
                                                            : null
                                                    }
                                                    {item?.difference?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdNegative textAlignRight'>
                                                    {item?.diffPercent?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })} %
                                                </td>
                                                <td width="15%" className='lineLeft tdNegative textAlignRight'>
                                                    - R$ {Math.abs(item?.diffInReal)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="7">
                                                    <hr className='m0' />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>

                                <tfoot className='tfootNegative'>
                                    <tr>
                                        <th className="subtitle" colSpan={5}>
                                            ATÉ 5 PRINCIPAIS NEGATIVAS{' '}
                                        </th>
                                        <th className="subtitle textAlignRight">
                                            {((totalDiffCalc(productsNegative.slice(0, 5)) / totalDiffCalc(productsTotalNegative)) * 100).toLocaleString('pt-br', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })} %
                                        </th>
                                        <th width="15%" className="subtitle lineLeft alignCollumn">
                                            <div className='gapItens dFlex justifyBetween alignCenter'>
                                                <div className='totalNumber'>
                                                    {productsNegative.slice(0, 5).length || 0}
                                                </div>
                                                <p className='totalValue'>
                                                    - R$ {totalDiffCalc(productsNegative.slice(0, 5))?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div style={{ width: "1%" }}></div>

                        <ReasonsTable
                            products={productsNegative}
                            isTopTable={productsNegative && Array.isArray(productsNegative) && productsNegative.length > 0 ? true : false}
                            restaurantId={getId}
                            userInfo={userInfo}
                            getReportInfos={getReportInfos}
                            type="COUNT"
                        />

                    </div>
                </>
            ) : null}

            {productsPositive.length ? (
                <>

                    <div style={{ width: "100%", display: "flex" }}>
                        <div className='pRelative' style={{ width: "61%" }}>
                            <div className="headerSubtitlePositive">
                                <div className='subheader'>
                                    <th
                                        width="32.1%"
                                        className="subtitle"
                                    >
                                        PRODUTO{' '}
                                    </th>

                                    <th width="10.9%" className="subtitle textAlignRight lineLeft">
                                        REAL{' '}
                                    </th>

                                    <th width="10.8%" className="subtitle textAlignRight lineLeft">
                                        TEÓRICO{' '}
                                    </th>

                                    <th width="10.7%" className="subtitle textAlignRight lineLeft">
                                        CONSUMO{' '}
                                    </th>

                                    <th width="13.1%" className="subtitle textAlignRight lineLeft">
                                        EM QTDE{' '}
                                    </th>
                                    <th width="10.8%" className="subtitle textAlignRight lineLeft">
                                        % / CONSUMO{' '}
                                    </th>
                                    <th width="15%" className="subtitle textAlignRight lineLeft">
                                        EM R${' '}
                                    </th>
                                </div>
                            </div>

                            <table
                                className="tablePositive"
                                width="100%"
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                            >
                                <thead className='theadPositive'>
                                    <tr className="headerTitle">
                                        <th className="subtitle" colSpan={6}>
                                            DIFERENÇAS POSITIVAS{' '}
                                        </th>
                                        <th width="15%" className="subtitle lineLeft alignCollumn">
                                            <div className='gapItens dFlex justifyBetween alignCenter'>
                                                <Tooltip
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="right"
                                                    disableHoverListener
                                                    title={
                                                        <span
                                                            className="dBlock"
                                                            style={{ padding: '4px 7px' }}
                                                        >
                                                            Visualizar todas as diferenças positivas
                                                        </span>
                                                    }
                                                >
                                                    <Link
                                                        className="disabledLink"
                                                        target="_blank"
                                                        to={`/inventario/contagens/${getId}?startAt=${moment(date).format('YYYY-MM-DD')}?endAt=${moment(date).format('YYYY-MM-DD')}&periodId=${periodicity?.id}&periodDescription=${periodicity?.description}&difference=leftover`}
                                                    >
                                                        <div className='totalNumberPositive'>
                                                            {lengthPositive || 0}
                                                        </div>
                                                    </Link>
                                                </Tooltip>

                                                <p className='totalValue'>
                                                    R$ {totalDiffCalc(productsTotalPositive)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {productsPositive.slice(0, 5).map((item, index) => (
                                        <>
                                            <tr className='list'>
                                                <td width="30.6%" className="titleLink pb0">
                                                    <Link
                                                        className="td0"
                                                        target="_blank"
                                                        to={`/inventario/contagem-diaria/${getId}/${item.countIds.find((el) => el)}?countDate=${moment(date).format('YYYY-MM-DD')}`}
                                                    >
                                                        {ValidationLength(
                                                            item?.description,
                                                            25
                                                        )}
                                                    </Link>
                                                </td>
                                                <td width="10%" className='titleLink lineLeft textAlignRight cursorPointer'
                                                    onClick={() => handleOpenExtract({
                                                        ...item,
                                                        consumptionUnitsPrimary: item?.consumptionUnity
                                                    })}>
                                                    {(item?.realStock || 0).toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdParagraph textAlignRight'>
                                                    {(item?.theoreticalStock || 0)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdParagraph textAlignRight'>
                                                    {(item?.consumed || 0)?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td >
                                                <td width="12%" className='lineLeft tdPositive textAlignRight'>
                                                    {
                                                        item?.tooltipDifference?.canShow ?
                                                            <Tooltip
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}
                                                                title={
                                                                    <div
                                                                        className="dFlex flexColumn"
                                                                        style={{
                                                                            width: "210px",
                                                                            padding: 1.2,
                                                                            fontSize: "12px"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            Correções efetuadas nesta contagem
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            {`Estoque téorico: ${item?.tooltipDifference?.theoreticalStock ? parseFloat(item?.tooltipDifference?.theoreticalStock).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            INÍCIO DA ANÁLISE
                                                                        </span>
                                                                        <span>
                                                                            {`Contado: ${item?.tooltipDifference?.before?.counted ? parseFloat(item?.tooltipDifference?.before?.counted).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em Qtde: ${item?.tooltipDifference?.before?.diffQuantity ? parseFloat(item?.tooltipDifference?.before?.diffQuantity).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em R$: ${item?.tooltipDifference?.before?.diffValue ? `${item?.tooltipDifference?.before?.diffValue}` : "R$ 0,00"}`}
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            style={{ fontWeight: "700" }}
                                                                        >
                                                                            APÓS AJUSTE
                                                                        </span>
                                                                        <span>
                                                                            {`Contado: ${item?.tooltipDifference?.after?.counted ? parseFloat(item?.tooltipDifference?.after?.counted).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em Qtde: ${item?.tooltipDifference?.after?.diffQuantity ? parseFloat(item?.tooltipDifference?.after?.diffQuantity).toLocaleString("pt-br", { minimumFractionDigits: 3 }) : "0,000"} ${item?.tooltipDifference?.unity}`}
                                                                        </span>
                                                                        <span>
                                                                            {`Diferença em R$: ${item?.tooltipDifference?.after?.diffValue ? `${item?.tooltipDifference?.after?.diffValue}` : "R$ 0,00"}`}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            >
                                                                <img src={InfoIcon} alt="Info Icon" style={{ marginRight: "16px", marginBottom: "1px" }} />
                                                            </Tooltip>
                                                            : null
                                                    }
                                                    {item?.difference?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 3,
                                                        maximumFractionDigits: 3,
                                                    })} {item.consumptionUnity}
                                                </td>
                                                <td width="10%" className='lineLeft tdPositive textAlignRight'>
                                                    {item?.diffPercent?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })} %
                                                </td>
                                                <td width="15%" className='lineLeft tdPositive textAlignRight'>
                                                    R$ {item?.diffInReal?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="7">
                                                    <hr className='m0' />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>

                                <tfoot className='tfootPositive'>
                                    <tr>
                                        <th className="subtitle" colSpan={5}>
                                            ATÉ 5 PRINCIPAIS POSITIVAS{' '}
                                        </th>
                                        <th className="subtitle textAlignRight">
                                            {((totalDiffCalc(productsPositive.slice(0, 5)) / totalDiffCalc(productsTotalPositive)) * 100).toLocaleString('pt-br', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })} %
                                        </th>
                                        <th width="15%" className="subtitle lineLeft alignCollumn">
                                            <div className='gapItens dFlex justifyBetween alignCenter'>
                                                <div className='totalNumberPositive'>
                                                    {productsPositive.slice(0, 5).length || 0}
                                                </div>
                                                <p className='totalValue'>
                                                    R$ {totalDiffCalc(productsPositive.slice(0, 5))?.toLocaleString('pt-br', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div style={{ width: "1%" }}></div>

                        <ReasonsTable
                            products={productsPositive}
                            isTopTable={productsNegative && Array.isArray(productsNegative) && productsNegative.length > 0 ? false : true}
                            restaurantId={getId}
                            userInfo={userInfo}
                            getReportInfos={getReportInfos}
                            type="COUNT"
                        />
                    </div>

                </>
            ) : null}
        </>
    )
}

export default CountTab